import {
  combineReducers,
  configureStore
} from '@reduxjs/toolkit';
import { pharmaciesLayoutReducer } from './pharmaciesLayout/pharmaciesLayoutReducer';
import { pharmaciesReducer } from './pharmacies/pharmaciesReducer';
import thunk from 'redux-thunk';
import { errorReducer } from './error/errorReducer';
import { userReducer } from './user/userReducer';
import { servicesReducer } from './services/servicesReducer';
import { pharmaicesServicesMappingReducer } from './pharmaciesServicesMapping/pharmaciesServicesMappingReducer';
import { serviceRequestsReducer } from './serviceRequests/serviceRequestsReducer';
import { snackbarReducer } from './snackbar/snackbarReducer';
import { feedbacksReducer } from './feedbacks/feedbacksReducer';
import { adminUserReducer } from './adminUsers/adminUserReducer';
import { mailLogsReducer } from './mailLogs/mailLogsReducer';

export const rootStore = configureStore({
  reducer: combineReducers({
    pharmaciesLayout: pharmaciesLayoutReducer,
    pharmacies: pharmaciesReducer,
    error: errorReducer,
    user: userReducer,
    services: servicesReducer,
    pharmaciesServicesMapping:
      pharmaicesServicesMappingReducer,
    serviceRequests: serviceRequestsReducer,
    snackbar: snackbarReducer,
    feedbacks: feedbacksReducer,
    adminUsers: adminUserReducer,
    mailLogs: mailLogsReducer
  }),
  middleware: [thunk]
});

export type RootStateType = ReturnType<
  typeof rootStore.getState
>;

export type DispatchType = typeof rootStore.dispatch;
