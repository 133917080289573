import { ACTION_NAMES } from '../reduxActions';

export const enableError = (message: string) => {
  return {
    type: ACTION_NAMES.ENABLE_ERROR,
    payload: message
  };
};

export const disableError = () => {
  return {
    type: ACTION_NAMES.DISABLE_ERROR
  };
};
