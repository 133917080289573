import {
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { PharmacyAddressProps } from '../../helpers/interfaces';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffect } from 'react';

const PharmacyAddress: React.FC<PharmacyAddressProps> = (
  props: PharmacyAddressProps
): JSX.Element => {
  useEffect(() => {
    props.setWarning(
      [
        props.locationAddress1.trim(),
        props.locationCity.trim(),
        props.locationCounty.trim(),
        props.locationState.trim(),
        props.locationZip.trim()
      ].includes('') ||
        (!props.sameAddress &&
          [
            props.mailingAddress1.trim(),
            props.mailingCity.trim(),
            props.mailingCounty.trim(),
            props.mailingState.trim(),
            props.mailingZip.trim()
          ].includes(''))
    );
  }, [
    props.locationAddress1,
    props.locationCity,
    props.locationCounty,
    props.locationState,
    props.locationZip,
    props.mailingAddress1,
    props.mailingCity,
    props.mailingCounty,
    props.mailingState,
    props.mailingZip,
    props.sameAddress
  ]);

  return (
    <>
      {props.viewMode !== 'REVIEW' && (
        <Typography variant="h5" align="center">
          Add address details
        </Typography>
      )}
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <TextField
            autoComplete="given-location-address1"
            name="location-address1"
            required
            fullWidth
            label="Address line 1"
            value={props.locationAddress1}
            sx={{ marginBottom: '2%' }}
            onChange={(event) => {
              props.updatePharmacyObject({
                locationAddress1: event.target.value
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
            autoFocus
          />
          <WarningComponentForIncorrectField
            message={
              props.locationAddress1 === ''
                ? 'Address line 1 cannot be empty'
                : ''
            }
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Address line 2"
            name="location-address2"
            value={props.locationAddress2}
            onChange={(event) => {
              props.updatePharmacyObject({
                locationAddress2: event.target
                  .value as string
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            label="City"
            name="city"
            value={props.locationCity}
            onChange={(event) => {
              props.updatePharmacyObject({
                locationCity: event.target.value
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
          />
          <WarningComponentForIncorrectField
            message={
              props.locationCity.trim() === ''
                ? 'City cannot be empty'
                : ''
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            label="County"
            name="county"
            value={props.locationCounty}
            onChange={(event) => {
              props.updatePharmacyObject({
                locationCounty: event.target.value
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
          />
          <WarningComponentForIncorrectField
            message={
              props.locationCounty.trim() === ''
                ? 'County cannot be empty'
                : ''
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            label="State"
            name="state"
            value={props.locationState}
            onChange={(event) => {
              props.updatePharmacyObject({
                locationState: event.target.value
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
          />
          <WarningComponentForIncorrectField
            message={
              props.locationState.trim() === ''
                ? 'State cannot be empty'
                : ''
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            label="Zip Code"
            name="zip"
            value={props.locationZip}
            onChange={(event) => {
              props.updatePharmacyObject({
                locationZip: event.target.value
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
          />
          <WarningComponentForIncorrectField
            message={
              props.locationZip.trim() === ''
                ? 'Zip cannot be empty'
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} display="flex">
          <IconButton
            onClick={() => {
              props.setSameAddress(!props.sameAddress);
            }}>
            {props.sameAddress ? (
              <CheckBoxIcon
                fontSize="medium"
                color="primary"
              />
            ) : (
              <CheckBoxOutlineBlankIcon
                fontSize="medium"
                color="primary"
              />
            )}
          </IconButton>
          <Typography mt={1}>
            Use above address as mailing address
          </Typography>
        </Grid>
        {props.sameAddress ? null : (
          <>
            <Grid item xs={12}>
              <Typography
                fontWeight="bold"
                align="left"
                variant="h5">
                Mailing Address:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="given-mailing-address1"
                name="mailing-address1"
                required
                fullWidth
                label="Address line 1"
                value={props.mailingAddress1}
                sx={{ marginBottom: '2%' }}
                onChange={(event) => {
                  props.updatePharmacyObject({
                    mailingAddress1: event.target.value
                  });
                }}
                disabled={props.viewMode === 'REVIEW'}
              />
              <WarningComponentForIncorrectField
                message={
                  props.mailingAddress1 === ''
                    ? 'Address line 1 cannot be empty'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Address line 2"
                name="mailing-address2"
                value={props.mailingAddress2}
                onChange={(event) => {
                  props.updatePharmacyObject({
                    mailingAddress2: event.target.value
                  });
                }}
                disabled={props.viewMode === 'REVIEW'}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                label="City"
                name="city"
                value={props.mailingCity}
                onChange={(event) => {
                  props.updatePharmacyObject({
                    mailingCity: event.target.value
                  });
                }}
                disabled={props.viewMode === 'REVIEW'}
              />
              <WarningComponentForIncorrectField
                message={
                  props.mailingCity.trim() === ''
                    ? 'City cannot be empty'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                label="County"
                name="county"
                value={props.mailingCounty}
                onChange={(event) => {
                  props.updatePharmacyObject({
                    mailingCounty: event.target.value
                  });
                }}
                disabled={props.viewMode === 'REVIEW'}
              />
              <WarningComponentForIncorrectField
                message={
                  props.mailingCounty.trim() === ''
                    ? 'County cannot be empty'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                label="State"
                name="state"
                value={props.mailingState}
                onChange={(event) => {
                  props.updatePharmacyObject({
                    mailingState: event.target.value
                  });
                }}
                disabled={props.viewMode === 'REVIEW'}
              />
              <WarningComponentForIncorrectField
                message={
                  props.mailingState.trim() === ''
                    ? 'State cannot be empty'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                label="Zip Code"
                name="zip"
                value={props.mailingZip}
                onChange={(event) => {
                  props.updatePharmacyObject({
                    mailingZip: event.target.value
                  });
                }}
                disabled={props.viewMode === 'REVIEW'}
              />
              <WarningComponentForIncorrectField
                message={
                  props.mailingZip.trim() === ''
                    ? 'Zip cannot be empty'
                    : ''
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default PharmacyAddress;
