import {
  FormControl,
  Grid,
  InputLabel,
  // InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { PharmacyBasicDetailsProps } from '../../helpers/interfaces';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import { useEffect } from 'react';
import {
  PHARMACY_TYPES,
  USER_ROLES
} from '../../helpers/constant';
import { validatePhoneNumber } from '../../helpers/utils';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';

const PharmacyBasicDetails: React.FC<
  PharmacyBasicDetailsProps
> = (props: PharmacyBasicDetailsProps): JSX.Element => {
  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  useEffect(() => {
    props.setWarning(
      [
        props.permitNo.trim(),
        props.pharmacyName.trim(),
        props.pharmacyType.trim()
      ].includes('') ||
        validatePhoneNumber(props.phone) !== ''
    );
  }, [
    props.permitNo,
    props.pharmacyName,
    props.phone,
    props.pharmacyType
  ]);

  return (
    <>
      {props.viewMode !== 'REVIEW' && (
        <Typography variant="h5" align="center">
          Add pharmacy details
        </Typography>
      )}
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <TextField
            autoComplete="given-permit-number"
            name="permit-number"
            required
            fullWidth
            label="Permit Number"
            value={props.permitNo}
            sx={{ marginBottom: '2%' }}
            onChange={(event) => {
              props.updatePharmacyObject({
                permitNo: event.target.value
              });
            }}
            disabled={
              props.viewMode === 'REVIEW' ||
              userState.userProfile === null ||
              userState.userProfile.role === USER_ROLES.USER
            }
            autoFocus
          />
          <WarningComponentForIncorrectField
            message={
              props.permitNo.trim() === ''
                ? 'Permit number cannot be empty'
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Pharmacy Name"
            name="pharmacy-name"
            value={props.pharmacyName}
            onChange={(event) => {
              props.updatePharmacyObject({
                pharmacyName: event.target.value
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
          />
          <WarningComponentForIncorrectField
            message={
              props.pharmacyName.trim() === ''
                ? 'Pharmacy name cannot be empty'
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            label="Phone Number"
            name="phone-number"
            value={props.phone}
            onChange={(event) => {
              props.updatePharmacyObject({
                phone: event.target.value
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
          />
          <WarningComponentForIncorrectField
            message={validatePhoneNumber(props.phone)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Fax"
            name="fax"
            value={props.fax}
            onChange={(event) => {
              props.updatePharmacyObject({
                fax: event.target.value
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Website"
            name="website"
            value={props.website}
            onChange={(event) => {
              props.updatePharmacyObject({
                website: event.target.value
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Hours of operation"
            name="operation-hours"
            value={props.operationHours}
            onChange={(event) => {
              props.updatePharmacyObject({
                operationHours: event.target.value
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
          />
        </Grid>
        {props.viewMode === 'REVIEW' ? (
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Type of pharmacy"
              name="pharmacy-type"
              value={props.pharmacyType}
              disabled={true}
            />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="pharmacy-type-label">
                Type of pharmacy
              </InputLabel>
              <Select
                labelId="pharmacy-type-label"
                id="pharmacy-type"
                value={props.pharmacyType.toUpperCase()}
                style={{ textAlign: 'left' }}
                label="Type of pharmacy"
                onChange={(event) => {
                  props.updatePharmacyObject({
                    pharmacyType: event.target.value
                  });
                }}>
                {Object.keys(PHARMACY_TYPES).map((type) => {
                  const typeValue = PHARMACY_TYPES[type];
                  return (
                    <MenuItem
                      key={typeValue}
                      value={typeValue}>
                      {typeValue[0] +
                        typeValue.slice(1).toLowerCase()}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PharmacyBasicDetails;
