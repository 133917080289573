import {
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Tooltip,
  Typography
} from '@mui/material';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import { ServiceQuestionsProps } from '../../helpers/interfaces';
import {
  SERVICE_QUESTION_REFERENCE_STATUS,
  SERVICE_QUESTION_TYPES
} from '../../helpers/constant';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const ServiceQuestions: React.FC<ServiceQuestionsProps> = (
  props: ServiceQuestionsProps
): JSX.Element => {
  const providerQuestions =
    props.serviceQuestionsList.filter(
      (question) =>
        question.status !==
          SERVICE_QUESTION_REFERENCE_STATUS.DELETED &&
        ![
          SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
          SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
        ].includes(question.serviceQuestionType)
    );
  const supplementalQuestions =
    props.serviceQuestionsList.filter(
      (question) =>
        question.status !==
          SERVICE_QUESTION_REFERENCE_STATUS.DELETED &&
        [
          SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
          SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
        ].includes(question.serviceQuestionType)
    );
  return (
    <>
      <Typography variant="h5" align="center">
        Add provider and supplemental questions for your
        service
      </Typography>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          minWidth: '100%',
          marginBottom: '4%',
          marginTop: '2%'
        }}>
        <Tooltip title="Add new service commitment level">
          <Button
            variant="contained"
            onClick={() => {
              props.newServiceQuestion(
                SERVICE_QUESTION_TYPES.LEVEL1_PROVIDER
              );
            }}>
            Add Service Commitment Level
          </Button>
        </Tooltip>
      </Container>
      <Grid container>
        {providerQuestions.map((question) => {
          return (
            <>
              <Grid
                container
                item
                key={question.serviceQuestionId}
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  marginBottom: '2%'
                }}>
                <Grid item xs={11}>
                  <Typography color="gray" fontSize={12}>
                    {question.serviceQuestionType +
                      ' Status'}
                  </Typography>

                  <TextareaAutosize
                    required
                    style={{
                      width: '100%',
                      fontFamily: 'inherit',
                      fontSize: 17
                    }}
                    name={
                      question.serviceQuestionType +
                      ' Status'
                    }
                    placeholder={
                      question.serviceQuestionType +
                      ' Status'
                    }
                    value={question.serviceQuestion}
                    onChange={(event) => {
                      props.updateServiceQuestion(
                        question.serviceQuestionId,
                        event.target.value,
                        question.status
                      );
                    }}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    disabled={
                      question.serviceQuestionType ===
                      SERVICE_QUESTION_TYPES.LEVEL1_PROVIDER
                    }
                    onClick={() => {
                      props.removeServiceQuestion(
                        question.serviceQuestionId,
                        question.status
                      );
                    }}>
                    <DeleteForeverIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <WarningComponentForIncorrectField
                    message={
                      question.serviceQuestion.trim() === ''
                        ? question.serviceQuestionType +
                          ' Status cannot be empty'
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
      <Divider
        sx={{
          marginTop: '6%',
          marginBottom: '6%',
          borderBottomWidth: '3px',
          borderColor: '#000'
        }}
      />
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          minWidth: '100%',
          marginTop: '3%',
          marginBottom: '4%'
        }}>
        <Tooltip title="Add new supplemental service">
          <Button
            variant="contained"
            onClick={() => {
              props.newServiceQuestion(
                SERVICE_QUESTION_TYPES.SUPPLEMENTAL
              );
            }}>
            Add Supplemental Service
          </Button>
        </Tooltip>
      </Container>
      <Grid container spacing={2}>
        {supplementalQuestions.map((question) => {
          return (
            <>
              <Grid
                container
                item
                key={question.serviceQuestionId}
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  marginBottom: '2%'
                }}>
                <Grid item xs={11}>
                  <Typography color="gray" fontSize={12}>
                    {question.serviceQuestionType +
                      ' Question'}
                  </Typography>
                  <TextareaAutosize
                    required
                    style={{
                      width: '100%',
                      fontFamily: 'inherit',
                      fontSize: 17
                    }}
                    name={
                      question.serviceQuestionType +
                      ' Question'
                    }
                    placeholder={
                      question.serviceQuestionType +
                      ' Question'
                    }
                    value={question.serviceQuestion}
                    onChange={(event) => {
                      props.updateServiceQuestion(
                        question.serviceQuestionId,
                        event.target.value,
                        question.status
                      );
                    }}
                    autoFocus
                  />
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="option"
                      name="option"
                      row
                      value={
                        question.serviceQuestionType ===
                        SERVICE_QUESTION_TYPES.SUPPLEMENTAL
                          ? 'yesNo'
                          : 'customAnswer'
                      }
                      onChange={(event) => {
                        props.updateServiceQuestionType(
                          question.serviceQuestionId,
                          event.target.value === 'yesNo'
                            ? SERVICE_QUESTION_TYPES.SUPPLEMENTAL
                            : SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL,
                          question.status
                        );
                      }}>
                      <FormControlLabel
                        id="yesNo"
                        value="yesNo"
                        control={<Radio />}
                        label="Yes/No"
                      />
                      <FormControlLabel
                        id="customAnswer"
                        value="customAnswer"
                        control={<Radio />}
                        label="Custom Answer"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      props.removeServiceQuestion(
                        question.serviceQuestionId,
                        question.status
                      );
                    }}>
                    <DeleteForeverIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <WarningComponentForIncorrectField
                    message={
                      question.serviceQuestion === ''
                        ? question.serviceQuestionType +
                          ' Question cannot be empty'
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
};

export default ServiceQuestions;
