import { ACTION_NAMES } from '../reduxActions';
import { getMailLogsApi } from '../../helpers/apiCalls';
import { DispatchType } from '../store';

export const loadMailLogs = async (
  dispatch: DispatchType
) => {
  dispatch({
    type: ACTION_NAMES.LOAD_MAIL_LOGS
  });
  const result = await getMailLogsApi(dispatch);
  dispatch({
    type: ACTION_NAMES.LOAD_MAIL_LOGS_SUCCESS,
    payload: result
  });
};
