import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress
} from '@mui/material';
import { RequestConfirmationModalProps } from '../../helpers/interfaces';
import { approveRejectRequests } from '../../redux/serviceRequests/serviceRequestsActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';

const RequestConfirmationModal: React.FC<
  RequestConfirmationModalProps
> = (props: RequestConfirmationModalProps) => {
  const [rejectComment, setRejectComment] = useState('');

  const dispatch = useDispatch();

  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirm = () => {
    setLoading(true);
    if (userState.userProfile) {
      approveRejectRequests(
        dispatch,
        props.serviceRequest,
        userState.userProfile?.userId,
        userState.userProfile?.role,
        props.isApproveRequest ? 'APPROVED' : 'REJECTED',
        props.isApproveRequest ? '' : rejectComment
      );
      props.closeModal();
      dispatch(
        openSnackbar(
          `Service request ${
            props.isApproveRequest ? 'approved' : 'rejected'
          }!`,
          'success'
        )
      );
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={props.closeModal}>
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>
        Are you sure you want to{' '}
        {props.isApproveRequest ? 'approve ' : 'reject '}
        this request?
        {!props.isApproveRequest && (
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Comment (if rejecting)"
            value={rejectComment}
            onChange={(event) => {
              setRejectComment(event.target.value);
            }}
            margin="normal"
            variant="outlined"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeModal} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={
            loading ||
            (!props.isApproveRequest &&
              rejectComment.trim() === '')
          }
          color={
            props.isApproveRequest ? 'success' : 'error'
          }>
          {loading ? (
            <CircularProgress />
          ) : props.isApproveRequest ? (
            'Approve'
          ) : (
            'Reject'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequestConfirmationModal;
