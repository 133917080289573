import * as React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Toolbar,
  IconButton,
  Badge,
  Button,
  Typography,
  Tooltip
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuOptions from './MenuOptions';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { deepPurple } from '@mui/material/colors';
import { getInitialsFromName } from '../../helpers/utils';
import {
  FEEDBACK_STATUS,
  USER_ROLES
} from '../../helpers/constant';
import banner from '../../images/NCAPBannerLogo.png';
import { useState, useEffect } from 'react';

const NavigationBar = () => {
  const location = useLocation();
  const [menuElement, setMenuElement] =
    React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  const serviceRequestsState = useSelector(
    (state: RootStateType) => state.serviceRequests
  );

  const feedbacksState = useSelector(
    (state: RootStateType) => state.feedbacks
  );

  const [selectedTab, setSelectedTab] =
    useState<string>('About');

  useEffect(() => {
    switch (location.pathname) {
      case '/pharmacies':
        setSelectedTab('Pharmacies');
        break;
      case '/services':
        setSelectedTab('Services');
        break;
      case '/serviceRequests':
        setSelectedTab('Requests');
        break;
      case '/importexport':
        setSelectedTab('Data Ops');
        break;
      case '/giveFeedback':
        setSelectedTab('Feedback');
        break;
      case '/feedback':
        setSelectedTab('Feedback');
        break;
      case '/manageAdmins':
        setSelectedTab('Manage Admins');
        break;
      default:
        setSelectedTab('');
    }
  }, [location.pathname]);

  const handleProfileMenuOpen = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setMenuElement(event.currentTarget);
  };

  return (
    <Box sx={{ flexGrow: 1, mb: 2 }}>
      <AppBar
        position="static"
        sx={{ background: '#5a5a5a' }}>
        <Toolbar>
          <img
            src={banner}
            onClick={() => {
              navigate('/');
            }}
            style={{
              cursor: 'pointer',
              width: '8%',
              minWidth: '55px',
              margin: '0.2%',
              border: '0.3px solid rgba(0, 0, 0, 0.05)',
              borderRadius: 8,
              boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.1)',
              transition: 'box-shadow 0.3s ease',
              background: 'white'
            }}
          />

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <>
              <Tooltip title="View Pharmacies">
                <Button
                  style={{
                    fontSize: 'large',
                    backgroundColor:
                      selectedTab === 'Pharmacies'
                        ? '#ADD8E6'
                        : 'transparent'
                  }}
                  onClick={() => {
                    setSelectedTab('Pharmacies');
                    navigate('/pharmacies');
                  }}>
                  <Typography
                    fontWeight="bold"
                    sx={{
                      color:
                        selectedTab === 'Pharmacies'
                          ? '#000000'
                          : 'white'
                    }}>
                    Pharmacies
                  </Typography>
                </Button>
              </Tooltip>

              {userState.userProfile &&
                [
                  USER_ROLES.ADMIN,
                  USER_ROLES.SUPERADMIN
                ].includes(userState.userProfile.role) && (
                  <Button
                    style={{
                      fontSize: 'large',
                      backgroundColor:
                        selectedTab === 'Services'
                          ? '#ADD8E6'
                          : 'transparent'
                    }}
                    onClick={() => {
                      setSelectedTab('Services');
                      navigate('/services');
                    }}>
                    <Typography
                      fontWeight="bold"
                      sx={{
                        color:
                          selectedTab === 'Services'
                            ? '#000000'
                            : 'white'
                      }}>
                      Services
                    </Typography>
                  </Button>
                )}

              {userState.userProfile &&
                userState.userProfile.role ===
                  USER_ROLES.USER &&
                userState.myPharmacy.length === 1 && (
                  <Button
                    style={{
                      fontSize: 'large',
                      backgroundColor:
                        selectedTab === 'My Pharmacy'
                          ? '#ADD8E6'
                          : 'transparent'
                    }}
                    onClick={() => {
                      setSelectedTab('My Pharmacy');
                      navigate(
                        `/pharmacy/${userState.myPharmacy[0].recordId}`
                      );
                    }}>
                    <Typography
                      sx={{
                        color:
                          selectedTab === 'My Pharmacy'
                            ? '#000000'
                            : 'white'
                      }}
                      fontWeight="bold">
                      My Pharmacy
                    </Typography>
                  </Button>
                )}

              {userState.userProfile &&
                [
                  USER_ROLES.ADMIN,
                  USER_ROLES.SUPERADMIN
                ].includes(userState.userProfile.role) &&
                serviceRequestsState.requests && (
                  <Button
                    style={{
                      fontSize: 'large',
                      backgroundColor:
                        selectedTab === 'Requests'
                          ? '#ADD8E6'
                          : 'transparent'
                    }}
                    onClick={() => {
                      setSelectedTab('Requests');
                      navigate('/serviceRequests');
                    }}>
                    <Badge
                      badgeContent={
                        serviceRequestsState.requests.length
                      }
                      color="error">
                      <Typography
                        sx={{
                          color:
                            selectedTab === 'Requests'
                              ? '#000000'
                              : 'white'
                        }}
                        fontWeight="bold">
                        Requests
                      </Typography>
                    </Badge>
                  </Button>
                )}

              <Tooltip
                title={
                  userState.userProfile &&
                  [
                    USER_ROLES.ADMIN,
                    USER_ROLES.SUPERADMIN
                  ].includes(userState.userProfile.role)
                    ? 'View Feedbacks'
                    : 'Give Feedback'
                }>
                <Button
                  style={{
                    fontSize: 'large',
                    backgroundColor:
                      selectedTab === 'Feedback'
                        ? '#ADD8E6'
                        : 'transparent'
                  }}
                  onClick={() => {
                    setSelectedTab('Feedback');
                    navigate(
                      userState.userProfile &&
                        [
                          USER_ROLES.ADMIN,
                          USER_ROLES.SUPERADMIN
                        ].includes(
                          userState.userProfile.role
                        )
                        ? '/feedback'
                        : '/giveFeedback'
                    );
                  }}>
                  {userState.userProfile &&
                  [
                    USER_ROLES.ADMIN,
                    USER_ROLES.SUPERADMIN
                  ].includes(userState.userProfile.role) ? (
                    <Badge
                      badgeContent={
                        feedbacksState.feedbacks
                          ? feedbacksState.feedbacks.filter(
                              (feedback) =>
                                [
                                  FEEDBACK_STATUS.OPEN,
                                  FEEDBACK_STATUS.IN_PROGRESS
                                ].includes(feedback.status)
                            ).length
                          : 0
                      }
                      color="error">
                      <Typography
                        sx={{
                          color:
                            selectedTab === 'Feedback'
                              ? '#000000'
                              : 'white'
                        }}
                        fontWeight="bold">
                        Feedback
                      </Typography>
                    </Badge>
                  ) : (
                    <Typography
                      sx={{
                        color:
                          selectedTab === 'Feedback'
                            ? '#000000'
                            : 'white'
                      }}
                      fontWeight="bold">
                      Feedback
                    </Typography>
                  )}
                </Button>
              </Tooltip>

              {userState.userProfile &&
                [
                  USER_ROLES.ADMIN,
                  USER_ROLES.SUPERADMIN
                ].includes(userState.userProfile.role) && (
                  <Tooltip title="Data Config">
                    <Button
                      style={{
                        fontSize: 'large',
                        backgroundColor:
                          selectedTab === 'Data Ops'
                            ? '#ADD8E6'
                            : 'transparent'
                      }}
                      onClick={() => {
                        setSelectedTab('Data Ops');
                        navigate('/importexport');
                      }}>
                      <Typography
                        sx={{
                          color:
                            selectedTab === 'Data Ops'
                              ? '#000000'
                              : 'white'
                        }}
                        fontWeight="bold">
                        Data Ops
                      </Typography>
                    </Button>
                  </Tooltip>
                )}

              {userState.userProfile &&
                userState.userProfile.role ===
                  USER_ROLES.SUPERADMIN && (
                  <Button
                    style={{
                      fontSize: 'large',
                      backgroundColor:
                        selectedTab === 'Manage Admins'
                          ? '#ADD8E6'
                          : 'transparent'
                    }}
                    onClick={() => {
                      setSelectedTab('Manage Admins');
                      navigate('/manageAdmins');
                    }}>
                    <Typography
                      sx={{
                        color:
                          selectedTab === 'Manage Admins'
                            ? '#000000'
                            : 'white'
                      }}
                      fontWeight="bold">
                      Manage Admins
                    </Typography>
                  </Button>
                )}
            </>
          </Box>
          {userState.userProfile ? (
            <Tooltip title="User Profile">
              <IconButton onClick={handleProfileMenuOpen}>
                <Avatar
                  sx={{
                    bgcolor: deepPurple[500],
                    fontSize: 'large'
                  }}>
                  {getInitialsFromName(
                    userState.userProfile.name
                  )}
                </Avatar>
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              sx={{ color: 'white' }}>
              <AccountCircle fontSize="large" />
            </IconButton>
          )}
          <MenuOptions
            menuElement={menuElement}
            setMenuElement={setMenuElement}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavigationBar;
