import { ACTION_NAMES } from '../reduxActions';
import {
  createServiceApi,
  deleteServiceApi,
  getServicesApi,
  updateServiceApi
} from '../../helpers/apiCalls';
import { DispatchType } from '../store';
import {
  ServiceObject,
  ServiceQuestionObject,
  ServiceReferenceObject
} from '../../helpers/interfaces';
// import { loadServiceRequests } from '../serviceRequests/serviceRequestsActions';

export const loadServices = async (
  dispatch: DispatchType
) => {
  dispatch({ type: ACTION_NAMES.LOAD_SERVICES });
  const result = await getServicesApi(dispatch);
  dispatch({
    type: ACTION_NAMES.LOAD_SERVICES_SUCCESS,
    payload: result
  });
  // await loadServiceRequests(dispatch);
};

export const createServices = async (
  dispatch: DispatchType,
  serviceName: string,
  serviceDefinition: string,
  publicServiceDefinition: string,
  serviceQuestions: ServiceQuestionObject[],
  serviceReferences: ServiceReferenceObject[]
) => {
  await createServiceApi(
    dispatch,
    serviceName,
    serviceDefinition,
    publicServiceDefinition,
    serviceQuestions,
    serviceReferences
  );
  await loadServices(dispatch);
};

export const updateServices = async (
  dispatch: DispatchType,
  service: ServiceObject
) => {
  await updateServiceApi(dispatch, service);
  await loadServices(dispatch);
};

export const removeService = async (
  dispatch: DispatchType,
  serviceId: number
) => {
  await deleteServiceApi(dispatch, serviceId);
  await loadServices(dispatch);
};
