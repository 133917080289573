import { ACTION_NAMES } from '../reduxActions';

const initialState = {
  open: false,
  message: '',
  severity: 'success'
};

export const snackbarReducer = (
  state = initialState,
  action: {
    type: string;
    payload: { message: string; severity: string };
  }
) => {
  switch (action.type) {
    case ACTION_NAMES.CLOSE_SNACKBAR:
      return {
        ...state,
        open: false,
        message: '',
        severity: 'success'
      };
    case ACTION_NAMES.OPEN_SNACKBAR:
      return {
        ...state,
        open: true,
        message: action.payload.message as string,
        severity:
          action.payload.severity === 'error'
            ? (action.payload.severity as string)
            : 'success'
      };
    default:
      return state;
  }
};
