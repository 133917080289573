import { ACTION_NAMES } from '../reduxActions';
import {
  approveRejectServiceRequestsApi,
  createServiceRequestsApi,
  getServiceRequestsApi
} from '../../helpers/apiCalls';
import { DispatchType } from '../store';
import { ServiceRequestObject } from '../../helpers/interfaces';
import { loadPharmaciesServicesMapping } from '../pharmaciesServicesMapping/pharmaciesServicesMappingActions';

export const loadServiceRequests = async (
  dispatch: DispatchType
) => {
  dispatch({
    type: ACTION_NAMES.LOAD_SERVICE_REQUESTS
  });
  const result = await getServiceRequestsApi(dispatch);
  dispatch({
    type: ACTION_NAMES.LOAD_SERVICE_REQUESTS_SUCCESS,
    payload: result
  });
  await loadPharmaciesServicesMapping(dispatch);
};

export const createServiceRequests = async (
  dispatch: DispatchType,
  serviceRequests: ServiceRequestObject[],
  userId: number,
  userRole: string,
  questions: string[],
  serviceName: string
) => {
  await createServiceRequestsApi(
    dispatch,
    serviceRequests,
    userId,
    userRole,
    questions,
    serviceName
  );
  await loadServiceRequests(dispatch);
};

export const approveRejectRequests = async (
  dispatch: DispatchType,
  serviceRequest: ServiceRequestObject,
  userId: number,
  userRole: string,
  action: string,
  actionComment: string
) => {
  await approveRejectServiceRequestsApi(
    dispatch,
    serviceRequest,
    userId,
    userRole,
    action,
    actionComment
  );
  await loadServiceRequests(dispatch);
};
