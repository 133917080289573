import {
  createAdminApi,
  getAdminUsersApi,
  updateAdminApi
} from '../../helpers/apiCalls';
import { ACTION_NAMES } from '../reduxActions';
import { DispatchType } from '../store';

export const loadAdminUsers = async (
  dispatch: DispatchType
) => {
  dispatch({
    type: ACTION_NAMES.LOAD_ADMIN_USERS
  });
  const result = await getAdminUsersApi(dispatch);
  dispatch({
    type: ACTION_NAMES.LOAD_ADMIN_USERS_SUCCESS,
    payload: result
  });
};

export const updateAdmin = async (
  dispatch: DispatchType,
  superAdminEmail: string,
  adminUserId: number,
  adminEmail: string,
  newName: string,
  action: string
) => {
  await updateAdminApi(
    dispatch,
    superAdminEmail,
    adminUserId,
    adminEmail,
    newName,
    action
  );
  await loadAdminUsers(dispatch);
};

export const createAdminUser = async (
  dispatch: DispatchType,
  name: string,
  email: string
) => {
  await createAdminApi(dispatch, name, email);
  await loadAdminUsers(dispatch);
};
