import { DATA_LOAD_STATUS } from '../../helpers/constant';
import { PharmacyService } from '../../helpers/interfaces';
import { ACTION_NAMES } from '../reduxActions';

const initialState = {
  status: DATA_LOAD_STATUS.STATUS_LOADING,
  pharmaciesAndServicesList: [] as PharmacyService[]
};

export const pharmaicesServicesMappingReducer = (
  state = initialState,
  action: {
    type: string;
    payload: PharmacyService[] | string;
  }
) => {
  switch (action.type) {
    case ACTION_NAMES.LOAD_PHARMACIES_SERVICES_MAPPING:
      return {
        ...state,
        status: DATA_LOAD_STATUS.STATUS_LOADING,
        pharmaciesAndServicesList: [] as PharmacyService[]
      };
    case ACTION_NAMES.LOAD_PHARMACIES_SERVICES_MAPPING_SUCCESS:
      return {
        ...state,
        status: DATA_LOAD_STATUS.STATUS_DONE,
        pharmaciesAndServicesList:
          action.payload as PharmacyService[]
      };
    default:
      return state;
  }
};
