import { Typography } from '@mui/material';

const WarningComponentForIncorrectField: React.FC<{
  message: string;
}> = (props: { message: string }): JSX.Element => {
  return (
    <>
      {props.message === '' ? null : (
        <Typography
          variant="body2"
          align="left"
          style={{ color: 'red' }}>
          {props.message}
        </Typography>
      )}
    </>
  );
};

export default WarningComponentForIncorrectField;
