import {
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import { ServiceReferencesProps } from '../../helpers/interfaces';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { SERVICE_QUESTION_REFERENCE_STATUS } from '../../helpers/constant';

const ServiceReferences: React.FC<
  ServiceReferencesProps
> = (props: ServiceReferencesProps): JSX.Element => {
  const activeReferenceList =
    props.serviceReferencesList.filter(
      (reference) =>
        reference.status !==
        SERVICE_QUESTION_REFERENCE_STATUS.DELETED
    );

  return (
    <>
      <Typography variant="h5">
        Add reference links for your service
      </Typography>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          minWidth: '100%',
          marginBottom: '4%',
          marginTop: '2%'
        }}>
        <Tooltip title="Add new reference link">
          <Button
            variant="contained"
            onClick={() => {
              props.newServiceReference();
            }}>
            Add Reference Link
          </Button>
        </Tooltip>
      </Container>
      <Grid container>
        {activeReferenceList.map((reference) => {
          return (
            <>
              <Grid
                container
                item
                key={reference.serviceReferenceId}
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  mb: 3
                }}>
                <Grid item xs={4} mr={2}>
                  <TextField
                    name="Reference Name"
                    required
                    fullWidth
                    label="Reference Name"
                    value={reference.serviceReferenceName}
                    onChange={(event) => {
                      props.updateServiceReference(
                        reference.serviceReferenceId,
                        event.target.value,
                        reference.serviceReferenceLink,
                        reference.status
                      );
                    }}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={6.5}>
                  <TextField
                    name="Reference Link"
                    required
                    fullWidth
                    label="Reference Link"
                    value={reference.serviceReferenceLink}
                    onChange={(event) => {
                      props.updateServiceReference(
                        reference.serviceReferenceId,
                        reference.serviceReferenceName,
                        event.target.value,
                        reference.status
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      props.removeServiceReference(
                        reference.serviceReferenceId,
                        reference.status
                      );
                    }}>
                    <DeleteForeverIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <WarningComponentForIncorrectField
                    message={
                      reference.serviceReferenceLink.trim() ===
                      ''
                        ? ' Reference Link cannot be empty'
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
};

export default ServiceReferences;
