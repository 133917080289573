import React, { useState } from 'react';
import { PHARMACY_LAYOUT_USER } from '../../helpers/constant';
import { ServiceObject } from '../../helpers/interfaces';
import {
  Typography,
  Container,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { updateFilters } from '../../redux/pharmacies/pharmaciesActions';
import { ServiceCatalogDescription } from '../../helpers/ServiceCatalogDescription';
import {
  isValidZipCode,
  isValidZipCodeRadius
} from '../../helpers/utils';

const FilterSection: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isZipCodeValid, setIsZipCodeValid] =
    useState(true);
  const [isZipCodeRadiusValid, setIsZipCodeRadiusValid] =
    useState(true);
  const [infoService, setInfoService] =
    useState<ServiceObject | null>(null);

  const pharmaciesState = useSelector(
    (state: RootStateType) => state.pharmacies
  );

  const pharmaciesLayoutState = useSelector(
    (state: RootStateType) => state.pharmaciesLayout
  );

  const servicesState = useSelector(
    (state: RootStateType) => state.services
  );

  const dispatch = useDispatch();

  const showServiceLevelsFilter =
    pharmaciesLayoutState.user ===
    PHARMACY_LAYOUT_USER.PROVIDER;

  const handleOpenDialog = (
    service: ServiceObject | null
  ) => {
    setInfoService(service);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setInfoService(null);
    setOpenDialog(false);
  };

  const updateFilterValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updateFilters({
        ...pharmaciesState.filters,
        [event.target.name]: event.target.value
      })
    );
  };

  const updateZipCodeFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updateFilters({
        ...pharmaciesState.filters,
        [event.target.name]: event.target.value
      })
    );

    if (event.target.name === 'zipcode') {
      if (event.target.value === '') {
        setIsZipCodeValid(true);
        return;
      }
      setIsZipCodeValid(isValidZipCode(event.target.value));
    }

    if (event.target.name === 'zipcode_radius') {
      if (event.target.value === '') {
        setIsZipCodeRadiusValid(true);
        return;
      }
      setIsZipCodeRadiusValid(
        isValidZipCodeRadius(event.target.value)
      );
    }
  };

  const updateServiceFilters = (id: number) => {
    if (
      pharmaciesState.filters.servicesOffered.includes(id)
    ) {
      const updatedList =
        pharmaciesState.filters.servicesOffered.filter(
          (service) => service !== id
        );
      delete pharmaciesState.filters.serviceLevelsOffered[
        id
      ];
      dispatch(
        updateFilters({
          ...pharmaciesState.filters,
          servicesOffered: updatedList
        })
      );
    } else {
      const service_levels = servicesState.servicesList
        .filter((service) => service.serviceId === id)[0]
        .serviceQuestions.filter((question) =>
          question.serviceQuestionType.startsWith('Level')
        ).length;

      pharmaciesState.filters.serviceLevelsOffered = {
        ...pharmaciesState.filters.serviceLevelsOffered,
        [id]: Array.from(
          { length: service_levels },
          (_, index) => index + 1
        )
      };
      dispatch(
        updateFilters({
          ...pharmaciesState.filters,
          servicesOffered: [
            ...pharmaciesState.filters.servicesOffered,
            id
          ]
        })
      );
    }
  };

  const updateServiceLevelFilters = (
    service_id: number,
    selected_levels: number[]
  ) => {
    if (
      !pharmaciesState.filters.servicesOffered.includes(
        service_id
      )
    ) {
      pharmaciesState.filters.servicesOffered.push(
        service_id
      );
    }
    pharmaciesState.filters.serviceLevelsOffered = {
      ...pharmaciesState.filters.serviceLevelsOffered,
      [service_id]: [...selected_levels]
    };
    if (selected_levels.length === 0) {
      delete pharmaciesState.filters.serviceLevelsOffered[
        service_id
      ];
      pharmaciesState.filters.servicesOffered =
        pharmaciesState.filters.servicesOffered.filter(
          (service) => service !== service_id
        );
    }

    dispatch(
      updateFilters({
        ...pharmaciesState.filters
      })
    );
  };

  return (
    <>
      {
        <Container
          style={{
            border: '0.3px solid rgba(0, 0, 0, 0.05)',
            borderRadius: 8,
            boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.1)',
            transition: 'box-shadow 0.3s ease',
            padding: '1rem'
          }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            fontSize="large"
            ml={2}>
            Filters
          </Typography>

          <Divider />

          <Box>
            <Typography
              variant="h6"
              fontStyle="italic"
              mt={1}
              key="locationFilters">
              Location Filters
            </Typography>
            <TextField
              label="Name"
              name="pharmacyName"
              value={pharmaciesState.filters.pharmacyName}
              onChange={updateFilterValue}
              fullWidth
              margin="normal"
              key="name"
            />
            <TextField
              label="City"
              name="city"
              value={pharmaciesState.filters.city}
              onChange={updateFilterValue}
              fullWidth
              margin="normal"
              key="city"
            />
            <TextField
              label="County"
              name="county"
              value={pharmaciesState.filters.county}
              onChange={updateFilterValue}
              fullWidth
              margin="normal"
              key="county"
            />
            <Grid container spacing={1} alignItems="center">
              {/* Zipcode Field */}
              <Grid item xs={6} sm={6} md={6}>
                <TextField
                  label="Zipcode"
                  name="zipcode"
                  value={pharmaciesState.filters.zipcode}
                  onChange={updateZipCodeFilter}
                  fullWidth
                  margin="normal"
                  key="zipcode"
                  error={!isZipCodeValid}
                  helperText={
                    !isZipCodeValid
                      ? 'Invalid NC ZIP code'
                      : ' '
                  }
                />
              </Grid>
              {/* Radius Field */}
              <Grid item xs={6} sm={6} md={6}>
                <TextField
                  label="Radius (mi)"
                  name="zipcode_radius"
                  value={
                    pharmaciesState.filters.zipcode_radius
                  }
                  onChange={updateZipCodeFilter}
                  fullWidth
                  margin="normal"
                  key="zipcode_radius"
                  error={!isZipCodeRadiusValid}
                  helperText={
                    !isZipCodeRadiusValid
                      ? 'Minimum value: 1mi'
                      : ' '
                  }
                />
              </Grid>
            </Grid>
            <Divider />
            <Typography
              variant="h6"
              fontStyle="italic"
              mt={1}
              key="serviceFilters">
              Service Filters
            </Typography>
            <FormGroup>
              {servicesState.servicesList.map(
                (service, index, arr) => {
                  return (
                    <Box key={service.serviceId}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between">
                        <FormControlLabel
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          control={
                            <Checkbox
                              size="small"
                              checked={pharmaciesState.filters.servicesOffered.includes(
                                service.serviceId
                              )}
                              onChange={() =>
                                updateServiceFilters(
                                  service.serviceId
                                )
                              }
                            />
                          }
                          label={
                            <Typography
                              sx={{ paddingY: '5px' }}
                              variant="body2"
                              align="left">
                              {service.serviceName}
                            </Typography>
                          }
                        />
                        {/* Info Button */}
                        <IconButton
                          size="small"
                          aria-label="info"
                          onClick={() =>
                            handleOpenDialog(service)
                          }>
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Box>
                      {showServiceLevelsFilter && (
                        <Box
                          display="flex"
                          alignItems="center">
                          <ToggleButtonGroup
                            color="primary"
                            size="small"
                            value={
                              pharmaciesState.filters
                                .serviceLevelsOffered[
                                service.serviceId
                              ]
                            }
                            sx={{
                              marginTop: '1%',
                              marginLeft: '2rem',
                              height: '2rem'
                            }}
                            onChange={(
                              event,
                              selected_levels
                            ) =>
                              updateServiceLevelFilters(
                                service.serviceId,
                                selected_levels
                              )
                            }
                            aria-label="Service Level">
                            {service.serviceQuestions
                              .filter((question) =>
                                question.serviceQuestionType.startsWith(
                                  'Level'
                                )
                              )
                              .map((question) => {
                                const [level] =
                                  question.serviceQuestionType.split(
                                    ' '
                                  );
                                const level_label =
                                  'L' + level.slice(5);
                                const level_number =
                                  parseInt(level.slice(5));
                                return (
                                  <ToggleButton
                                    key={
                                      question.serviceQuestionId
                                    }
                                    value={level_number}>
                                    {level_label}
                                  </ToggleButton>
                                );
                              })}
                          </ToggleButtonGroup>
                        </Box>
                      )}
                      {index < arr.length - 1 && (
                        <Divider
                          sx={{
                            marginTop: '2%',
                            marginBottom: '2%',
                            borderBottomWidth: '1px',
                            borderColor: 'lightgray'
                          }}
                        />
                      )}
                    </Box>
                  );
                }
              )}
            </FormGroup>
            {/* Dialog for service level information */}
            {pharmaciesLayoutState.user ===
              PHARMACY_LAYOUT_USER.PATIENT && (
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}>
                <DialogTitle>
                  Service Description
                </DialogTitle>
                <DialogContent>
                  <Typography
                    sx={{ textAlign: 'justify' }}
                    variant="body2">
                    {infoService
                      ? infoService.publicServiceDefinition
                      : ''}
                  </Typography>
                </DialogContent>
              </Dialog>
            )}
            {pharmaciesLayoutState.user ===
              PHARMACY_LAYOUT_USER.PROVIDER && (
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}>
                <DialogTitle>
                  Service Information
                </DialogTitle>
                <DialogContent>
                  {(() => {
                    const serviceId =
                      infoService?.serviceId ?? -1;
                    const description =
                      ServiceCatalogDescription[serviceId]
                        ?.description ?? '';

                    return (
                      <Typography
                        sx={{ textAlign: 'justify' }}
                        variant="body2">
                        {description}
                      </Typography>
                    );
                  })()}
                  <Divider
                    sx={{
                      marginTop: '2%',
                      marginBottom: '2%',
                      borderBottomWidth: '1px',
                      borderColor: 'lightgray'
                    }}
                  />
                  {(() => {
                    const serviceId =
                      infoService?.serviceId ?? -1;
                    const levels =
                      ServiceCatalogDescription[serviceId]
                        ?.levels ?? [];

                    return levels.map(
                      (level, index, arr) => (
                        <>
                          <Typography
                            sx={{
                              textAlign: 'justify',
                              textDecoration: 'underline'
                            }}
                            variant="body2">
                            {`Pharmacies claiming a LEVEL ${'I'.repeat(
                              index + 1
                            )} Service Status:`}
                          </Typography>
                          <Typography
                            sx={{ textAlign: 'justify' }}
                            variant="body2">
                            {level}
                          </Typography>
                          {index < arr.length - 1 && (
                            <Divider
                              sx={{
                                marginTop: '2%',
                                marginBottom: '2%',
                                borderBottomWidth: '1px',
                                borderColor: 'lightgray'
                              }}
                            />
                          )}
                        </>
                      )
                    );
                  })()}
                </DialogContent>
              </Dialog>
            )}
          </Box>
        </Container>
      }
    </>
  );
};

export default FilterSection;
