import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { useEffect, useState } from 'react';
import SortFilterTable from '../../sharedComponents/SortFilterTable/SortFilterTable';
import { SERVICE_QUESTION_TYPES } from '../../helpers/constant';

const ReportPharmacyActivityStats: React.FC =
  (): JSX.Element => {
    const pharmaciesState = useSelector(
      (state: RootStateType) => state.pharmacies
    );

    const mappingsState = useSelector(
      (state: RootStateType) =>
        state.pharmaciesServicesMapping
    );

    const requestsState = useSelector(
      (state: RootStateType) => state.serviceRequests
    );

    const mailLogsState = useSelector(
      (state: RootStateType) => state.mailLogs
    );

    const [tableData, setTableData] = useState<
      { item: string; pharmacyCount: number }[]
    >([]);

    useEffect(() => {
      const pharmaciesWithRequests = Array.from(
        new Set(
          requestsState.requests.map((sr) => {
            sr?.pharmacyRecordId;
          }) || []
        )
      );

      const pharmaciesWithMappings = Array.from(
        new Set(
          mappingsState.pharmaciesAndServicesList
            .filter(
              (m) =>
                m &&
                m.questionType ===
                  SERVICE_QUESTION_TYPES.PROVIDER &&
                m.response !== 'None'
            )
            .map((mp) => mp?.pharmacyRecordId) || []
        )
      );

      const pharmaciesMail = Array.from(
        new Set(
          mailLogsState.mailLogs
            .filter((ml) =>
              [
                'RESET PASSWORD',
                'FORGOT PASSWORD'
              ].includes(ml.mailType)
            )
            .map((mlogs) => mlogs.permitNo) || []
        )
      );

      const pharmaciesWithSomeActivity =
        pharmaciesState.pharmaciesList.filter(
          (ph) =>
            [
              ...pharmaciesWithMappings,
              ...pharmaciesWithRequests
            ].includes(ph.recordId) ||
            [...pharmaciesMail].includes(ph.permitNo)
        ) || 0;

      const totalPharamcies =
        pharmaciesState.pharmaciesList.length || 0;
      setTableData([
        {
          item: 'Pharmacies that raised at least 1 service request',
          pharmacyCount: pharmaciesWithRequests.length
        },
        {
          item: 'Pharmacies mapped to at least 1 service',
          pharmacyCount: pharmaciesWithMappings.length
        },
        {
          item: 'Pharmacies that have reset their temporary password or requested a new one',
          pharmacyCount: pharmaciesMail.length
        },
        {
          item: 'Pharmacies that performed some activity',
          pharmacyCount: pharmaciesWithSomeActivity.length
        },
        {
          item: 'Total Pharamcies',
          pharmacyCount: totalPharamcies
        }
      ]);
    }, [pharmaciesState, mappingsState]);

    const tableColumns: {
      columnKey: string;
      columnDisplay: string;
      hidden: boolean;
    }[] = [
      {
        columnKey: 'item',
        columnDisplay: 'Item',
        hidden: false
      },
      {
        columnKey: 'pharmacyCount',
        columnDisplay: 'Number of Pharmacies',
        hidden: false
      }
    ];

    return (
      <>
        <SortFilterTable
          columnList={tableColumns}
          originalData={tableData}
          originalDataCount={-1}
          tableDataItem="NA"
        />
      </>
    );
  };

export default ReportPharmacyActivityStats;
