import {
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import * as XLSX from 'xlsx';
import {
  createBulkPharmacyApi,
  getFeedbacksApi,
  getMailLogsApi,
  getPharmaciesApi,
  getPharmacyServicesMappingApi,
  getPlainPharmacyHistoryApi,
  getPlainServiceQuestionsApi,
  getPlainServiceReferencesApi,
  getPlainServicesApi,
  getPlainUsersApi,
  getServiceRequestsApi
} from '../../helpers/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EmailIcon from '@mui/icons-material/Email';
import AssessmentIcon from '@mui/icons-material/Assessment';
import {
  IMPORT_PHARMACIES_COLUMNS,
  TABLE_NAMES
} from '../../helpers/constant';
import { PharmacyObject } from '../../helpers/interfaces';
import { RootStateType } from '../../redux/store';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';
import {
  convertExcelToJson,
  getTimestamp
} from '../../helpers/utils';
import { useNavigate } from 'react-router-dom';

const ImportExport: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState<number>(0);
  const [downloadProgress, setDownloadProgress] =
    useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] =
    useState<string>('');

  const navigate = useNavigate();

  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  const downloadAllData = async () => {
    try {
      setErrorMessage('');
      setDownloadProgress(true);
      setProgress(0);
      const wb = XLSX.utils.book_new();
      const apis = [
        { name: 'Pharmacies', method: getPharmaciesApi },
        { name: 'Services', method: getPlainServicesApi },
        {
          name: 'PharmacyServices',
          method: getPharmacyServicesMappingApi
        },
        {
          name: 'ServiceRequests',
          method: getServiceRequestsApi
        },
        {
          name: 'ServiceQuestions',
          method: getPlainServiceQuestionsApi
        },
        {
          name: 'ServiceReferences',
          method: getPlainServiceReferencesApi
        },
        {
          name: 'PharmacyHistory',
          method: getPlainPharmacyHistoryApi
        },
        { name: 'Users', method: getPlainUsersApi },
        { name: 'Feedbacks', method: getFeedbacksApi },
        { name: 'MailLogs', method: getMailLogsApi }
      ];
      await Promise.all(
        apis.map(async (api) => {
          const data = await api.method(dispatch);
          const ws = XLSX.utils.json_to_sheet(data);
          XLSX.utils.book_append_sheet(wb, ws, api.name);
          setProgress(progress + (1 / 8) * 100);
        })
      );

      XLSX.writeFile(
        wb,
        `NCPharmacyFinder - Dataset${getTimestamp()}.xlsx`
      );
      setProgress(0);
      setDownloadProgress(false);
      dispatch(
        openSnackbar(
          'Data downloaded successfully successfully!',
          'success'
        )
      );
    } catch (e) {
      dispatch(
        openSnackbar(
          `Download failed due to an error. ${
            e instanceof Error ? `Error: ${e.message}` : ``
          }`,
          'error'
        )
      );
    }
  };

  const importNewPharmacies = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const binaryData = e.target?.result as string;
        const workbook = XLSX.read(binaryData, {
          type: 'binary'
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(
          worksheet,
          { header: 1 }
        );

        try {
          const processedData = convertExcelToJson(
            parsedData as string[][],
            TABLE_NAMES.PHARMACIES
          );
          if (userState.userProfile) {
            await createBulkPharmacyApi(
              dispatch,
              processedData as PharmacyObject[],
              userState.userProfile?.userId
            );
            setErrorMessage('');
            dispatch(
              openSnackbar(
                'Pharmacies imported successfully!',
                'success'
              )
            );
          }
        } catch (error) {
          setErrorMessage(
            error instanceof Error
              ? error.message
              : 'Error occurred'
          );
        }
      };

      await reader.readAsBinaryString(file);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const getTemplateFile = async () => {
    setErrorMessage('');
    const wb = XLSX.utils.book_new();

    const data: Record<string, string | number | null>[] =
      [];
    const dataRow: Record<string, string | number | null> =
      {};
    IMPORT_PHARMACIES_COLUMNS.map((column) => {
      dataRow[column] = null;
    });
    data.push(dataRow);
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Pharmacies');

    XLSX.writeFile(wb, `Pharmacies template.xlsx`);
    setProgress(0);

    dispatch(
      openSnackbar(
        'Template file for pharmacies downloaded',
        'success'
      )
    );
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center">
      {errorMessage !== '' && (
        <Grid item xs={12} mb={2}>
          <Typography
            sx={{
              backgroundColor: 'orange',
              font: 'gray'
            }}>
            {errorMessage}
          </Typography>
        </Grid>
      )}

      <Grid item xs={4}>
        <IconButton onClick={getTemplateFile}>
          <Container>
            <CloudDownloadIcon fontSize="large" />
            <Typography>
              Get Pharmacy Template File
            </Typography>
          </Container>
        </IconButton>
      </Grid>

      <Grid item xs={4}>
        <IconButton onClick={downloadAllData}>
          <Container>
            <CloudDownloadIcon fontSize="large" />
            <Typography>Download All Data</Typography>
          </Container>
        </IconButton>
        {downloadProgress && (
          <>
            <Typography>
              Downloading {(progress * 8) / 100}/8 files
            </Typography>
            <LinearProgress
              sx={{ width: '100%' }}
              variant="determinate"
              value={progress}
            />
          </>
        )}
      </Grid>
      <Grid item xs={4}>
        <input
          ref={fileInputRef}
          type="file"
          accept=".xlsx, .xls"
          onChange={importNewPharmacies}
          style={{ display: 'none' }}
          id="fileInput"
        />
        <label htmlFor="fileInput">
          <IconButton component="span">
            <Container>
              <CloudUploadIcon fontSize="large" />
              <Typography>
                Import New Pharmacy Data
              </Typography>
            </Container>
          </IconButton>
        </label>
      </Grid>

      <Grid item xs={4}>
        <label htmlFor="trackMails">
          <IconButton
            component="span"
            onClick={() => {
              navigate('/trackMails');
            }}>
            <Container>
              <EmailIcon fontSize="large" />
              <Typography>Track Mails</Typography>
            </Container>
          </IconButton>
        </label>
      </Grid>

      <Grid item xs={4}>
        <label htmlFor="reports">
          <IconButton
            component="span"
            onClick={() => {
              navigate('/reports');
            }}>
            <Container>
              <AssessmentIcon fontSize="large" />
              <Typography>Reports</Typography>
            </Container>
          </IconButton>
        </label>
      </Grid>
    </Grid>
  );
};

export default ImportExport;
