import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { loadAdminUsers } from '../../redux/adminUsers/adminUserActions';
import { USER_ROLES } from '../../helpers/constant';
import UpdateAdminModal from './UpdateAdminModal';
import { AdminUserObject } from '../../helpers/interfaces';

const ManageAdmins: React.FC = (): JSX.Element => {
  const adminUsersState = useSelector(
    (state: RootStateType) => state.adminUsers
  );

  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  const [selectedAdmin, setSelectedAdmin] =
    useState<AdminUserObject | null>(null);

  const [selectedAction, setSelectedAction] =
    useState<string>('');

  const [openModal, setOpenModal] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    loadAdminUsers(dispatch);
  }, []);

  return (
    <>
      {!adminUsersState ||
      adminUsersState.adminUsers.length === 0 ? (
        <Typography variant="h6">
          Admin users are not present in the system
          currently
        </Typography>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: 20,
              paddingBottom: 10
            }}>
            <Button
              variant="contained"
              onClick={() => {
                setSelectedAction('CREATE NEW ADMIN');
                setSelectedAdmin(null);
                setOpenModal(true);
              }}>
              Create new admin
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead
                style={{
                  fontWeight: 'bold',
                  backgroundColor: '#f0f0f0'
                }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminUsersState.adminUsers.map((admin) => (
                  <TableRow key={admin.userId}>
                    <TableCell>{admin.name}</TableCell>
                    <TableCell>{admin.email}</TableCell>
                    {admin.role === USER_ROLES.ADMIN && (
                      <TableCell
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly'
                        }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setSelectedAdmin(admin);
                            setSelectedAction(
                              'UPDATE NAME'
                            );
                            setOpenModal(true);
                          }}>
                          Update Name
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setSelectedAdmin(admin);
                            setSelectedAction(
                              'ASSIGN NEW PASSWORD'
                            );
                            setOpenModal(true);
                          }}>
                          Assign New Password
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setSelectedAdmin(admin);
                            setSelectedAction(
                              'DELETE ADMIN'
                            );
                            setOpenModal(true);
                          }}>
                          Delete User
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {userState.userProfile &&
        selectedAction !== '' &&
        (selectedAdmin ||
          selectedAction === 'CREATE NEW ADMIN') && (
          <UpdateAdminModal
            superAdminEmail={userState.userProfile.email}
            action={selectedAction}
            admin={selectedAdmin}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        )}
    </>
  );
};

export default ManageAdmins;
