import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  Modal,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material';
import MiscellaneousServicesTwoToneIcon from '@mui/icons-material/MiscellaneousServicesTwoTone';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Copyright from '../../sharedComponents/Copyright/Copyright';
import {
  AddServiceModalProps,
  ServiceObject,
  ServiceQuestionObject,
  ServiceReferenceObject
} from '../../helpers/interfaces';
import ServiceDetails from './ServiceDetails';
import ServiceQuestions from './ServiceQuestions';
import {
  SERVICE_QUESTION_REFERENCE_STATUS,
  SERVICE_QUESTION_TYPES
} from '../../helpers/constant';
import {
  getLatestServiceQuestionId,
  getLatestServiceQuestionType,
  getLatestServiceReferenceId
} from '../../helpers/utils';
import ServiceReferences from './ServiceReferences';
import ServiceReview from './ServiceReview';
import {
  createServices,
  loadServices,
  updateServices
} from '../../redux/services/servicesActions';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';

const AddServiceModal: React.FC<AddServiceModalProps> = (
  props: AddServiceModalProps
): JSX.Element => {
  const [serviceId, setServiceId] = useState<number>(
    props.selectedService
      ? props.selectedService.serviceId
      : -1
  );
  const [servcieName, setServiceName] = useState<string>(
    props.selectedService
      ? props.selectedService.serviceName
      : ''
  );
  const [serviceNameMessage, setServiceNameMessage] =
    useState<string>('');
  const [serviceDefinition, setServiceDefinition] =
    useState<string>(
      props.selectedService
        ? props.selectedService.serviceDefinition
        : ''
    );
  const [
    serviceDefinitionMessage,
    setServiceDefinitionMessage
  ] = useState<string>('');
  const [
    publicServiceDefinition,
    setPublicServiceDefinition
  ] = useState<string>(
    props.selectedService
      ? props.selectedService.publicServiceDefinition
      : ''
  );
  const [
    publicServiceDefinitionMessage,
    setPublicServiceDefinitionMessage
  ] = useState<string>('');

  const [serviceQuestionsList, setServiceQuestionsList] =
    useState<ServiceQuestionObject[]>(
      props.selectedService
        ? props.selectedService.serviceQuestions
        : [
            {
              serviceQuestionId: -1,
              serviceQuestion: '',
              serviceQuestionType:
                SERVICE_QUESTION_TYPES.LEVEL1_PROVIDER,
              serviceId: serviceId,
              status: SERVICE_QUESTION_REFERENCE_STATUS.NEW
            }
          ]
    );

  const [serviceReferencesList, setServiceReferencesList] =
    useState<ServiceReferenceObject[]>(
      props.selectedService
        ? props.selectedService.serviceReferences
        : ([] as ServiceReferenceObject[])
    );

  const [serviceProgress, setServiceProgress] =
    useState<number>(1);

  useEffect(() => {
    setServiceProgress(1);
    setServiceId(
      props.selectedService
        ? props.selectedService.serviceId
        : -1
    );
    setServiceName(
      props.selectedService
        ? props.selectedService.serviceName
        : ''
    );
    setServiceDefinition(
      props.selectedService
        ? props.selectedService.serviceDefinition
        : ''
    );
    setServiceQuestionsList(
      props.selectedService
        ? props.selectedService.serviceQuestions
        : [
            {
              serviceQuestionId: -1,
              serviceQuestion: '',
              serviceQuestionType:
                SERVICE_QUESTION_TYPES.LEVEL1_PROVIDER,
              serviceId: serviceId,
              status: SERVICE_QUESTION_REFERENCE_STATUS.NEW
            }
          ]
    );
    setServiceReferencesList(
      props.selectedService
        ? props.selectedService.serviceReferences
        : ([] as ServiceReferenceObject[])
    );
  }, [props.isModalOpen]);

  const newServiceQuestion = (questionType: string) => {
    setServiceQuestionsList([
      ...serviceQuestionsList,
      {
        serviceQuestionId: getLatestServiceQuestionId(
          serviceQuestionsList
        ),
        serviceQuestion: '',
        serviceQuestionType:
          questionType ===
          SERVICE_QUESTION_TYPES.SUPPLEMENTAL
            ? questionType
            : getLatestServiceQuestionType(
                questionType,
                serviceQuestionsList
              ),
        serviceId: serviceId,
        status: SERVICE_QUESTION_REFERENCE_STATUS.NEW
      }
    ]);
  };

  const newServiceReference = () => {
    setServiceReferencesList([
      ...serviceReferencesList,
      {
        serviceReferenceName: '',
        serviceReferenceId: getLatestServiceReferenceId(
          serviceReferencesList
        ),
        serviceReferenceLink: '',
        serviceId: serviceId,
        status: SERVICE_QUESTION_REFERENCE_STATUS.NEW
      }
    ]);
  };

  const removeServiceQuestion = (
    questionId: number,
    status: string
  ) => {
    let updatedQList;
    if (status === SERVICE_QUESTION_REFERENCE_STATUS.NEW) {
      updatedQList = serviceQuestionsList.filter(
        (question) =>
          question.serviceQuestionId !== questionId
      );
    } else {
      updatedQList = Object.assign(
        [] as ServiceQuestionObject[],
        serviceQuestionsList
      );
      updatedQList.map(
        (question: ServiceQuestionObject) => {
          if (question.serviceQuestionId === questionId) {
            question.status =
              SERVICE_QUESTION_REFERENCE_STATUS.DELETED;
          }
        }
      );
    }
    setServiceQuestionsList([...updatedQList]);
  };

  const removeServiceReference = (
    referenceId: number,
    status: string
  ) => {
    let updatedList;
    if (status === SERVICE_QUESTION_REFERENCE_STATUS.NEW) {
      updatedList = serviceReferencesList.filter(
        (reference) =>
          reference.serviceReferenceId !== referenceId
      );
    } else {
      updatedList = Object.assign(
        [] as ServiceQuestionObject[],
        serviceReferencesList
      );
      updatedList.map(
        (reference: ServiceReferenceObject) => {
          if (
            reference.serviceReferenceId === referenceId
          ) {
            reference.status =
              SERVICE_QUESTION_REFERENCE_STATUS.DELETED;
          }
        }
      );
    }
    setServiceReferencesList([...updatedList]);
  };

  const updateServiceQuestions = (
    questionId: number,
    questionValue: string,
    status: string
  ) => {
    const updatedList = Object.assign(
      [] as ServiceQuestionObject[],
      serviceQuestionsList
    );
    updatedList.map((question: ServiceQuestionObject) => {
      if (question.serviceQuestionId === questionId) {
        question.serviceQuestion = questionValue;
        if (
          status ===
          SERVICE_QUESTION_REFERENCE_STATUS.EXISTING
        ) {
          question.status =
            SERVICE_QUESTION_REFERENCE_STATUS.UPDATED;
        }
      }
    });
    setServiceQuestionsList([...updatedList]);
  };

  const updateServiceQuestionType = (
    questionId: number,
    questionTypeValue: string,
    status: string
  ) => {
    const updatedList = Object.assign(
      [] as ServiceQuestionObject[],
      serviceQuestionsList
    );
    updatedList.map((question: ServiceQuestionObject) => {
      if (question.serviceQuestionId === questionId) {
        question.serviceQuestionType = questionTypeValue;
        if (
          status ===
          SERVICE_QUESTION_REFERENCE_STATUS.EXISTING
        ) {
          question.status =
            SERVICE_QUESTION_REFERENCE_STATUS.UPDATED;
        }
      }
    });
    setServiceQuestionsList([...updatedList]);
  };

  const updateServiceReferences = (
    referenceId: number,
    referenceName: string,
    referenceValue: string,
    status: string
  ) => {
    const updatedList = Object.assign(
      [] as ServiceReferenceObject[],
      serviceReferencesList
    );
    updatedList.map((reference: ServiceReferenceObject) => {
      if (reference.serviceReferenceId === referenceId) {
        reference.serviceReferenceName = referenceName;
        reference.serviceReferenceLink = referenceValue;
        if (
          status ===
          SERVICE_QUESTION_REFERENCE_STATUS.EXISTING
        ) {
          reference.status =
            SERVICE_QUESTION_REFERENCE_STATUS.UPDATED;
        }
      }
    });
    setServiceReferencesList([...updatedList]);
  };

  const navigate = useNavigate();

  const defaultTheme = createTheme();

  useEffect(() => {
    setServiceNameMessage(
      servcieName.trim() === ''
        ? 'Service Name cannot be empty'
        : ''
    );
  }, [servcieName]);

  useEffect(() => {
    setServiceDefinitionMessage(
      serviceDefinition.trim() === ''
        ? 'Service Definition cannot be empty'
        : ''
    );
  }, [serviceDefinition]);

  useEffect(() => {
    setPublicServiceDefinitionMessage(
      publicServiceDefinition.trim() === ''
        ? 'Public-facing service definition cannot be empty'
        : ''
    );
  }, [publicServiceDefinition]);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (serviceProgress < 4) {
      setServiceProgress(serviceProgress + 1);
    } else {
      setLoading(true);
      if (props.selectedService) {
        await updateServices(dispatch, {
          serviceId: serviceId,
          serviceName: servcieName,
          serviceDefinition: serviceDefinition,
          publicServiceDefinition: publicServiceDefinition,
          serviceQuestions: serviceQuestionsList,
          serviceReferences: serviceReferencesList
        } as ServiceObject);
        dispatch(
          openSnackbar(
            'Service updated successfully!',
            'success'
          )
        );
      } else {
        await createServices(
          dispatch,
          servcieName,
          serviceDefinition,
          publicServiceDefinition,
          serviceQuestionsList,
          serviceReferencesList
        );
        dispatch(
          openSnackbar(
            'Service created successfully!',
            'success'
          )
        );
      }
      setLoading(false);
      props.setIsModalOpen(false);
      navigate('/services');
    }
  };

  const handleCancel = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setServiceProgress(1);
    setServiceId(-1);
    setServiceName('');
    setServiceDefinition('');
    setPublicServiceDefinition('');
    setServiceQuestionsList(
      props.selectedService
        ? props.selectedService.serviceQuestions
        : [
            {
              serviceQuestionId: -1,
              serviceQuestion: '',
              serviceQuestionType:
                SERVICE_QUESTION_TYPES.LEVEL1_PROVIDER,
              serviceId: serviceId,
              status: SERVICE_QUESTION_REFERENCE_STATUS.NEW
            }
          ]
    );
    setServiceReferencesList([]);
    await loadServices(dispatch);
    props.setIsModalOpen(false);
  };

  const handlePrevious = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setServiceProgress(serviceProgress - 1);
  };

  const getNextButtonText = (): string => {
    switch (serviceProgress) {
      case 1:
        return 'ADD QUESTIONS';
      case 2:
        return 'ADD REFERENCES';
      case 3:
        return 'REVIEW';
      case 4:
        return 'SUBMIT';
      default:
        return 'NEXT: ADD QUESTIONS';
    }
  };

  const disableNextButton = (): boolean => {
    switch (serviceProgress) {
      case 1:
        return (
          servcieName === '' ||
          serviceDefinition === '' ||
          publicServiceDefinition === ''
        );
      case 2:
        return serviceQuestionsList
          .filter(
            (question) =>
              question.status !==
              SERVICE_QUESTION_REFERENCE_STATUS.DELETED
          )
          .map((question) => question.serviceQuestion)
          .includes('');
      case 3:
        return serviceReferencesList
          .filter(
            (reference) =>
              reference.status !==
              SERVICE_QUESTION_REFERENCE_STATUS.DELETED
          )
          .map(
            (reference) =>
              reference.serviceReferenceName +
              reference.serviceReferenceLink
          )
          .includes('');
      case 4:
        return false;
      default:
        return false;
    }
  };

  return (
    <Modal
      open={props.isModalOpen}
      sx={{ overflow: 'scroll' }}
      onClose={(
        event: React.MouseEvent<HTMLButtonElement>
      ) => handleCancel(event)}>
      <ThemeProvider theme={defaultTheme}>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            background: '#fff',
            minWidth: '60%',
            paddingBottom: '1%'
          }}>
          <CssBaseline />
          <Box
            minWidth="80%"
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Avatar sx={{ m: 1, bgcolor: '#4169e1' }}>
              <MiscellaneousServicesTwoToneIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {props.selectedService
                ? 'Update'
                : 'Create new'}{' '}
              service
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={() => {}}
              sx={{ mt: 2 }}>
              {serviceProgress === 1 && (
                <ServiceDetails
                  serviceName={servcieName}
                  setServcieName={setServiceName}
                  serviceDefinition={serviceDefinition}
                  setServcieDefinition={
                    setServiceDefinition
                  }
                  publicServiceDefinition={
                    publicServiceDefinition
                  }
                  setPublicServcieDefinition={
                    setPublicServiceDefinition
                  }
                  serviceNameMessage={serviceNameMessage}
                  serviceDefinitionMessage={
                    serviceDefinitionMessage
                  }
                  publicServiceDefinitionMessage={
                    publicServiceDefinitionMessage
                  }
                />
              )}
              {serviceProgress === 2 && (
                <ServiceQuestions
                  serviceQuestionsList={
                    serviceQuestionsList
                  }
                  newServiceQuestion={newServiceQuestion}
                  removeServiceQuestion={
                    removeServiceQuestion
                  }
                  updateServiceQuestion={
                    updateServiceQuestions
                  }
                  updateServiceQuestionType={
                    updateServiceQuestionType
                  }
                />
              )}
              {serviceProgress === 3 && (
                <ServiceReferences
                  serviceReferencesList={
                    serviceReferencesList
                  }
                  newServiceReference={newServiceReference}
                  removeServiceReference={
                    removeServiceReference
                  }
                  updateServiceReference={
                    updateServiceReferences
                  }
                />
              )}
              {serviceProgress === 4 && (
                <ServiceReview
                  serviceName={servcieName}
                  serviceDefinition={serviceDefinition}
                  publicServiceDefinition={
                    publicServiceDefinition
                  }
                  serviceQuestions={serviceQuestionsList}
                  serviceReferences={serviceReferencesList}
                />
              )}
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={serviceProgress === 1 ? 6 : 4}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="error"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={(event) => {
                      handleCancel(event);
                    }}>
                    Cancel
                  </Button>
                </Grid>
                {serviceProgress > 1 && (
                  <Grid item xs={12} sm={12} md={4}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handlePrevious}>
                      PREVIOUS
                    </Button>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={serviceProgress === 1 ? 6 : 4}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color={
                      serviceProgress < 4
                        ? 'primary'
                        : 'success'
                    }
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSubmit}
                    disabled={
                      loading || disableNextButton()
                    }>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      getNextButtonText()
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright />
        </Container>
      </ThemeProvider>
    </Modal>
  );
};

export default AddServiceModal;
