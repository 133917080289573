import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  CircularProgress
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import {
  validateConfirmPassword,
  validatePassword
} from '../../helpers/utils';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import { resetPasswordApi } from '../../helpers/apiCalls';
import { useNavigate } from 'react-router-dom';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';

const ResetPassword: React.FC = (): JSX.Element => {
  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] =
    useState<string>('');
  const [newPassword, setNewPassword] =
    useState<string>('');
  const [confirmPassword, setConfirmPassword] =
    useState<string>('');
  const [newPasswordMessage, setNewPasswordMessage] =
    useState<string>('');
  const [
    confirmPasswordMessage,
    setConfirmPasswordMessage
  ] = useState<string>('');
  const [resultMessage, setResultMessage] =
    useState<string>('');
  const [submitLoading, setSubmitLoading] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const handleUpdatePassword = async () => {
    setSubmitLoading(true);
    const resetResult = await resetPasswordApi(
      userState.userProfile?.permitNo || '',
      userState.userProfile?.userId || -1,
      currentPassword,
      newPassword
    );
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    if (resetResult === 'SUCCESS') {
      dispatch(
        openSnackbar(
          'Password updated successfully',
          'success'
        )
      );
      setResultMessage('');
      navigate('/');
    } else {
      setResultMessage(resetResult);
    }
    setSubmitLoading(false);
  };

  useEffect(() => {
    setNewPasswordMessage(validatePassword(newPassword));
    setConfirmPasswordMessage(
      validateConfirmPassword(newPassword, confirmPassword)
    );
  }, [newPassword, confirmPassword]);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Paper elevation={3} style={{ padding: 20 }}>
            <Typography
              variant="h5"
              align="center"
              gutterBottom>
              Reset Password
            </Typography>
            <form>
              <TextField
                label="Current Password"
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                value={currentPassword}
                onChange={(e) =>
                  setCurrentPassword(e.target.value)
                }
              />
              <TextField
                label="New Password"
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                value={newPassword}
                onChange={(e) =>
                  setNewPassword(e.target.value)
                }
              />
              <WarningComponentForIncorrectField
                message={newPasswordMessage}
              />
              <TextField
                label="Confirm Password"
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                value={confirmPassword}
                onChange={(e) =>
                  setConfirmPassword(e.target.value)
                }
              />
              <WarningComponentForIncorrectField
                message={confirmPasswordMessage}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: 20 }}
                onClick={handleUpdatePassword}
                disabled={
                  submitLoading ||
                  newPasswordMessage !== '' ||
                  confirmPasswordMessage !== ''
                }>
                {submitLoading ? (
                  <CircularProgress />
                ) : (
                  'Reset Password'
                )}
              </Button>
              <WarningComponentForIncorrectField
                message={resultMessage}
              />
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;
