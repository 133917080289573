import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import ViewListTwoToneIcon from '@mui/icons-material/ViewListTwoTone';
import CalendarViewMonthTwoToneIcon from '@mui/icons-material/CalendarViewMonthTwoTone';
import {
  DATA_LOAD_STATUS,
  USER_ROLES,
  VIEW_MODE,
  PHARMACY_LAYOUT_USER
} from '../../helpers/constant';
import PharmacyContainer from '../../components/PharmacyContainer/PharmacyContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import {
  updatePharmaciesLayout,
  updatePharmaciesLayoutUser
} from '../../redux/pharmaciesLayout/pharmaciesLayoutActions';
import { loadPharmacies } from '../../redux/pharmacies/pharmaciesActions';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Error from '../../components/Error/Error';
import PaginationBar from './PaginationBar';
import FilterSection from '../../components/FilterSection/FilterSection';
import { loadServices } from '../../redux/services/servicesActions';
import { loadPharmaciesServicesMapping } from '../../redux/pharmaciesServicesMapping/pharmaciesServicesMappingActions';
import { filterPharamciesByFilter } from '../../helpers/utils';
import { loadServiceRequests } from '../../redux/serviceRequests/serviceRequestsActions';
import { loadFeedbacks } from '../../redux/feedbacks/feedbacksActions';
import Disclaimer from '../../sharedComponents/Disclaimer/Disclaimer';
import ITACCBanner from '../../sharedComponents/ITACCBanner/ITACCBanner';

const PharmaciesLayout: React.FC = (): JSX.Element => {
  const pharmaciesLayoutState = useSelector(
    (state: RootStateType) => state.pharmaciesLayout
  );
  const pharmaciesState = useSelector(
    (state: RootStateType) => state.pharmacies
  );

  const pharmacyServicesState = useSelector(
    (state: RootStateType) =>
      state.pharmaciesServicesMapping
  );

  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  const errorState = useSelector(
    (state: RootStateType) => state.error
  );
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    loadPharmacies(dispatch);
    loadServices(dispatch);
    loadPharmaciesServicesMapping(dispatch);

    let user = PHARMACY_LAYOUT_USER.PATIENT;
    if (location.pathname === '/pharmacies') {
      user = PHARMACY_LAYOUT_USER.PATIENT;
    }
    if (location.pathname === '/providerPharmacies') {
      user = PHARMACY_LAYOUT_USER.PROVIDER;
    }
    dispatch(updatePharmaciesLayoutUser(user));

    if (
      userState.userProfile &&
      [USER_ROLES.ADMIN, USER_ROLES.SUPERADMIN].includes(
        userState.userProfile.role
      )
    ) {
      loadServiceRequests(dispatch);
      loadFeedbacks(dispatch);
    }
  }, [userState, location]);

  const getCurrentPagePharmacyCount = (): number => {
    const totalPages = Math.ceil(
      filterPharamciesByFilter(
        pharmaciesState.pharmaciesList,
        pharmacyServicesState.pharmaciesAndServicesList,
        pharmaciesState.filters
      ).length / pharmaciesState.paginationPageCapacity
    );
    if (pharmaciesState.currentPagination < totalPages) {
      return pharmaciesState.paginationPageCapacity;
    }
    return (
      filterPharamciesByFilter(
        pharmaciesState.pharmaciesList,
        pharmacyServicesState.pharmaciesAndServicesList,
        pharmaciesState.filters
      ).length % pharmaciesState.paginationPageCapacity
    );
  };

  const PaginationAndLayout = () => {
    return (
      <Container
        style={{ display: 'flex', marginTop: '1%' }}>
        <Container>
          <PaginationBar />
        </Container>
        <Tooltip
          title={
            pharmaciesLayoutState.mode ===
            VIEW_MODE.LIST_VIEW
              ? VIEW_MODE.GRID_VIEW
              : VIEW_MODE.LIST_VIEW
          }
          arrow>
          <IconButton
            onClick={() => {
              dispatch(updatePharmaciesLayout());
            }}>
            {pharmaciesLayoutState.mode ===
            VIEW_MODE.LIST_VIEW ? (
              <CalendarViewMonthTwoToneIcon
                style={{ fontSize: 'xx-large' }}
              />
            ) : (
              <ViewListTwoToneIcon
                style={{ fontSize: 'xx-large' }}
              />
            )}
          </IconButton>
        </Tooltip>
      </Container>
    );
  };

  return (
    <>
      {errorState.enableError && (
        <Error message={errorState.message} />
      )}

      {!errorState.enableError &&
        pharmaciesState.status ===
          DATA_LOAD_STATUS.STATUS_LOADING && (
          <LoadingSpinner />
        )}

      {!errorState.enableError &&
        pharmaciesState.status ===
          DATA_LOAD_STATUS.STATUS_DONE && (
          <Grid container>
            <Grid item xs={3}>
              <FilterSection />
            </Grid>
            <Grid item xs={9}>
              <ITACCBanner />
              <Disclaimer />
              <PaginationAndLayout />
              <PharmacyContainer />
              {pharmaciesLayoutState.mode ===
                VIEW_MODE.LIST_VIEW &&
                getCurrentPagePharmacyCount() > 5 && (
                  <PaginationAndLayout />
                )}
            </Grid>
          </Grid>
        )}
    </>
  );
};

export default PharmaciesLayout;
