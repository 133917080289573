import {
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { SortFilterTableProps } from '../../helpers/interfaces';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import * as XLSX from 'xlsx';
import { useDispatch } from 'react-redux';
import { getTimestamp } from '../../helpers/utils';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';

const SortFilterTable: React.FC<SortFilterTableProps> = (
  props: SortFilterTableProps
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filteredData, setFilteredData] = useState<any[]>(
    props.originalData
  );

  const [orderBy, setOrderBy] = useState<string>(
    props.columnList[0].columnKey
  );

  const [orderDirection, setOrderDirection] = useState<
    'asc' | 'desc'
  >('asc');

  const [filterList, setFilterList] = useState<{
    [key: string]: string;
  }>({});

  const updateFilter = (key: string, value: string) => {
    setFilterList({ ...filterList, [key]: value });
  };

  useEffect(() => {
    const result = props.originalData;

    const validResult = result.filter((p) => {
      return Object.keys(filterList).every((k) => {
        return (
          k === '' ||
          (k &&
            (p[k]?.toString() || '')
              .toLowerCase()
              .includes(filterList[k].toLowerCase()))
        );
      });
    });

    setFilteredData(
      [...validResult].sort((a, b) =>
        orderDirection === 'asc'
          ? a[orderBy]! > b[orderBy]!
            ? 1
            : -1
          : a[orderBy]! < b[orderBy]!
          ? 1
          : -1
      )
    );
  }, [
    orderBy,
    orderDirection,
    filterList,
    props.originalData
  ]);

  const dispatch = useDispatch();
  const [progress, setProgress] = useState<number>(0);
  const [downloadProgress, setDownloadProgress] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] =
    useState<string>('');
  const downloadData = async () => {
    try {
      setErrorMessage('');
      setDownloadProgress(true);
      setProgress(0);

      const wb = XLSX.utils.book_new();
      const headers = props.columnList.map(
        (column) => column.columnDisplay
      );
      const ws_data = filteredData.map((data) =>
        Object.values(data)
      );
      ws_data.unshift(headers);
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      XLSX.utils.book_append_sheet(wb, ws, 'Report Data');
      setProgress(progress + 50);

      XLSX.writeFile(
        wb,
        `NCPharmacyFinder - Report${getTimestamp()}.xlsx`
      );
      setProgress(0);
      setDownloadProgress(false);
      dispatch(
        openSnackbar(
          'Data downloaded successfully!',
          'success'
        )
      );
    } catch (e) {
      dispatch(
        openSnackbar(
          `Download failed due to an error. ${
            e instanceof Error ? `Error: ${e.message}` : ``
          }`,
          'error'
        )
      );
    }
  };

  return (
    <>
      <>
        {filteredData.length === 0 &&
          props.originalDataCount >= 0 && (
            <Typography variant="h6">
              No {props.tableDataItem} for the selected
              filter.
            </Typography>
          )}
        <Grid
          container
          direction="row"
          alignItems="flex-end">
          <Grid item xs={11}>
            {props.originalDataCount >= 0 && (
              <Typography
                variant="body1"
                align="left"
                ml={1}>
                {`Displaying ${filteredData.length} out of ${props.originalDataCount} ${props.tableDataItem}.`}
              </Typography>
            )}
          </Grid>
          <Grid item xs={1}>
            {filteredData.length > 0 && (
              <Container>
                {errorMessage !== '' && (
                  <Typography
                    sx={{
                      backgroundColor: 'orange',
                      font: 'gray'
                    }}>
                    {errorMessage}
                  </Typography>
                )}
                <IconButton onClick={downloadData}>
                  <Container>
                    <CloudDownloadIcon fontSize="large" />
                  </Container>
                </IconButton>
                {downloadProgress && (
                  <>
                    <Typography>
                      Downloading files
                    </Typography>
                    <LinearProgress
                      sx={{ width: '100%' }}
                      variant="determinate"
                      value={progress}
                    />
                  </>
                )}
              </Container>
            )}
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          sx={{ maxHeight: '75vh' }}>
          <Table stickyHeader>
            <TableHead
              style={{
                fontWeight: 'bold',
                backgroundColor: '#f0f0f0'
              }}>
              <TableRow>
                {props.columnList
                  .filter((tcol) => !tcol.hidden)
                  .map((tcol) => {
                    return (
                      <TableCell
                        key={tcol.columnKey}
                        style={{
                          fontWeight: 'bold',
                          backgroundColor: '#f0f0f0'
                        }}>
                        <TableSortLabel
                          active={
                            orderBy === tcol.columnKey
                          }
                          direction={
                            orderBy === tcol.columnKey
                              ? orderDirection
                              : undefined
                          }
                          onClick={() => {
                            setOrderBy(tcol.columnKey);
                            setOrderDirection(
                              orderBy === tcol.columnKey &&
                                orderDirection === 'asc'
                                ? 'desc'
                                : 'asc'
                            );
                          }}>
                          {tcol.columnDisplay}
                        </TableSortLabel>
                        {tcol.columnDisplay !== '#' && (
                          <Container>
                            <TextField
                              sx={{
                                padding: 0,
                                height: '2px'
                              }}
                              value={
                                filterList &&
                                filterList[tcol.columnKey]
                                  ? filterList[
                                      tcol.columnKey
                                    ]
                                  : ''
                              }
                              placeholder={`Filter by ${tcol.columnDisplay}`}
                              variant="standard"
                              onChange={(event) => {
                                updateFilter(
                                  tcol.columnKey,
                                  event.target.value
                                );
                              }}
                            />
                            <IconButton
                              onClick={() => {
                                updateFilter(
                                  tcol.columnKey,
                                  ''
                                );
                              }}>
                              <CloseIcon />
                            </IconButton>
                          </Container>
                        )}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((data, index) => (
                <TableRow key={index}>
                  {props.columnList
                    .filter((tcol) => !tcol.hidden)
                    .map((tcol, colIndex) => {
                      return (
                        <TableCell key={colIndex}>
                          {data[tcol.columnKey]}
                        </TableCell>
                      );
                    })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </>
  );
};

export default SortFilterTable;
