import { ACTION_NAMES } from '../reduxActions';
import {
  createPharmacyApi,
  getPharmaciesApi,
  updatePharmacyApi
} from '../../helpers/apiCalls';
import { DispatchType } from '../store';
import {
  FilterProperties,
  PharmacyObject
} from '../../helpers/interfaces';

export const loadPharmacies = async (
  dispatch: DispatchType
) => {
  dispatch({ type: ACTION_NAMES.LOAD_PHARMACIES });
  const result = await getPharmaciesApi(dispatch);
  dispatch({
    type: ACTION_NAMES.LOAD_PHARMACIES_SUCCESS,
    payload: result
  });
};

export const updatePagination = (
  currentPagination: number
) => {
  return {
    type: ACTION_NAMES.UPDATE_PAGINATION,
    payload: currentPagination
  };
};

export const createPharmacy = async (
  dispatch: DispatchType,
  pharmacy: PharmacyObject,
  userId: number
) => {
  await createPharmacyApi(dispatch, pharmacy, userId);
  loadPharmacies(dispatch);
};

export const updatePharmacy = async (
  dispatch: DispatchType,
  updatedPharmacyObject: PharmacyObject,
  updationComment: string,
  userId: number
) => {
  await updatePharmacyApi(
    dispatch,
    updatedPharmacyObject,
    updationComment,
    userId
  );
  loadPharmacies(dispatch);
};

export const updateFilters = (filter: FilterProperties) => {
  return {
    type: ACTION_NAMES.UPDATE_FILTERS,
    payload: filter
  };
};
