import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ExpandRequestsProps,
  RequestReviewProps,
  ServiceObject,
  ServiceQuestionObject
} from '../../helpers/interfaces';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { SERVICE_QUESTION_TYPES } from '../../helpers/constant';
import { getProviderLevelFromType } from '../../helpers/utils';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const ExpandRequests: React.FC<ExpandRequestsProps> = (
  props: ExpandRequestsProps
): JSX.Element => {
  const servicesState = useSelector(
    (state: RootStateType) => state.services
  );

  const [selectedServcie, setSelectedService] =
    useState<ServiceObject | null>(null);

  const getSupplementalQuestion = (
    serviceQuestions: ServiceQuestionObject[],
    questionId: number
  ): ServiceQuestionObject => {
    return serviceQuestions.filter(
      (question) =>
        question.serviceQuestionId === questionId
    )[0];
  };

  const getProviderQuestion = (
    serviceQuestions: ServiceQuestionObject[],
    serviceId: number
  ): ServiceQuestionObject[] => {
    const result = serviceQuestions.filter(
      (question) =>
        question.serviceQuestionType !==
          SERVICE_QUESTION_TYPES.SUPPLEMENTAL &&
        question.serviceId === serviceId
    );
    result.sort(
      (q1, q2) =>
        Number(q1.serviceQuestionType.split(' ')[0][-1]) -
        Number(q2.serviceQuestionType.split(' ')[0][-1])
    );
    return result;
  };

  useEffect(() => {
    setSelectedService(
      servicesState.servicesList.filter(
        (service) =>
          service.serviceId ===
          props.serviceRequest.serviceId
      )[0] || null
    );
  }, [props.serviceRequest]);

  const RequestReview: React.FC<RequestReviewProps> = (
    props: RequestReviewProps
  ): JSX.Element => {
    return (
      <>
        {selectedServcie && (
          <Grid
            style={{
              padding: 16,
              margin: 16,
              border: '2px solid gray'
            }}
            item
            xs={5.5}
            mr={2}
            sx={{
              backgroundColor:
                props.reviewMode === 'OLD'
                  ? '#FF5733'
                  : '#33FF57'
            }}>
            {[
              SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
              SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
            ].includes(
              props.serviceRequest.questionType
            ) ? (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  align="center"
                  mb={2}>
                  {props.reviewMode === 'OLD'
                    ? 'Existing Information'
                    : 'Change Requested'}
                </Typography>
                <Typography
                  component="h1"
                  variant="h5"
                  fontSize={20}>
                  {
                    getSupplementalQuestion(
                      selectedServcie.serviceQuestions,
                      props.serviceRequest.serviceQuestionId
                    ).serviceQuestion
                  }
                </Typography>
                {props.serviceRequest.questionType ===
                SERVICE_QUESTION_TYPES.SUPPLEMENTAL ? (
                  <FormControl>
                    <RadioGroup
                      row={true}
                      value={
                        props.reviewMode === 'OLD'
                          ? props.serviceRequest.oldResponse
                          : props.serviceRequest.newResponse
                      }
                      name="radio-buttons-group">
                      <FormControlLabel
                        key="yes"
                        value="Yes"
                        control={<Radio disabled={true} />}
                        label={
                          <Typography
                            component="h1"
                            variant="h5"
                            fontSize={20}>
                            Yes
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        key="no"
                        value="No"
                        control={<Radio disabled={true} />}
                        label={
                          <Typography
                            component="h1"
                            variant="h5"
                            fontSize={20}>
                            No
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                ) : (
                  <TextareaAutosize
                    required
                    readOnly
                    style={{
                      width: '100%',
                      fontFamily: 'inherit',
                      fontSize: 17,
                      backgroundColor: 'inherit'
                    }}
                    value={
                      props.reviewMode === 'OLD'
                        ? props.serviceRequest.oldResponse
                        : props.serviceRequest.newResponse
                    }
                  />
                )}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  fontSize={20}>
                  Select the most suitable option from the
                  below list:
                </Typography>
                <FormControl>
                  <RadioGroup
                    value={
                      props.reviewMode === 'OLD'
                        ? props.serviceRequest.oldResponse
                        : props.serviceRequest.newResponse
                    }
                    name="radio-buttons-group">
                    {getProviderQuestion(
                      selectedServcie.serviceQuestions,
                      props.serviceRequest.serviceId
                    ).map((question) => {
                      return (
                        <FormControlLabel
                          key={question.serviceQuestionId}
                          value={getProviderLevelFromType(
                            question.serviceQuestionType
                          )}
                          control={
                            <Radio
                              disabled={true}
                              sx={{ mb: 'auto', mt: -1 }}
                            />
                          }
                          label={
                            <Typography
                              component="h1"
                              variant="h5"
                              fontSize={20}>
                              {question.serviceQuestion}
                            </Typography>
                          }
                        />
                      );
                    })}
                    <FormControlLabel
                      value="None"
                      key="None"
                      control={<Radio disabled={true} />}
                      label={
                        <Typography
                          component="h1"
                          variant="h5"
                          fontSize={20}>
                          None of these
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      {selectedServcie && (
        <Grid
          container
          style={{
            border: '1px solid black',
            backgroundColor: 'lightgray'
          }}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <Typography
                component="h1"
                variant="h5"
                fontWeight="bold"
                fontSize={20}
                mt={2}>
                <i>Service:</i>{' '}
                {props.serviceRequest.serviceName}
              </Typography>
              <Typography
                component="h1"
                variant="h5"
                fontSize={20}
                mt={1}
                mb={2}>
                <i>Service Definition:</i>{' '}
                {selectedServcie.serviceDefinition}
              </Typography>
              {selectedServcie.serviceReferences.length >
                0 && (
                <>
                  <Typography
                    component="h1"
                    variant="h5"
                    fontWeight="bold"
                    fontSize={20}>
                    References
                  </Typography>
                  <ul>
                    {selectedServcie.serviceReferences.map(
                      (ref) => {
                        return (
                          <li key={ref.serviceReferenceId}>
                            <a
                              target="_blank"
                              href={
                                ref.serviceReferenceLink
                              }
                              style={{
                                textDecoration: 'none'
                              }}
                              rel="noreferrer">
                              <Typography
                                component="h1"
                                variant="h3"
                                fontSize={20}>
                                {ref.serviceReferenceName}
                              </Typography>
                            </a>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </>
              )}
              <Typography
                component="h1"
                variant="h5"
                fontSize={20}
                mb={1}
                fontStyle="italic">
                This request is for a change in the
                {props.serviceRequest.questionType ===
                SERVICE_QUESTION_TYPES.SUPPLEMENTAL
                  ? ' response to the below supplemental question.'
                  : ' provider level status.'}
              </Typography>
            </Box>
          </Grid>
          <RequestReview
            serviceRequest={props.serviceRequest}
            reviewMode="OLD"
          />
          <DoubleArrowIcon
            fontSize="large"
            sx={{ mt: 9 }}
          />
          <RequestReview
            serviceRequest={props.serviceRequest}
            reviewMode="NEW"
          />
        </Grid>
      )}
    </>
  );
};

export default ExpandRequests;
