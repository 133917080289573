import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Copyright = (): JSX.Element => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: 4, mb: 4 }}>
      {'Copyright © '}
      <Link
        color="inherit"
        to="https://www.ncpharmacists.org/"
        target="_blank">
        North Carolina Association of Pharmacists
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default Copyright;
