import { Grid, TextField, Typography } from '@mui/material';
import { PharmacyReviewProps } from '../../helpers/interfaces';
import PharmacyBasicDetails from './PharmacyBasicDetails';
import PharmacyAddress from './PharmacyAddress';
import PharmacyCredentialDetails from './PharmacyCredentialDetails';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import { PHARMACY_OPERATION_MODE } from '../../helpers/constant';
import { useEffect } from 'react';

const PharmacyReview: React.FC<PharmacyReviewProps> = (
  props: PharmacyReviewProps
): JSX.Element => {
  useEffect(() => {
    if (
      props.operationType === PHARMACY_OPERATION_MODE.NEW
    ) {
      props.setWarningReview(false);
    } else {
      props.setWarningReview(props.updateComment === '');
    }
  }, [props.operationType, props.updateComment]);

  return (
    <>
      <Grid container spacing={2} mt={1}>
        {props.operationType ===
          PHARMACY_OPERATION_MODE.UPDATE && (
          <>
            <Grid item xs={12}>
              <Typography
                fontWeight="bold"
                align="left"
                variant="h5">
                {`Tell us what you've updated:`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="update-comment"
                required
                fullWidth
                label="Comment for your update..."
                value={props.updateComment}
                sx={{ marginBottom: '2%' }}
                onChange={(event) => {
                  props.setUpdateComment(
                    event.target.value
                  );
                }}
                autoFocus
              />
              <WarningComponentForIncorrectField
                message={
                  props.updateComment.trim() === ''
                    ? 'Requierd field'
                    : ''
                }
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography
            fontWeight="bold"
            align="left"
            variant="h5">
            Details:
          </Typography>
        </Grid>
        <PharmacyBasicDetails
          fax={props.pharmacy.fax}
          operationHours={props.pharmacy.operationHours}
          permitNo={props.pharmacy.permitNo}
          pharmacyName={props.pharmacy.pharmacyName}
          pharmacyType={props.pharmacy.pharmacyType}
          phone={props.pharmacy.phone}
          setWarning={props.setWarningBasic}
          updatePharmacyObject={props.updatePharmacyObject}
          viewMode="REVIEW"
          website={props.pharmacy.website}
        />
        <Grid item xs={12}>
          <Typography
            fontWeight="bold"
            align="left"
            variant="h5">
            Address:
          </Typography>
        </Grid>
        <PharmacyAddress
          locationAddress1={props.pharmacy.locationAddress1}
          locationAddress2={props.pharmacy.locationAddress2}
          locationCity={props.pharmacy.locationCity}
          locationState={props.pharmacy.locationState}
          locationZip={props.pharmacy.locationZip}
          locationCounty={props.pharmacy.locationCounty}
          mailingAddress1={props.pharmacy.mailingAddress1}
          mailingAddress2={props.pharmacy.mailingAddress2}
          mailingCity={props.pharmacy.mailingCity}
          mailingState={props.pharmacy.mailingState}
          mailingZip={props.pharmacy.mailingZip}
          mailingCounty={props.pharmacy.mailingCounty}
          updatePharmacyObject={props.updatePharmacyObject}
          setWarning={props.setWarningAddress}
          sameAddress={props.sameAddress}
          setSameAddress={props.setSameAddress}
          viewMode="REVIEW"
        />
        <PharmacyCredentialDetails
          facilityEmail={props.pharmacy.facilityEmail}
          pmpicName={props.pharmacy.pmpicName}
          pmpicEmail={props.pharmacy.pmpicEmail}
          loggedInUsername={props.loggedInUsername}
          loggedInEmail={props.loggedInEmail}
          setWarning={props.setWarningCredentails}
          updatePharmacyObject={props.updatePharmacyObject}
          operationType={props.operationType}
          viewMode="REVIEW"
        />
      </Grid>
    </>
  );
};

export default PharmacyReview;
