import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  PharmacyObject,
  ServicesChipsProps
} from '../../helpers/interfaces';
import { SERVICE_QUESTION_TYPES } from '../../helpers/constant';
import { filterPharamciesByFilter } from '../../helpers/utils';

const ServicesChips: React.FC<ServicesChipsProps> = (
  props: ServicesChipsProps
): JSX.Element => {
  const pharmaciesState = useSelector(
    (state: RootStateType) => state.pharmacies
  );

  const servicesState = useSelector(
    (state: RootStateType) => state.services
  );

  const mappingsState = useSelector(
    (state: RootStateType) =>
      state.pharmaciesServicesMapping
  );

  const [selectedServices, setSelectedServices] = useState<
    number[]
  >([] as number[]);

  useEffect(() => {
    if (selectedServices.includes(-1)) {
      const validPharmacies =
        mappingsState.pharmaciesAndServicesList
          .filter(
            (m) =>
              m &&
              m.questionType ===
                SERVICE_QUESTION_TYPES.PROVIDER &&
              m.response !== 'None'
          )
          .map((mp) => mp?.pharmacyRecordId) || [];
      props.setPharmacies(
        (
          pharmaciesState.pharmaciesList ||
          ([] as PharmacyObject[])
        ).filter(
          (p) => !validPharmacies.includes(p.recordId)
        )
      );
    } else if (
      selectedServices.length === 0 ||
      selectedServices.length ===
        servicesState.servicesList.length
    ) {
      props.setPharmacies(
        filterPharamciesByFilter(
          pharmaciesState.pharmaciesList ||
            ([] as PharmacyObject[]),
          mappingsState.pharmaciesAndServicesList,
          {
            pharmacyName: '',
            city: '',
            county: '',
            zipcode: 0,
            zipcode_radius: 0,
            servicesOffered: [],
            serviceLevelsOffered: {}
          }
        )
      );
    } else {
      props.setPharmacies(
        filterPharamciesByFilter(
          pharmaciesState.pharmaciesList ||
            ([] as PharmacyObject[]),
          mappingsState.pharmaciesAndServicesList,
          {
            pharmacyName: '',
            city: '',
            county: '',
            zipcode: 0,
            zipcode_radius: 0,
            servicesOffered: selectedServices,
            serviceLevelsOffered: {}
          }
        )
      );
    }
  }, [selectedServices]);

  return (
    <div>
      {servicesState &&
        servicesState.servicesList.map((serv) => {
          return (
            <Chip
              sx={{ marginLeft: '1vh' }}
              key={serv.serviceId}
              label={serv.serviceName}
              clickable
              color={
                selectedServices.includes(serv.serviceId)
                  ? 'primary'
                  : 'default'
              }
              onClick={() => {
                if (selectedServices.includes(-1)) {
                  setSelectedServices([serv.serviceId]);
                } else if (
                  selectedServices.includes(serv.serviceId)
                ) {
                  setSelectedServices(
                    [...selectedServices].filter(
                      (s) => s !== serv.serviceId
                    )
                  );
                } else {
                  setSelectedServices([
                    ...selectedServices,
                    serv.serviceId
                  ]);
                }
              }}
            />
          );
        })}
      <Chip
        sx={{ marginLeft: '1vh' }}
        key={'noservice'}
        label={'No Service'}
        clickable
        color={
          selectedServices.includes(-1)
            ? 'primary'
            : 'default'
        }
        onClick={() => {
          if (selectedServices.includes(-1)) {
            setSelectedServices([]);
          } else {
            setSelectedServices([-1]);
          }
        }}
      />
    </div>
  );
};

export default ServicesChips;
