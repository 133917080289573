import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material';
import Copyright from '../../sharedComponents/Copyright/Copyright';
import { Link, useNavigate } from 'react-router-dom';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useEffect, useState } from 'react';
import {
  validateEmail,
  validatePassword
} from '../../helpers/utils';
import { getUserProfileApi } from '../../helpers/apiCalls';
import { loadUserProfile } from '../../redux/user/userActions';
import { useDispatch } from 'react-redux';
import { SIGN_IN_UP_MODES } from '../../helpers/constant';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';
import {
  Visibility,
  VisibilityOff
} from '@mui/icons-material';

const UserSignIn: React.FC = (): JSX.Element => {
  const [permitNo, setPermitNo] = useState<string>('');
  const [permitNoMessage, setPermitNoMessage] =
    useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailMessage, setEmailMessage] =
    useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordMessage, setPasswordMessage] =
    useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>('');

  const [passwordShow, setPasswordShow] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setEmailMessage(
      validateEmail(email, SIGN_IN_UP_MODES.USER_SIGN_IN)
    );
  }, [email]);

  useEffect(() => {
    setPasswordMessage(
      validatePassword(
        password,
        SIGN_IN_UP_MODES.USER_SIGN_IN
      )
    );
  }, [password]);

  useEffect(() => {
    if (isAdmin) {
      setPermitNoMessage('');
      setPermitNo('');
    } else {
      setPermitNoMessage(
        permitNo === ''
          ? 'Permit Number cannot be empty'
          : ''
      );
    }
  }, [permitNo, isAdmin]);

  const defaultTheme = createTheme();

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setLoading(true);
    const result = await getUserProfileApi(
      isAdmin ? 'ADMIN' : permitNo,
      email,
      password,
      isAdmin
    );
    if (result) {
      setLoginError('');
      loadUserProfile(dispatch, result);
      if (isAdmin) {
        navigate('/');
        // run other data loads for users, services, mappings and all
      } else if (result.myPharmacy.length === 0) {
        navigate('/');
      } else {
        navigate(
          `/pharmacy/${result.myPharmacy[0].recordId}`
        );
      }
      dispatch(
        openSnackbar(
          `Welcome${
            result.userProfile
              ? ` ${result.userProfile?.name}`
              : ``
          }!`,
          'success'
        )
      );
    } else {
      setLoginError(
        `Provided credentials do not match.${
          isAdmin
            ? ''
            : 'You may verify the Permit Number and Facility Email with the Board.'
        }`
      );
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Avatar
              sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              {!isAdmin && (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Permit Number"
                    name="permitNo"
                    value={permitNo}
                    onChange={(event) => {
                      setPermitNo(event.target.value);
                    }}
                    autoComplete="permitNo"
                    autoFocus
                  />
                  <WarningComponentForIncorrectField
                    message={permitNoMessage}
                  />
                </>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                label={
                  isAdmin
                    ? 'Email Address'
                    : 'Facility Email Address'
                }
                name={isAdmin ? 'email' : 'facilityEmail'}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value.trim());
                }}
                autoComplete={
                  isAdmin ? 'email' : 'facilityEmail'
                }
                autoFocus
              />
              <WarningComponentForIncorrectField
                message={emailMessage}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={passwordShow ? 'text' : 'password'}
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setPasswordShow(!passwordShow);
                        }}
                        edge="end">
                        {passwordShow ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <WarningComponentForIncorrectField
                message={passwordMessage}
              />
              <Grid container>
                <IconButton
                  onClick={() => {
                    setIsAdmin(!isAdmin);
                  }}>
                  {isAdmin ? (
                    <CheckBoxIcon
                      fontSize="medium"
                      color="primary"
                    />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      fontSize="medium"
                      color="primary"
                    />
                  )}
                </IconButton>
                <Typography mt={1}>Admin Login?</Typography>
              </Grid>

              {loginError !== '' && (
                <Typography
                  variant="body2"
                  style={{ color: 'red' }}>
                  {loginError}
                </Typography>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={
                  loading ||
                  permitNoMessage !== '' ||
                  emailMessage !== '' ||
                  passwordMessage !== ''
                }
                onClick={(event) => {
                  handleSubmit(event);
                }}>
                {loading ? <CircularProgress /> : 'Sign In'}
              </Button>
              <Grid container>
                <Grid item xs={12} mt={0.5}>
                  <Link to="/forgotpassword">
                    <Typography
                      variant="body2"
                      align="left">
                      {`Forgot Password`}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright />
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default UserSignIn;
