import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Modal,
  ThemeProvider,
  Typography,
  createTheme,
  CircularProgress
} from '@mui/material';
import MiscellaneousServicesTwoToneIcon from '@mui/icons-material/MiscellaneousServicesTwoTone';
import Copyright from '../../sharedComponents/Copyright/Copyright';
import {
  ConfigurePharmacyServiceModalProps,
  PharmacyService,
  ServiceQuestionObject,
  ServiceRequestObject
} from '../../helpers/interfaces';
import { useEffect, useState } from 'react';
import {
  getServiceQuestionsByType,
  hasSupplementalQuestions
} from '../../helpers/utils';
import ProviderQuestions from './ProviderQuestions';
import { SERVICE_QUESTION_TYPES } from '../../helpers/constant';
import SupplementalQuestions from './SupplementalQuestions';
import { createServiceRequests } from '../../redux/serviceRequests/serviceRequestsActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';

const ConfigurePharmacyServiceModal: React.FC<
  ConfigurePharmacyServiceModalProps
> = (
  props: ConfigurePharmacyServiceModalProps
): JSX.Element => {
  const pharmaciesServicesMappingState = useSelector(
    (state: RootStateType) =>
      state.pharmaciesServicesMapping
  );

  const getSelectedProviderLevel = (): string => {
    const filteredValues =
      pharmaciesServicesMappingState.pharmaciesAndServicesList.filter(
        (mapping) =>
          mapping?.pharmacyRecordId ===
            props.pharmacyRecordId &&
          mapping.serviceId === props.service?.serviceId &&
          mapping.questionType ===
            SERVICE_QUESTION_TYPES.PROVIDER
      );
    return filteredValues.length > 0
      ? filteredValues[0]?.response || 'None'
      : 'None';
  };

  const [loading, setLoading] = useState<boolean>(false);

  const getSupplementalResponses =
    (): PharmacyService[] => {
      const copiedValues = JSON.parse(
        JSON.stringify(
          pharmaciesServicesMappingState.pharmaciesAndServicesList
        )
      ) as PharmacyService[];
      const filteredValues = copiedValues.filter(
        (mapping) =>
          mapping &&
          mapping.pharmacyRecordId ===
            props.pharmacyRecordId &&
          mapping.serviceId === props.service?.serviceId &&
          [
            SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
            SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
          ].includes(mapping.questionType)
      );
      return filteredValues;
    };

  const getProviderQuestionId = (): number => {
    return (
      props.service?.serviceQuestions.filter(
        (q) =>
          ![
            SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
            SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
          ].includes(q.serviceQuestionType)
      )[0].serviceQuestionId || -1
    );
  };

  const defaultTheme = createTheme();

  const [selectedProviderLevel, setSelectedProviderLevel] =
    useState<string>(getSelectedProviderLevel());
  const [supplementalResponses, setSupplementalResponses] =
    useState<PharmacyService[]>(getSupplementalResponses());

  useEffect(() => {
    setSelectedProviderLevel(getSelectedProviderLevel());
    setSupplementalResponses(getSupplementalResponses());
  }, [
    props.isModalOpen,
    pharmaciesServicesMappingState.pharmaciesAndServicesList
  ]);

  const dispatch = useDispatch();

  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  const providerLevelChanged = (
    providerObject: PharmacyService | undefined
  ): boolean => {
    return (
      ((providerObject === null ||
        providerObject === undefined) &&
        selectedProviderLevel !== 'None') ||
      (providerObject !== null &&
        providerObject !== undefined &&
        providerObject.response !== selectedProviderLevel)
    );
  };

  const supplementalResponseChanged = (
    supplementalResponse: PharmacyService,
    existingResponse: PharmacyService | undefined
  ): boolean => {
    return (
      ((existingResponse === null ||
        existingResponse === undefined) &&
        supplementalResponse !== null &&
        supplementalResponse.response !== 'No') ||
      (existingResponse !== null &&
        existingResponse !== undefined &&
        supplementalResponse !== null &&
        existingResponse.response !==
          supplementalResponse.response)
    );
  };

  const enableSubmit = () => {
    const providerObjectFind =
      pharmaciesServicesMappingState.pharmaciesAndServicesList.find(
        (mapping) =>
          mapping?.pharmacyRecordId ===
            props.pharmacyRecordId &&
          mapping.serviceId === props.service?.serviceId &&
          mapping?.questionType ===
            SERVICE_QUESTION_TYPES.PROVIDER
      );

    let changedSupplemental = false;
    supplementalResponses.map((suppResp) => {
      const suppObject =
        pharmaciesServicesMappingState.pharmaciesAndServicesList.find(
          (mapping) =>
            mapping?.pharmacyRecordId ===
              props.pharmacyRecordId &&
            mapping.serviceId ===
              props.service?.serviceId &&
            mapping?.serviceQuestionId ===
              suppResp?.serviceQuestionId
        );

      if (
        props.service &&
        suppResp &&
        supplementalResponseChanged(suppResp, suppObject)
      ) {
        changedSupplemental = true;
      }
    });
    return (
      providerLevelChanged(providerObjectFind) ||
      changedSupplemental
    );
  };

  const submitRequest = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setLoading(true);
    if (userState.userProfile && props.service) {
      const serviceRequests = [] as ServiceRequestObject[];
      const questions = [] as string[];
      const providerObject =
        pharmaciesServicesMappingState.pharmaciesAndServicesList.find(
          (mapping) =>
            mapping?.pharmacyRecordId ===
              props.pharmacyRecordId &&
            mapping.serviceId ===
              props.service?.serviceId &&
            mapping?.questionType ===
              SERVICE_QUESTION_TYPES.PROVIDER
        );
      if (providerLevelChanged(providerObject)) {
        serviceRequests.push({
          serviceRequestId: -1,
          pharmacyRecordId: props.pharmacyRecordId,
          questionType: SERVICE_QUESTION_TYPES.PROVIDER,
          serviceId: props.service.serviceId,
          serviceQuestionId: getProviderQuestionId(),
          oldResponse: providerObject
            ? providerObject.response
            : 'None',
          newResponse: selectedProviderLevel,
          requestStatus: 'PENDING',
          requestedOn: new Date(),
          closedOn: null
        } as ServiceRequestObject);
        questions.push('');
      }

      supplementalResponses.map((suppResp) => {
        const suppObject =
          pharmaciesServicesMappingState.pharmaciesAndServicesList.find(
            (mapping) =>
              mapping?.pharmacyRecordId ===
                props.pharmacyRecordId &&
              mapping.serviceId ===
                props.service?.serviceId &&
              mapping?.serviceQuestionId ===
                suppResp?.serviceQuestionId
          );
        if (
          props.service &&
          suppResp &&
          supplementalResponseChanged(suppResp, suppObject)
        ) {
          serviceRequests.push({
            serviceRequestId: -1,
            pharmacyRecordId: props.pharmacyRecordId,
            questionType: suppResp.questionType,
            serviceId: props.service.serviceId,
            serviceQuestionId: suppResp.serviceQuestionId,
            oldResponse: suppObject
              ? suppObject.response
              : suppResp.questionType ===
                SERVICE_QUESTION_TYPES.SUPPLEMENTAL
              ? 'No'
              : '',
            newResponse: suppResp.response,
            requestStatus: 'PENDING',
            requestedOn: new Date(),
            closedOn: null
          } as ServiceRequestObject);
          const selectedQuestion =
            props.service.serviceQuestions.find(
              (q) =>
                q.serviceQuestionId ===
                suppResp.serviceQuestionId
            );
          questions.push(
            selectedQuestion
              ? selectedQuestion.serviceQuestion
              : ''
          );
        }
      });

      await createServiceRequests(
        dispatch,
        serviceRequests,
        userState.userProfile.userId,
        userState.userProfile.role,
        questions,
        props.service.serviceName
      );

      dispatch(
        openSnackbar(`Service request created!`, 'success')
      );
    }
    setLoading(false);
    props.handleClose(event);
  };

  return (
    <Modal
      open={props.isModalOpen}
      sx={{ overflow: 'scroll' }}
      onClose={(
        event: React.MouseEvent<HTMLButtonElement>
      ) => props.handleClose(event)}>
      <ThemeProvider theme={defaultTheme}>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            background: '#fff',
            minWidth: '60%',
            paddingBottom: '1%'
          }}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Avatar sx={{ m: 1, bgcolor: '#4169e1' }}>
              <MiscellaneousServicesTwoToneIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              fontWeight="bold">
              Configure {props.service?.serviceName} Service
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={() => {}}
              sx={{ mt: 2 }}
              minWidth="80%">
              {props.service && (
                <>
                  <Typography
                    component="h1"
                    variant="h5"
                    fontWeight="bold"
                    fontSize={20}
                    mt={2}>
                    Service Definition
                  </Typography>
                  <Typography
                    component="h1"
                    variant="h5"
                    fontSize={20}
                    mt={1}
                    mb={2}>
                    {props.service?.serviceDefinition}
                  </Typography>
                </>
              )}
              {props.service &&
                props.service.serviceReferences.length >
                  0 && (
                  <>
                    <Typography
                      component="h1"
                      variant="h5"
                      fontWeight="bold"
                      fontSize={20}
                      mt={2}>
                      Resources
                    </Typography>
                    <ul>
                      {props.service.serviceReferences.map(
                        (ref) => {
                          return (
                            <li
                              key={ref.serviceReferenceId}>
                              <a
                                target="_blank"
                                href={
                                  ref.serviceReferenceLink
                                }
                                style={{
                                  textDecoration: 'none'
                                }}
                                rel="noreferrer">
                                <Typography
                                  component="h1"
                                  variant="h5"
                                  fontSize={20}>
                                  {ref.serviceReferenceName}
                                </Typography>
                              </a>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </>
                )}

              {props.service && (
                <>
                  <ProviderQuestions
                    pharmacyRecordId={
                      props.pharmacyRecordId
                    }
                    serviceId={props.service.serviceId}
                    providerQuestions={
                      props.service
                        ? getServiceQuestionsByType(
                            props.service?.serviceQuestions,
                            SERVICE_QUESTION_TYPES.LEVEL1_PROVIDER
                          )
                        : ([] as ServiceQuestionObject[])
                    }
                    selectedProviderLevel={
                      selectedProviderLevel
                    }
                    setSelectedProviderLevel={
                      setSelectedProviderLevel
                    }
                  />
                  {hasSupplementalQuestions(
                    props.service
                  ) && (
                    <SupplementalQuestions
                      pharmacyRecordId={
                        props.pharmacyRecordId
                      }
                      serviceId={props.service.serviceId}
                      supplementalQuestions={
                        props.service
                          ? getServiceQuestionsByType(
                              props.service
                                ?.serviceQuestions,
                              SERVICE_QUESTION_TYPES.SUPPLEMENTAL
                            )
                          : ([] as ServiceQuestionObject[])
                      }
                      supplementalResponses={
                        supplementalResponses
                      }
                      setSupplementalResponses={
                        setSupplementalResponses
                      }
                    />
                  )}{' '}
                </>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="error"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={(event) => {
                      props.handleClose(event);
                    }}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={(event) => {
                      submitRequest(event);
                    }}
                    disabled={loading || !enableSubmit()}>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      'Submit Request'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright />
        </Container>
      </ThemeProvider>
    </Modal>
  );
};

export default ConfigurePharmacyServiceModal;
