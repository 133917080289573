import { DATA_LOAD_STATUS } from '../../helpers/constant';
import { ServiceRequestObject } from '../../helpers/interfaces';
import { ACTION_NAMES } from '../reduxActions';

const initialState = {
  status: DATA_LOAD_STATUS.STATUS_LOADING,
  requests: [] as ServiceRequestObject[]
};

export const serviceRequestsReducer = (
  state = initialState,
  action: {
    type: string;
    payload: ServiceRequestObject[] | string;
  }
) => {
  switch (action.type) {
    case ACTION_NAMES.LOAD_SERVICE_REQUESTS:
      return {
        ...state,
        status: DATA_LOAD_STATUS.STATUS_LOADING,
        requests: [] as ServiceRequestObject[]
      };
    case ACTION_NAMES.LOAD_SERVICE_REQUESTS_SUCCESS:
      return {
        ...state,
        status: DATA_LOAD_STATUS.STATUS_DONE,
        requests: action.payload as ServiceRequestObject[]
      };
    default:
      return state;
  }
};
