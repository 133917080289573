import { ACTION_NAMES } from '../reduxActions';

export const closeSnackbar = () => {
  return {
    type: ACTION_NAMES.CLOSE_SNACKBAR
  };
};

export const openSnackbar = (
  message: string,
  severity: string
) => {
  return {
    type: ACTION_NAMES.OPEN_SNACKBAR,
    payload: { message: message, severity: severity }
  };
};
