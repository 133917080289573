import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  IconButton
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import RequestConfirmationModal from './RequestConfirmationModal';
import { ServiceRequestObject } from '../../helpers/interfaces';
import ExpandRequests from './ExpandRequest';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getDateString } from '../../helpers/utils';
import SortBy from '../../sharedComponents/SortBy/SortBy';

const ServiceRequests: React.FC = (): JSX.Element => {
  const serviceRequestsState = useSelector(
    (state: RootStateType) => state.serviceRequests
  );

  const [openConfirmation, setOpenConfirmation] =
    useState<boolean>(false);

  const [selectedRequest, setSelectedRequest] =
    useState<ServiceRequestObject | null>(null);

  const [expandedRequest, setExpandedRequest] = useState<
    number | null
  >(null);

  const [filteredRequests, setFilteredRequests] = useState<
    ServiceRequestObject[]
  >(serviceRequestsState.requests);

  useEffect(() => {
    setFilteredRequests(serviceRequestsState.requests);
  }, [serviceRequestsState.requests]);

  const closeModal = () => {
    setOpenConfirmation(false);
    setSelectedRequest(null);
  };

  const [isApproveRequest, setIsApproveRequest] =
    useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDataSort = (data: any) => {
    setFilteredRequests(data as ServiceRequestObject[]);
  };

  return (
    <>
      <Paper>
        <h2>Pending Service Requests</h2>
        {serviceRequestsState.requests &&
        serviceRequestsState.requests.length > 0 ? (
          <>
            <SortBy
              data={filteredRequests}
              setData={handleDataSort}
              sortingOptions={[
                {
                  filterName: 'Request Date',
                  attributeName: 'requestedOn',
                  type: 'date'
                },
                {
                  filterName: 'Pharmacy Name',
                  attributeName: 'pharmacyName',
                  type: 'string'
                },
                {
                  filterName: 'Service Name',
                  attributeName: 'serviceName',
                  type: 'string'
                },
                {
                  filterName: 'Question Type',
                  attributeName: 'questionType',
                  type: 'string'
                }
              ]}
              defaultSorting={{
                filterName: 'Request Date',
                attributeName: 'requestedOn',
                type: 'date'
              }}
            />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      #
                    </TableCell>
                    <TableCell />
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Pharmacy Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Service Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Requested On
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Questions Type
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Old Value
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      New Value
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold'
                      }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRequests.map(
                    (request, index) => (
                      <>
                        <TableRow key={index}>
                          <TableCell
                            style={{ width: '5px' }}>
                            {index + 1}
                          </TableCell>
                          <TableCell
                            style={{ width: '5px' }}
                            onClick={() => {
                              if (
                                expandedRequest &&
                                expandedRequest ===
                                  request.serviceRequestId
                              ) {
                                setExpandedRequest(null);
                              } else {
                                setExpandedRequest(
                                  request.serviceRequestId
                                );
                              }
                            }}>
                            <IconButton>
                              {expandedRequest &&
                              expandedRequest ===
                                request.serviceRequestId ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {request.pharmacyName}
                          </TableCell>
                          <TableCell>
                            {request.serviceName}
                          </TableCell>
                          <TableCell>
                            {getDateString(
                              request.requestedOn
                            )}
                          </TableCell>
                          <TableCell>
                            {request.questionType}
                          </TableCell>
                          <TableCell>
                            {request.oldResponse}
                          </TableCell>
                          <TableCell>
                            {request.newResponse}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() => {
                                setSelectedRequest(request);
                                setIsApproveRequest(true);
                                setOpenConfirmation(true);
                              }}>
                              Approve
                            </Button>
                            <Button
                              sx={{ marginLeft: '2%' }}
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setSelectedRequest(request);
                                setIsApproveRequest(false);
                                setOpenConfirmation(true);
                              }}>
                              Reject
                            </Button>
                          </TableCell>
                        </TableRow>
                        {expandedRequest ===
                          request.serviceRequestId && (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <ExpandRequests
                                serviceRequest={request}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography fontSize={20} fontStyle="italic">
            No pending requests at this time.
          </Typography>
        )}
      </Paper>
      {selectedRequest && (
        <RequestConfirmationModal
          openModal={openConfirmation}
          closeModal={closeModal}
          serviceRequest={selectedRequest}
          isApproveRequest={isApproveRequest}
        />
      )}
    </>
  );
};

export default ServiceRequests;
