import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useEffect, useState } from 'react';
import {
  validateConfirmPassword,
  validateEmail,
  validatePassword,
  validateUsername
} from '../../helpers/utils';
import { createUserApi } from '../../helpers/apiCalls';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Copyright from '../../sharedComponents/Copyright/Copyright';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';

const UserSignUp: React.FC = (): JSX.Element => {
  const [permitNo, setPermitNo] = useState<string>('');
  const [permitNoMessage, setPermitNoMessage] =
    useState<string>('');
  const [name, setName] = useState<string>('');
  const [nameMessage, setNameMessage] =
    useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailMessage, setEmailMessage] =
    useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordMessage, setPasswordMessage] =
    useState<string>('');
  const [confirmPassword, setConfirmPassword] =
    useState<string>('');
  const [
    confirmPasswordMessage,
    setConfirmPasswordMessage
  ] = useState<string>('');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const defaultTheme = createTheme();

  useEffect(() => {
    setPermitNoMessage(
      permitNo === '' ? 'Permit Number cannot be empty' : ''
    );
  }, [permitNo]);

  useEffect(() => {
    setNameMessage(validateUsername(name));
  }, [name]);

  useEffect(() => {
    setEmailMessage(validateEmail(email));
  }, [email]);

  useEffect(() => {
    setPasswordMessage(validatePassword(password));
  }, [password]);

  useEffect(() => {
    setConfirmPasswordMessage(
      validateConfirmPassword(password, confirmPassword)
    );
  }, [confirmPassword]);

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    await createUserApi(
      dispatch,
      name,
      email,
      permitNo,
      password
    );
    navigate('/signin');
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={() => {}}
            sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="permit-no"
                  name="permitNo"
                  required
                  fullWidth
                  label="Permit No"
                  value={permitNo}
                  onChange={(event) => {
                    setPermitNo(event.target.value);
                  }}
                  autoFocus
                />
                <WarningComponentForIncorrectField
                  message={permitNoMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  label="Name"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  autoFocus
                />
                <WarningComponentForIncorrectField
                  message={nameMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Email Address"
                  name="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  autoComplete="email"
                />
                <WarningComponentForIncorrectField
                  message={emailMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  autoComplete="new-password"
                />
                <WarningComponentForIncorrectField
                  message={passwordMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirm-password"
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                  }}
                  autoComplete="confirm-password"
                />
                <WarningComponentForIncorrectField
                  message={confirmPasswordMessage}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={(event) => {
                handleSubmit(event);
              }}
              disabled={
                nameMessage !== '' ||
                emailMessage !== '' ||
                passwordMessage !== ''
              }>
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  to="/signin"
                  style={{ cursor: 'pointer' }}>
                  <Typography variant="body2">
                    Already have an account? Sign in
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
};

export default UserSignUp;
