import { DATA_LOAD_STATUS } from '../../helpers/constant';
import { FeedbackObject } from '../../helpers/interfaces';
import { ACTION_NAMES } from '../reduxActions';

const initialState = {
  status: DATA_LOAD_STATUS.STATUS_LOADING,
  feedbacks: [] as FeedbackObject[]
};

export const feedbacksReducer = (
  state = initialState,
  action: {
    type: string;
    payload: FeedbackObject[] | string;
  }
) => {
  switch (action.type) {
    case ACTION_NAMES.LOAD_FEEDBACKS:
      return {
        ...state,
        status: DATA_LOAD_STATUS.STATUS_LOADING,
        feedbacks: [] as FeedbackObject[]
      };
    case ACTION_NAMES.LOAD_FEEDBACKS_SUCCESS:
      return {
        ...state,
        status: DATA_LOAD_STATUS.STATUS_DONE,
        feedbacks: action.payload as FeedbackObject[]
      };
    default:
      return state;
  }
};
