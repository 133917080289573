import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  TextField,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material';
import Copyright from '../../sharedComponents/Copyright/Copyright';
import { useNavigate } from 'react-router-dom';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useEffect, useState } from 'react';
import { validateEmail } from '../../helpers/utils';
import { forgotPasswordApi } from '../../helpers/apiCalls';
import { useDispatch } from 'react-redux';
import { SIGN_IN_UP_MODES } from '../../helpers/constant';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';

const ForgotPassword: React.FC = (): JSX.Element => {
  const [permitNo, setPermitNo] = useState<string>('');
  const [permitNoMessage, setPermitNoMessage] =
    useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailMessage, setEmailMessage] =
    useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setEmailMessage(
      validateEmail(email, SIGN_IN_UP_MODES.USER_SIGN_IN)
    );
  }, [email]);

  useEffect(() => {
    if (isAdmin) {
      setPermitNoMessage('');
      setPermitNo('');
    } else {
      setPermitNoMessage(
        permitNo === ''
          ? 'Permit Number cannot be empty'
          : ''
      );
    }
  }, [permitNo, isAdmin]);

  const defaultTheme = createTheme();

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setLoading(true);
    try {
      await forgotPasswordApi(
        isAdmin ? '' : permitNo,
        email,
        isAdmin
      );

      setLoginError('');
      navigate('/');
      dispatch(
        openSnackbar(
          `Temporary password mailed successfully`,
          'success'
        )
      );
    } catch (e) {
      navigate('/');
      dispatch(
        openSnackbar(
          `We are facing some problems, please try again later`,
          'error'
        )
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Avatar
              sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              {!isAdmin && (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Permit Number"
                    name="permitNo"
                    value={permitNo}
                    onChange={(event) => {
                      setPermitNo(event.target.value);
                    }}
                    autoComplete="permitNo"
                    autoFocus
                  />
                  <WarningComponentForIncorrectField
                    message={permitNoMessage}
                  />
                </>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                label={
                  isAdmin
                    ? 'Email Address'
                    : 'Facility Email Address'
                }
                name={isAdmin ? 'email' : 'facilityEmail'}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value.trim());
                }}
                autoComplete={
                  isAdmin ? 'email' : 'facilityEmail'
                }
                autoFocus
              />
              <WarningComponentForIncorrectField
                message={emailMessage}
              />

              <Grid container>
                <IconButton
                  onClick={() => {
                    setIsAdmin(!isAdmin);
                  }}>
                  {isAdmin ? (
                    <CheckBoxIcon
                      fontSize="medium"
                      color="primary"
                    />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      fontSize="medium"
                      color="primary"
                    />
                  )}
                </IconButton>
                <Typography mt={1}>Admin Login?</Typography>
              </Grid>

              {loginError !== '' && (
                <Typography
                  variant="body2"
                  style={{ color: 'red' }}>
                  {loginError}
                </Typography>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={
                  loading ||
                  permitNoMessage !== '' ||
                  emailMessage !== ''
                }
                onClick={(event) => {
                  handleSubmit(event);
                }}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  'Send temporary password'
                )}
              </Button>
            </Box>
          </Box>
          <Copyright />
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default ForgotPassword;
