import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingSpinner: React.FC = (): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', margin: '15% 0 20% 45%' }}>
      <CircularProgress size="15rem" />
    </Box>
  );
};

export default LoadingSpinner;
