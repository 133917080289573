import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  Button,
  Checkbox
} from '@mui/material';
import { validateEmail } from '../../helpers/utils';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import { createFeedback } from '../../redux/feedbacks/feedbacksActions';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';
import { useNavigate } from 'react-router-dom';

const GiveFeedback: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState<string>('');
  const [emailMessage, setEmailMessage] =
    useState<string>('');
  const [anonymous, setAnonymous] =
    useState<boolean>(false);
  const [topic, setTopic] = useState<string>('');
  const [topicMessage, setTopicMessage] =
    useState<string>('');
  const [description, setDescription] =
    useState<string>('');
  const [descriptionMessage, setDescriptionMessage] =
    useState<string>('');
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    setEmailMessage(validateEmail(email));
  }, [email]);

  useEffect(() => {
    setTopicMessage(
      topic.trim() === ''
        ? 'Feedback topic cannot be empty'
        : ''
    );
  }, [topic]);

  useEffect(() => {
    setDescriptionMessage(
      description.trim() === ''
        ? 'Please enter a valid feedback'
        : ''
    );
  }, [description]);

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    try {
      event.preventDefault();
      await createFeedback(
        dispatch,
        anonymous ? 'anonymous' : email,
        topic,
        description
      );
      dispatch(
        openSnackbar(
          `Feedback submitted successfully`,
          'success'
        )
      );
      navigate('/');
    } catch (e) {
      console.log('Error: ', e);
      dispatch(
        openSnackbar(
          'Feedback could not be submitted at this time. Please try again later. If the issue persists, then contact the administrators.',
          'error'
        )
      );
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Feedback Form
      </Typography>
      {!anonymous && (
        <>
          <TextField
            fullWidth
            label="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <WarningComponentForIncorrectField
            message={emailMessage}
          />
        </>
      )}
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={anonymous}
              onChange={() => {
                setAnonymous(!anonymous);
              }}
              name="anonymous"
            />
          }
          label="Stay Anonymous"
        />
      </FormControl>
      <TextField
        fullWidth
        label="Feedback Topic"
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
        style={{ marginTop: '20px' }}
        required
      />
      <WarningComponentForIncorrectField
        message={topicMessage}
      />
      <TextField
        fullWidth
        label="Feedback Description"
        multiline
        rows={4}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{ marginTop: '20px' }}
        required
      />
      <WarningComponentForIncorrectField
        message={descriptionMessage}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginTop: '20px' }}
        disabled={
          topicMessage !== '' ||
          descriptionMessage !== '' ||
          (!anonymous && emailMessage !== '')
        }
        onClick={handleSubmit}>
        Submit Feedback
      </Button>
    </Container>
  );
};

export default GiveFeedback;
