import { ACTION_NAMES } from '../reduxActions';
import {
  createFeedbackApi,
  getFeedbacksApi,
  updateFeedbackApi,
  replyFeedbackApi
} from '../../helpers/apiCalls';
import { DispatchType } from '../store';

export const loadFeedbacks = async (
  dispatch: DispatchType
) => {
  dispatch({
    type: ACTION_NAMES.LOAD_FEEDBACKS
  });
  const result = await getFeedbacksApi(dispatch);
  dispatch({
    type: ACTION_NAMES.LOAD_FEEDBACKS_SUCCESS,
    payload: result
  });
};

export const createFeedback = async (
  dispatch: DispatchType,
  email: string,
  topic: string,
  description: string
) => {
  await createFeedbackApi(
    dispatch,
    email,
    topic,
    description
  );
  await loadFeedbacks(dispatch);
};

export const updateFeedback = async (
  dispatch: DispatchType,
  feedbackId: number,
  status: string,
  comment: string,
  assignedTo: string
) => {
  await updateFeedbackApi(
    dispatch,
    feedbackId,
    status,
    comment,
    assignedTo
  );
  await loadFeedbacks(dispatch);
};

export const replyFeedback = async (
  dispatch: DispatchType,
  feedbackId: number,
  comment: string,
  includeDisclaimer: boolean,
  replyToAddress: string
) => {
  await replyFeedbackApi(
    dispatch,
    feedbackId,
    comment,
    includeDisclaimer,
    replyToAddress
  );
  await loadFeedbacks(dispatch);
};
