import {
  Button,
  Container,
  Typography
} from '@mui/material';
import ncapbanner from '../../images/ncapbanner.jpg';
import { useNavigate } from 'react-router-dom';
import HomeNavBar from '../../components/HomeNavBar.tsx/HomeNavBar';
import { useState } from 'react';

const Home: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [view, setView] = useState('Home');
  const [showServiceCatalog, setShowServiceCatalog] =
    useState(false);

  const getViewDetails = () => {
    switch (view) {
      case 'Home':
        return (
          <>
            <Typography
              variant="h4"
              align="left"
              mt={2}
              fontStyle="italic">
              {`Welcome to the North Carolina Pharmacy Locator
          Website!`}{' '}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              mt={2}
              textAlign="justify">
              {`Powered by the North Carolina Association of Pharmacists, consider this your premier destination for navigating our great state's vast landscape of pharmacies. This dynamic tool, crafted with the utmost dedication to serving patients and healthcare providers alike, is designed to be your compass in finding pharmacies, whether independent or part of chains, that offer specialized services tailored to meet your medical needs. Where access to quality care meets convenience, this tool empowers you to make informed decisions for your health and well-being.`}
            </Typography>
            <Container
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '2%'
              }}>
              <Button
                variant="contained"
                style={{ fontSize: 'large' }}
                onClick={() => {
                  navigate('/pharmacies');
                }}>
                <Typography fontWeight="bold">
                  Browse Pharmacies
                </Typography>
              </Button>
            </Container>
          </>
        );
      case 'Patients':
        return (
          <>
            <Typography
              variant="h4"
              align="left"
              mt={2}
              fontStyle="italic">
              {`To Our Patients and Those in Our Community!`}{' '}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              mt={2}
              textAlign="justify">
              {`Pharmacists in North Carolina do more than just fill prescriptions! They play a crucial
role in providing various healthcare services to improve your health and well-being.
Despite significant advancements in the profession, many North Carolinians aren't fully
aware of the range of services pharmacies offer.`}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              mt={2}
              textAlign="justify">
              {`Thanks to recent state laws passed in 2021, pharmacists now have expanded
capabilities. They can provide birth control, glucagon for diabetic emergencies, nicotine
replacement therapy for quitting smoking, naloxone for preventing opioid overdoses,
and HIV prevention medications without needing a doctor's prescription. Additionally,
pharmacists can assist with administering injectable medications, saving both you and
your provider time and hassle.`}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              mt={2}
              textAlign="justify">
              {`Whether you need help managing your medications or other healthcare needs,
pharmacists are committed to working with you and your healthcare team to enhance
the quality of care you receive and support your journey toward better health. To get
started, click 'PHARMACIES'; in the top right of your screen. On the left, enter your
location and what you're looking for. The website will do the rest showing you nearby
pharmacies that provide the services you seek.`}
            </Typography>
          </>
        );
      case 'Providers':
        return (
          <>
            <Typography
              variant="h4"
              align="left"
              mt={2}
              fontStyle="italic">
              {`To Our Partners in Health!`}{' '}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              mt={2}
              textAlign="justify">
              {`Medical Providers, consider the pharmacy locator your go-to tool for finding valuable
pharmacy partners to help you co-manage your patient’s health. Pharmacists play a
large role in enhancing HEDIS measures and contributing to value-based care. Whether
you’re searching for medication therapy management services or other targeted
intervention programs for your patients, pharmacists’ active participation in collaborative
efforts with providers can significantly enhance patients’ outcomes and contribute to the
success of value-based initiatives. To find the right pharmacy for you, click
'PHARMACIES' in the top right. Enter your location and the services you're interested in
on the left, and the website will show nearby pharmacies that offer what you need.`}
            </Typography>
            <Container
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '1%'
              }}>
              <Button
                variant="contained"
                style={{
                  fontSize: 'large',
                  width: '30ch'
                }}
                onClick={() => {
                  navigate('/providerPharmacies');
                }}>
                <Typography fontWeight="bold">
                  Browse Pharmacies
                </Typography>
              </Button>
            </Container>
            <Container
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '2%'
              }}>
              <Button
                variant="contained"
                style={{ fontSize: 'large', width: '30ch' }}
                onClick={() => {
                  setShowServiceCatalog(
                    !showServiceCatalog
                  );
                }}>
                <Typography fontWeight="bold">
                  {!showServiceCatalog ? 'Show ' : 'Hide '}
                  Service Catalog
                </Typography>
              </Button>
            </Container>
            <Container
              style={{
                display: showServiceCatalog
                  ? 'block'
                  : 'none',
                position: 'relative',
                paddingTop: 'max(60%,326px)',
                height: '0',
                width: '100%',
                marginBottom: '2%'
              }}>
              <iframe
                allow="clipboard-write"
                sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
                allowFullScreen={true}
                style={{
                  position: 'absolute',
                  border: 'none',
                  width: '100%',
                  height: '100%',
                  left: '0',
                  right: '0',
                  top: '0',
                  bottom: '0'
                }}
                src="https://e.issuu.com/embed.html?d=pharmacy_services_catalog&pageLayout=singlePage&u=ncpharmacists"></iframe>
            </Container>
          </>
        );
    }
  };

  return (
    <>
      <Container>
        <HomeNavBar view={view} setView={setView} />
        <img
          src={ncapbanner}
          style={{
            border: '1px solid rgba(0, 0, 0, 0.2)',
            borderRadius: 8,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'box-shadow 0.3s ease',
            marginTop: '8px',
            height: 'auto',
            width: '100%'
          }}
        />
        {getViewDetails()}
      </Container>
    </>
  );
};

export default Home;
