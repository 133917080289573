import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { updatePagination } from '../../redux/pharmacies/pharmaciesActions';
import { filterPharamciesByFilter } from '../../helpers/utils';

const PaginationBar: React.FC = (): JSX.Element => {
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(updatePagination(value));
  };

  const pharmacyServicesState = useSelector(
    (state: RootStateType) =>
      state.pharmaciesServicesMapping
  );

  const pharmaciesState = useSelector(
    (state: RootStateType) => state.pharmacies
  );
  const dispatch = useDispatch();

  return (
    <Pagination
      count={Math.ceil(
        filterPharamciesByFilter(
          pharmaciesState.pharmaciesList,
          pharmacyServicesState.pharmaciesAndServicesList,
          pharmaciesState.filters
        ).length / pharmaciesState.paginationPageCapacity
      )}
      page={pharmaciesState.currentPagination}
      onChange={handleChange}
      variant="outlined"
      shape="rounded"
    />
  );
};

export default PaginationBar;
