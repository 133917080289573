import { ACTION_NAMES } from '../reduxActions';

export const updatePharmaciesLayout = () => {
  return {
    type: ACTION_NAMES.UPDATE_PHARMACY_LAYOUT
  };
};

export const updatePharmaciesLayoutUser = (
  user: string
) => {
  return {
    type: ACTION_NAMES.UPDATE_PHARMACY_LAYOUT_USER,
    payload: user
  };
};
