import { ACTION_NAMES } from '../reduxActions';

const initialState = {
  enableError: false,
  message: ''
};

export const errorReducer = (
  state = initialState,
  action: { type: string; payload: string }
) => {
  switch (action.type) {
    case ACTION_NAMES.ENABLE_ERROR:
      return {
        ...state,
        enableError: true,
        message: action.payload
      };
    case ACTION_NAMES.DISABLE_ERROR:
      return {
        ...state,
        enableError: false,
        message: ''
      };
    default:
      return state;
  }
};
