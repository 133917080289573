import { Typography } from '@mui/material';
import { TEXT_TEMPLATES } from '../../helpers/constant';

const Disclaimer = (): JSX.Element => {
  return (
    <>
      <Typography
        align="center"
        ml={5}
        color="error"
        fontWeight="bold"
        fontSize="large">
        {TEXT_TEMPLATES.DISCLAIMER_TEXT}
      </Typography>
    </>
  );
};

export default Disclaimer;
