import { useState } from 'react';
import ReportPharmacyServiceData from './ReportPharmacyServiceData';
import {
  Button,
  Container,
  Grid,
  Typography
} from '@mui/material';
import ReportPharmacyServiceStats from './ReportPharmacyServiceStats';
import ReportPharmacyActivityStats from './ReportPharmacyActivityStats';

const Reports: React.FC = (): JSX.Element => {
  const reportList = [
    {
      reportName: 'Pharmacy Service Mappings',
      subTypes: ['Data', 'Stats']
    },
    {
      reportName: 'User Activity',
      subTypes: ['Stats']
    }
  ];

  const getReportComponent = (
    reportName: string,
    subType: string
  ) => {
    switch (reportName) {
      case 'Pharmacy Service Mappings':
        return subType === 'Data' ? (
          <ReportPharmacyServiceData />
        ) : (
          <ReportPharmacyServiceStats />
        );
      case 'User Activity':
        return subType === 'Stats' ? (
          <ReportPharmacyActivityStats />
        ) : null;
      default:
        return null;
    }
  };

  const [selectedReport, setSelectedReport] = useState<{
    reportName: string;
    subTypes: string[];
  }>(reportList[0]);

  const [selectedSubType, setSelectedSubType] =
    useState<string>(reportList[0].subTypes[0]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Container
            sx={{
              padding: '20px',
              border: '1px solid #ccc',
              borderRadius: '5px'
            }}>
            <Typography variant="h6">
              Select your choice of report
            </Typography>
            {reportList.map((report, index) => {
              return (
                <Button
                  key={index}
                  variant="contained"
                  color={
                    selectedReport?.reportName ===
                    report.reportName
                      ? 'success'
                      : 'primary'
                  }
                  onClick={() => {
                    setSelectedReport(report);
                    setSelectedSubType(report.subTypes[0]);
                  }}
                  sx={{
                    marginRight: '10px',
                    marginBottom: '10px',
                    width: '100%'
                  }}>
                  {report.reportName}
                </Button>
              );
            })}
          </Container>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Container
            sx={{
              padding: '20px',
              border: '1px solid #ccc',
              borderRadius: '5px'
            }}>
            {selectedReport && (
              <>
                <Typography variant="h6">
                  Select your choice of report type
                </Typography>
                {selectedReport.subTypes.map(
                  (subtype, index) => {
                    return (
                      <Button
                        key={index}
                        variant="contained"
                        color={
                          selectedSubType === subtype
                            ? 'success'
                            : 'primary'
                        }
                        onClick={() =>
                          setSelectedSubType(subtype)
                        }
                        sx={{
                          marginRight: '10px',
                          marginBottom: '10px',
                          width: '100%'
                        }}>
                        {subtype}
                      </Button>
                    );
                  }
                )}
              </>
            )}
          </Container>
        </Grid>
      </Grid>
      {getReportComponent(
        selectedReport.reportName,
        selectedSubType
      )}
    </>
  );
};

export default Reports;
