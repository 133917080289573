import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { useState } from 'react';
import { PharmacyObject } from '../../helpers/interfaces';
import { getAddress } from '../../helpers/utils';
import SortFilterTable from '../../sharedComponents/SortFilterTable/SortFilterTable';
import ServicesChips from '../../components/ServicesChips/ServicesChips';

const ReportPharmacyServiceData: React.FC =
  (): JSX.Element => {
    const pharmaciesState = useSelector(
      (state: RootStateType) => state.pharmacies
    );

    const [filteredPharmacies, setFilteredPharmacies] =
      useState<PharmacyObject[]>(
        pharmaciesState.pharmaciesList ||
          ([] as PharmacyObject[])
      );

    const tableColumns: {
      columnKey: keyof PharmacyObject | 'pharmacyAddress';
      columnDisplay: string;
      hidden: boolean;
    }[] = [
      {
        columnKey: 'permitNo',
        columnDisplay: 'Permit Number',
        hidden: false
      },
      {
        columnKey: 'pharmacyName',
        columnDisplay: 'Pharmacy',
        hidden: false
      },
      {
        columnKey: 'pharmacyAddress',
        columnDisplay: 'Address',
        hidden: true
      },
      {
        columnKey: 'locationCity',
        columnDisplay: 'City',
        hidden: false
      },
      {
        columnKey: 'locationCounty',
        columnDisplay: 'County',
        hidden: false
      },
      {
        columnKey: 'phone',
        columnDisplay: 'Phone',
        hidden: true
      },
      {
        columnKey: 'facilityEmail',
        columnDisplay: 'Facility Email',
        hidden: true
      }
    ];

    return (
      <>
        <ServicesChips
          setPharmacies={setFilteredPharmacies}
        />
        <SortFilterTable
          columnList={tableColumns}
          originalData={
            filteredPharmacies.length > 0
              ? filteredPharmacies.map((pharm) => ({
                  permitNo: pharm.permitNo,
                  pharmacyName: pharm.pharmacyName,
                  pharmacyAddress: getAddress(
                    pharm.locationAddress1,
                    pharm.locationAddress2,
                    pharm.locationCity,
                    pharm.locationState,
                    pharm.locationZip
                  ),
                  locationCity: pharm.locationCity,
                  locationCounty: pharm.locationCounty,
                  phone: pharm.phone,
                  facilityEmail: pharm.facilityEmail
                }))
              : []
          }
          originalDataCount={
            pharmaciesState &&
            pharmaciesState.pharmaciesList
              ? pharmaciesState.pharmaciesList.length
              : 0
          }
          tableDataItem="pharmacies"
        />
      </>
    );
  };

export default ReportPharmacyServiceData;
