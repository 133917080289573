import { DATA_LOAD_STATUS } from '../../helpers/constant';
import {
  PharmacyObject,
  UserProfileObject,
  UserWithPharmacyObject
} from '../../helpers/interfaces';
import { ACTION_NAMES } from '../reduxActions';

const initialState = {
  status: DATA_LOAD_STATUS.STATUS_DONE,
  userProfile: null as UserProfileObject,
  myPharmacy: [] as PharmacyObject[]
};

export const userReducer = (
  state = initialState,
  action: {
    type: string;
    payload: UserWithPharmacyObject | string;
  }
) => {
  switch (action.type) {
    case ACTION_NAMES.LOAD_USER_PROFILE:
      return {
        ...state,
        status: DATA_LOAD_STATUS.STATUS_LOADING,
        userProfile: null as UserProfileObject,
        myPharmacy: [] as PharmacyObject[]
      };
    case ACTION_NAMES.LOAD_USER_PROFILE_SUCCESS:
      return {
        ...state,
        status: DATA_LOAD_STATUS.STATUS_DONE,
        userProfile: ((
          action.payload as UserWithPharmacyObject
        )?.userProfile || null) as UserProfileObject,
        myPharmacy: ((
          action.payload as UserWithPharmacyObject
        )?.myPharmacy || null) as PharmacyObject[]
      };
    case ACTION_NAMES.LOG_OUT_USER_PROFILE:
      return {
        ...state,
        userProfile: null as UserProfileObject,
        myPharmacy: [] as PharmacyObject[]
      };
    default:
      return state;
  }
};
