import React, { useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  Box,
  IconButton,
  InputLabel,
  FormControl
} from '@mui/material';
import { SortByProps } from '../../helpers/interfaces';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const SortBy: React.FC<SortByProps> = (
  props: SortByProps
) => {
  const [sortOrder, setSortOrder] = useState<number>(1);
  const [sortBy, setSortBy] = useState<{
    filterName: string;
    attributeName: string;
    type: string;
  }>(props.defaultSorting);

  useEffect(() => {
    let result = [...props.data];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result = result.sort((f1: any, f2: any) => {
      switch (sortBy.type) {
        case 'number':
          return (
            (f1[sortBy.attributeName] -
              f2[sortBy.attributeName]) *
            sortOrder
          );
        case 'string':
          return (
            f1[sortBy.attributeName].localeCompare(
              f2[sortBy.attributeName]
            ) * sortOrder
          );
        default:
          return (
            (new Date(f1[sortBy.attributeName]).getTime() -
              new Date(
                f2[sortBy.attributeName]
              ).getTime()) *
            sortOrder
          );
      }
    });
    props.setData(result);
  }, [sortOrder, sortBy]);

  return (
    <Box
      style={{
        width: '15%',
        margin: '0 2% 2% auto',
        display: 'flex'
      }}>
      <IconButton
        onClick={() => {
          setSortOrder(sortOrder * -1);
        }}
        size="small">
        {sortOrder === 1 ? (
          <ArrowUpwardIcon />
        ) : (
          <ArrowDownwardIcon />
        )}
      </IconButton>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="sort-by-label">Sort By</InputLabel>
        <Select
          style={{ textAlign: 'left' }}
          value={sortBy.filterName}
          onChange={(event) => {
            const value =
              props.sortingOptions.filter(
                (option) =>
                  option.filterName === event.target.value
              )[0] || props.defaultSorting;
            setSortBy(value);
          }}
          variant="outlined"
          fullWidth
          label="Sort By">
          {props.sortingOptions.map((option) => {
            return (
              <MenuItem
                key={option.attributeName}
                value={option.filterName}>
                {option.filterName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SortBy;
