import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { ProviderQuestionsProps } from '../../helpers/interfaces';
import { getProviderLevelFromType } from '../../helpers/utils';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { SERVICE_QUESTION_TYPES } from '../../helpers/constant';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';

const ProviderQuestions: React.FC<
  ProviderQuestionsProps
> = (props: ProviderQuestionsProps): JSX.Element => {
  const serviceRequestsState = useSelector(
    (state: RootStateType) => state.serviceRequests
  );

  const requestAlert = (): string => {
    const pendingRequests =
      serviceRequestsState.requests.filter(
        (req) =>
          req.requestStatus === 'PENDING' &&
          req.questionType ===
            SERVICE_QUESTION_TYPES.PROVIDER &&
          req.pharmacyRecordId === props.pharmacyRecordId &&
          req.serviceId === props.serviceId
      );
    return pendingRequests.length > 0
      ? `Service request has been submmitted. Please wait for it to be completed.`
      : '';
  };

  return (
    <>
      <Typography
        component="h1"
        variant="h5"
        fontWeight="bold"
        fontSize={20}
        mt={4}>
        Service Commitment Level
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            component="h1"
            variant="h5"
            fontSize={20}>
            Select the radio button that represents the
            level of service you currently provide
            <WarningComponentForIncorrectField
              message={requestAlert()}
            />
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <FormControl>
            <RadioGroup
              value={props.selectedProviderLevel}
              name="radio-buttons-group"
              onChange={(event) => {
                props.setSelectedProviderLevel(
                  event.target.value
                );
              }}>
              {props.providerQuestions.map((question) => {
                return (
                  <FormControlLabel
                    key={question.serviceQuestionId}
                    value={getProviderLevelFromType(
                      question.serviceQuestionType
                    )}
                    control={
                      <Radio
                        disabled={requestAlert() !== ''}
                        sx={{ mb: 'auto', mt: -1 }}
                      />
                    }
                    label={
                      <Typography
                        component="h1"
                        variant="h5"
                        fontSize={20}>
                        {question.serviceQuestion}
                      </Typography>
                    }
                  />
                );
              })}
              <FormControlLabel
                value="None"
                key="None"
                control={
                  <Radio disabled={requestAlert() !== ''} />
                }
                label={
                  <Typography
                    component="h1"
                    variant="h5"
                    fontSize={20}>
                    None of these
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default ProviderQuestions;
