import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect } from 'react';
import { loadMailLogs } from '../../redux/mailLogs/mailLogsActions';
import { getDateTimeString } from '../../helpers/utils';

const TrackMails: React.FC = (): JSX.Element => {
  const mailLogsState = useSelector(
    (state: RootStateType) => state.mailLogs
  );

  const dispatch = useDispatch();

  useEffect(() => {
    loadMailLogs(dispatch);
  }, []);

  return (
    <>
      {!mailLogsState ||
      mailLogsState.mailLogs.length === 0 ? (
        <Typography variant="h6">
          No mails sent yet.
        </Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead
                style={{
                  fontWeight: 'bold',
                  backgroundColor: '#f0f0f0'
                }}>
                <TableRow>
                  <TableCell>To Email</TableCell>
                  <TableCell>Permit Number</TableCell>
                  <TableCell>Mail Type</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mailLogsState.mailLogs.map((mailLog) => (
                  <TableRow key={mailLog.mailLogId}>
                    <TableCell>{mailLog.toEmail}</TableCell>
                    <TableCell>
                      {mailLog.permitNo}
                    </TableCell>
                    <TableCell>
                      {mailLog.mailType}
                    </TableCell>
                    <TableCell>
                      {mailLog.description}
                    </TableCell>
                    <TableCell>
                      {getDateTimeString(mailLog.date)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default TrackMails;
