import {
  AdminUserObject,
  FeedbackObject,
  HistoryObject,
  MailLogsObject,
  PharmacyObject,
  PharmacyService,
  PlainServiceObject,
  PlainUserObject,
  ServiceObject,
  ServiceQuestionObject,
  ServiceReferenceObject,
  ServiceRequestObject,
  UserWithPharmacyObject,
  LocationSearchObject
} from './interfaces';
import axios from 'axios';
import { enableError } from '../redux/error/errorActions';
import { DispatchType } from '../redux/store';
import { getDateInMySQLFormat } from './utils';

const environment = process.env.REACT_APP_ENVIRONMENT;

const backendUrl =
  environment === 'DEV'
    ? process.env.REACT_APP_BACKEND_URL_DEV
    : process.env.REACT_APP_BACKEND_URL_PROD;

export const getPharmaciesApi = async (
  dispatch: DispatchType
): Promise<PharmacyObject[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/pharmacies`
    });
    return result.data as PharmacyObject[];
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const getPharmacyByRecordId = async (
  dispatch: DispatchType,
  recordId: number
): Promise<PharmacyObject | null> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/pharmacies/${recordId}`
    });
    return result.data[0] as PharmacyObject;
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return null;
  }
};

export const lookupPharmacyLocation = async (
  pharmacyValue: PharmacyObject
): Promise<LocationSearchObject> => {
  const location = {
    latitude: '0',
    longitude: '0',
    error: true
  };
  try {
    const street =
      pharmacyValue.locationAddress1.split(',')[0];
    const result = await axios({
      method: 'GET',
      url: `https://nominatim.openstreetmap.org/search`,
      params: {
        street,
        city: pharmacyValue.locationCity,
        county: pharmacyValue.locationCounty,
        state: 'North Carolina',
        country: 'United States',
        postalcode: pharmacyValue.locationZip,
        format: 'json',
        limit: '1'
      }
    });
    if (result.data.length > 0) {
      location.latitude = result.data[0]?.lat ?? '0';
      location.longitude = result.data[0]?.lon ?? '0';
      if (
        location.latitude !== '0' &&
        location.longitude !== '0'
      ) {
        location.error = false;
      }
    }
  } catch (e) {
    console.log('Error: ', e);
  }
  return location as LocationSearchObject;
};

export const getPharmacyHistory = async (
  dispatch: DispatchType,
  recordId: number
): Promise<HistoryObject[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/pharmacyHistory/${recordId}`
    });
    return result.data as HistoryObject[];
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const getPlainPharmacyHistoryApi = async (
  dispatch: DispatchType
): Promise<HistoryObject[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/pharmacyHistory`
    });
    return result.data as HistoryObject[];
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const createPharmacyApi = async (
  dispatch: DispatchType,
  pharmacy: PharmacyObject,
  userId: number
) => {
  try {
    await axios({
      method: 'POST',
      url: `${backendUrl}/pharmacies`,
      data: {
        data: {
          pharmacy: pharmacy,
          userId: userId
        }
      }
    });
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const createBulkPharmacyApi = async (
  dispatch: DispatchType,
  pharmacies: PharmacyObject[],
  userId: number
) => {
  try {
    await axios({
      method: 'POST',
      url: `${backendUrl}/pharmacies/bulkCreate`,
      data: {
        data: {
          pharmacyList: pharmacies,
          userId: userId
        }
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('Error: ', e.response.data.error);
    throw new Error(e.response.data.error || e.message);
  }
};

export const updatePharmacyApi = async (
  dispatch: DispatchType,
  updatedPharmacyObject: PharmacyObject,
  updationComment: string,
  userId: number
) => {
  try {
    await axios({
      method: 'PUT',
      url: `${backendUrl}/pharmacies`,
      data: {
        data: {
          updatedPharmacyObject: updatedPharmacyObject,
          updationComment: updationComment,
          userId: userId
        }
      }
    });
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const createUserApi = async (
  dispatch: DispatchType,
  name: string,
  email: string,
  permitNo: string,
  password: string
): Promise<void> => {
  try {
    await axios({
      method: 'POST',
      url: `${backendUrl}/users`,
      data: {
        data: {
          name: name,
          email: email,
          password: password,
          permitNo: permitNo
        }
      }
    });
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
  }
};

export const getUserProfileApi = async (
  permitNo: string,
  email: string,
  password: string,
  isAdmin: boolean
): Promise<UserWithPharmacyObject | null> => {
  try {
    const result = await axios({
      method: 'POST',
      url: `${backendUrl}/users/userProfile`,
      data: {
        data: {
          permitNo: permitNo,
          email: email,
          password: password,
          isAdmin: isAdmin
        }
      }
    });
    return result.data as UserWithPharmacyObject;
  } catch (e) {
    console.log('Error: ', (e as Error).message);
    return null;
  }
};

export const forgotPasswordApi = async (
  permitNo: string,
  email: string,
  isAdmin: boolean
): Promise<void> => {
  try {
    await axios({
      method: 'POST',
      url: `${backendUrl}/users/forgotUserPassword`,
      data: {
        data: {
          permitNo: permitNo,
          email: email,
          isAdmin: isAdmin
        }
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const getPlainUsersApi = async (
  dispatch: DispatchType
): Promise<PlainUserObject[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/users/plain`
    });
    return result.data as PlainUserObject[];
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const getServicesApi = async (
  dispatch: DispatchType
): Promise<ServiceObject[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/services`
    });
    return result.data as ServiceObject[];
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const getPlainServicesApi = async (
  dispatch: DispatchType
): Promise<PlainServiceObject[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/services/plain`
    });
    return result.data as PlainServiceObject[];
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const getPlainServiceQuestionsApi = async (
  dispatch: DispatchType
): Promise<ServiceQuestionObject[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/services/plainQuestions`
    });
    return result.data as ServiceQuestionObject[];
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const getPlainServiceReferencesApi = async (
  dispatch: DispatchType
): Promise<ServiceReferenceObject[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/services/plainReferences`
    });
    return result.data as ServiceReferenceObject[];
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const createServiceApi = async (
  dispatch: DispatchType,
  serviceName: string,
  serviceDefinition: string,
  publicServiceDefinition: string,
  serviceQuestions: ServiceQuestionObject[],
  serviceReferences: ServiceReferenceObject[]
): Promise<void> => {
  try {
    await axios({
      method: 'POST',
      url: `${backendUrl}/services`,
      data: {
        data: {
          serviceName: serviceName,
          serviceDefinition: serviceDefinition,
          publicServiceDefinition: publicServiceDefinition,
          serviceQuestions: serviceQuestions,
          serviceReferences: serviceReferences
        }
      }
    });
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
  }
};

export const updateServiceApi = async (
  dispatch: DispatchType,
  service: ServiceObject
): Promise<void> => {
  try {
    await axios({
      method: 'PUT',
      url: `${backendUrl}/services`,
      data: {
        data: {
          service: service
        }
      }
    });
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
  }
};

export const deleteServiceApi = async (
  dispatch: DispatchType,
  serviceId: number
): Promise<void> => {
  try {
    await axios({
      method: 'PATCH',
      url: `${backendUrl}/services`,
      data: {
        data: {
          serviceId: serviceId
        }
      }
    });
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
  }
};

export const getPharmacyServicesMappingApi = async (
  dispatch: DispatchType
): Promise<PharmacyService[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/pharmacyServices`
    });
    return result.data as PharmacyService[];
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const getServiceRequestsApi = async (
  dispatch: DispatchType
): Promise<ServiceRequestObject[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/serviceRequests`
    });
    return result.data as ServiceRequestObject[];
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [] as ServiceRequestObject[];
  }
};

export const createServiceRequestsApi = async (
  dispatch: DispatchType,
  serviceRequests: ServiceRequestObject[],
  userId: number,
  userRole: string,
  questions: string[],
  serviceName: string
): Promise<void> => {
  try {
    await axios({
      method: 'POST',
      url: `${backendUrl}/serviceRequests`,
      data: {
        data: {
          serviceRequests: serviceRequests,
          userId: userId,
          userRole: userRole,
          questions: questions,
          serviceName: serviceName
        }
      }
    });
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
  }
};

export const approveRejectServiceRequestsApi = async (
  dispatch: DispatchType,
  serviceRequest: ServiceRequestObject,
  userId: number,
  userRole: string,
  action: string,
  actionComment: string
): Promise<void> => {
  try {
    await axios({
      method: 'POST',
      url: `${backendUrl}/serviceRequests/approveReject`,
      data: {
        data: {
          serviceRequest: serviceRequest,
          userId: userId,
          userRole: userRole,
          action: action,
          actionComment: actionComment
        }
      }
    });
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
  }
};

export const resetPasswordApi = async (
  permitNo: string,
  userId: number,
  oldPassword: string,
  newPassword: string
): Promise<string> => {
  try {
    const result = await axios({
      method: 'POST',
      url: `${backendUrl}/users/resetPassword`,
      data: {
        data: {
          permitNo: permitNo,
          userId: userId,
          oldPassword: oldPassword,
          newPassword: newPassword
        }
      }
    });
    return result.data as string;
  } catch (e) {
    console.log('Error: ', e);
    return 'Error occurred, please try again later';
  }
};

export const getFeedbacksApi = async (
  dispatch: DispatchType
): Promise<FeedbackObject[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/feedbacks`
    });
    return result.data as FeedbackObject[];
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const createFeedbackApi = async (
  dispatch: DispatchType,
  email: string,
  topic: string,
  description: string
): Promise<void> => {
  try {
    await axios({
      method: 'POST',
      url: `${backendUrl}/feedbacks`,
      data: {
        data: {
          email: email,
          topic: topic,
          description: description,
          assignedTo: 'Unassigned',
          dateCreated: getDateInMySQLFormat(new Date())
        }
      }
    });
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
  }
};

export const updateFeedbackApi = async (
  dispatch: DispatchType,
  feedbackId: number,
  status: string,
  comment: string,
  assignedTo: string
): Promise<void> => {
  try {
    await axios({
      method: 'PUT',
      url: `${backendUrl}/feedbacks`,
      data: {
        data: {
          feedbackId: feedbackId,
          status: status,
          comment: comment,
          assignedTo: assignedTo
        }
      }
    });
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
  }
};

export const replyFeedbackApi = async (
  dispatch: DispatchType,
  feedbackId: number,
  comment: string,
  includeDisclaimer: boolean,
  replyToAddress: string
): Promise<void> => {
  try {
    await axios({
      method: 'PUT',
      url: `${backendUrl}/feedbacks/reply`,
      data: {
        data: {
          feedbackId: feedbackId,
          comment: comment,
          includeDisclaimer: includeDisclaimer,
          replyToAddress: replyToAddress
        }
      }
    });
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
  }
};

export const getAdminUsersApi = async (
  dispatch: DispatchType
): Promise<AdminUserObject[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/users/usersAdmin`
    });
    return result.data as AdminUserObject[];
  } catch (e) {
    console.log('Error: ', (e as Error).message);
    dispatch(enableError((e as Error).message));
    return [];
  }
};

export const updateAdminApi = async (
  dispatch: DispatchType,
  superAdminEmail: string,
  adminUserId: number,
  adminEmail: string,
  newName: string,
  action: string
): Promise<void> => {
  try {
    await axios({
      method: 'PUT',
      url: `${backendUrl}/users/adminUpdate`,
      data: {
        data: {
          superAdminEmail: superAdminEmail,
          adminUserId: adminUserId,
          newName: newName,
          adminEmail: adminEmail,
          action: action
        }
      }
    });
    return;
  } catch (e) {
    console.log('Error: ', (e as Error).message);
    dispatch(enableError((e as Error).message));
    return;
  }
};

export const createAdminApi = async (
  dispatch: DispatchType,
  name: string,
  email: string
): Promise<void> => {
  try {
    await axios({
      method: 'POST',
      url: `${backendUrl}/users/admin`,
      data: {
        data: {
          name: name,
          email: email
        }
      }
    });
    return;
  } catch (e) {
    console.log('Error: ', (e as Error).message);
    dispatch(enableError((e as Error).message));
    return;
  }
};

export const getMailLogsApi = async (
  dispatch: DispatchType
): Promise<MailLogsObject[]> => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${backendUrl}/mailLogs`
    });
    return result.data as MailLogsObject[];
  } catch (e) {
    console.log('Error: ', e);
    dispatch(enableError((e as Error).message));
    return [];
  }
};
