import {
  Divider,
  Grid,
  TextField,
  TextareaAutosize,
  Typography
} from '@mui/material';
import { ServiceReviewProps } from '../../helpers/interfaces';
import {
  SERVICE_QUESTION_REFERENCE_STATUS,
  SERVICE_QUESTION_TYPES
} from '../../helpers/constant';

const ServiceReview: React.FC<ServiceReviewProps> = (
  props: ServiceReviewProps
): JSX.Element => {
  const providerQuestions = props.serviceQuestions
    .filter(
      (question) =>
        question.status !==
          SERVICE_QUESTION_REFERENCE_STATUS.DELETED &&
        ![
          SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
          SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
        ].includes(question.serviceQuestionType)
    )
    .sort(
      (q1, q2) =>
        q1.serviceQuestionId - q2.serviceQuestionId
    );

  const supplementalQuestions = props.serviceQuestions
    .filter(
      (question) =>
        question.status !==
          SERVICE_QUESTION_REFERENCE_STATUS.DELETED &&
        [
          SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
          SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
        ].includes(question.serviceQuestionType)
    )
    .sort(
      (q1, q2) =>
        q1.serviceQuestionId - q2.serviceQuestionId
    );
  const activeReferences = props.serviceReferences.filter(
    (reference) =>
      reference.status !==
      SERVICE_QUESTION_REFERENCE_STATUS.DELETED
  );

  return (
    <>
      <Typography variant="h5" align="center">
        Review service
      </Typography>
      <Divider
        sx={{
          marginTop: '2%',
          marginBottom: '2%',
          borderBottomWidth: '1px',
          borderColor: '#000'
        }}
      />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">
            Service Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="given-service-name"
            name="service-name"
            required
            fullWidth
            label="Service Name"
            value={props.serviceName}
            sx={{
              marginBottom: '2%'
            }}
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="gray" fontSize={12}>
            Service Definition *
          </Typography>

          <TextareaAutosize
            readOnly
            required
            style={{
              width: '100%',
              fontFamily: 'inherit',
              fontSize: 17
            }}
            name={props.serviceDefinition}
            value={props.serviceDefinition}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="gray" fontSize={12}>
            Public-facing Service Definition *
          </Typography>

          <TextareaAutosize
            readOnly
            required
            style={{
              width: '100%',
              fontFamily: 'inherit',
              fontSize: 17
            }}
            name={props.publicServiceDefinition}
            value={props.publicServiceDefinition}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{
              marginTop: '2%',
              marginBottom: '2%',
              borderBottomWidth: '1px',
              borderColor: '#000'
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="bold">
            Questions
          </Typography>
        </Grid>
        {providerQuestions.map((question) => {
          return (
            <Grid
              item
              key={question.serviceQuestionId}
              xs={12}>
              <Typography color="gray" fontSize={12}>
                {question.serviceQuestionType + ' *'}
              </Typography>

              <TextareaAutosize
                readOnly
                required
                style={{
                  width: '100%',
                  fontFamily: 'inherit',
                  fontSize: 17,
                  marginBottom: '0.5%'
                }}
                name={question.serviceQuestionType}
                value={question.serviceQuestion}
              />
            </Grid>
          );
        })}
        {supplementalQuestions.map((question) => {
          return (
            <Grid
              item
              key={question.serviceQuestionId}
              xs={12}>
              <Typography color="gray" fontSize={12}>
                {`Supplemental Service (${
                  question.serviceQuestionType ===
                  SERVICE_QUESTION_TYPES.SUPPLEMENTAL
                    ? 'Yes/No'
                    : 'Custom'
                }) *`}
              </Typography>

              <TextareaAutosize
                readOnly
                required
                style={{
                  width: '100%',
                  fontFamily: 'inherit',
                  fontSize: 17,
                  marginBottom: '0.5%'
                }}
                name={question.serviceQuestionType}
                value={question.serviceQuestion}
              />
            </Grid>
          );
        })}
        {activeReferences.length > 0 && (
          <>
            <Grid item xs={12}>
              <Divider
                sx={{
                  marginTop: '2%',
                  marginBottom: '2%',
                  borderBottomWidth: '1px',
                  borderColor: '#000'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight="bold">
                References
              </Typography>
            </Grid>
            {activeReferences.map((reference) => {
              return (
                <>
                  <Grid
                    item
                    key={
                      reference.serviceReferenceId + 'name'
                    }
                    xs={5}
                    mr={1}>
                    <TextField
                      name="Reference Name"
                      required
                      fullWidth
                      label="Reference Name"
                      sx={{ marginBottom: '2%' }}
                      value={reference.serviceReferenceName}
                    />
                  </Grid>
                  <Grid
                    item
                    key={
                      reference.serviceReferenceId + 'link'
                    }
                    xs={6.5}>
                    <TextField
                      name="Reference Link"
                      required
                      fullWidth
                      label="Reference Link"
                      sx={{ marginBottom: '2%' }}
                      value={reference.serviceReferenceLink}
                    />
                  </Grid>
                </>
              );
            })}
          </>
        )}
      </Grid>
    </>
  );
};

export default ServiceReview;
