import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography
} from '@mui/material';
import {
  PharmacyService,
  ServiceQuestionObject,
  SupplementalQuestionsProps
} from '../../helpers/interfaces';
import { SERVICE_QUESTION_TYPES } from '../../helpers/constant';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';

const SupplementalQuestions: React.FC<
  SupplementalQuestionsProps
> = (props: SupplementalQuestionsProps): JSX.Element => {
  const serviceRequestsState = useSelector(
    (state: RootStateType) => state.serviceRequests
  );

  const requestAlert = (questionId: number): string => {
    const pendingRequests =
      serviceRequestsState.requests.filter(
        (req) =>
          req.requestStatus === 'PENDING' &&
          req.serviceQuestionId === questionId &&
          [
            SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
            SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
          ].includes(req.questionType) &&
          req.pharmacyRecordId === props.pharmacyRecordId &&
          req.serviceId === props.serviceId
      );
    return pendingRequests.length > 0
      ? `Service request has been submmitted. Please wait for it to be completed.`
      : '';
  };

  const getResponse = (
    questionId: number,
    type: string
  ): string => {
    const filteredItem = props.supplementalResponses.filter(
      (resp) => resp?.serviceQuestionId === questionId
    );
    if (type === SERVICE_QUESTION_TYPES.SUPPLEMENTAL) {
      return filteredItem && filteredItem.length > 0
        ? filteredItem[0]?.response || 'No'
        : 'No';
    }
    return filteredItem && filteredItem.length > 0
      ? filteredItem[0]?.response || ''
      : '';
  };

  const checkIsResponsePresent = (
    questionId: number
  ): boolean => {
    const filteredItem = props.supplementalResponses.filter(
      (resp) => resp?.serviceQuestionId === questionId
    );
    if (filteredItem && filteredItem.length > 0) {
      return filteredItem[0]?.response !== '';
    }
    return false;
  };

  const setUpdatedResponses = (
    question: ServiceQuestionObject,
    type: string,
    value: string
  ): void => {
    const existingIndex =
      props.supplementalResponses.findIndex(
        (resp) =>
          resp &&
          resp.serviceQuestionId ===
            question.serviceQuestionId
      );
    if (existingIndex !== -1) {
      const updatedResponses = [
        ...props.supplementalResponses
      ];
      updatedResponses.map((resp) => {
        if (
          resp &&
          resp.serviceQuestionId ===
            question.serviceQuestionId
        ) {
          resp.response = value;
        }
      });
      props.setSupplementalResponses(updatedResponses);
    } else {
      props.setSupplementalResponses([
        ...props.supplementalResponses,
        {
          pharmacyRecordId: props.pharmacyRecordId,
          pharmacyServiceId: -1,
          questionType: type,
          serviceId: question.serviceId,
          serviceQuestionId: question.serviceQuestionId,
          response: value
        } as PharmacyService
      ]);
    }
  };

  return (
    <>
      <Typography
        component="h1"
        variant="h5"
        fontWeight="bold"
        fontSize={20}
        mt={4}>
        Supplemental Services
      </Typography>

      <Grid container spacing={1}>
        {props.supplementalQuestions.map((question) => {
          return (
            <Grid
              item
              xs={12}
              key={question.serviceQuestionId}>
              <Typography
                component="h1"
                variant="h5"
                fontSize={20}>
                {question.serviceQuestion}
                <WarningComponentForIncorrectField
                  message={requestAlert(
                    question.serviceQuestionId
                  )}
                />
              </Typography>
              {question.serviceQuestionType ===
              SERVICE_QUESTION_TYPES.SUPPLEMENTAL ? (
                <FormControl>
                  <RadioGroup
                    row={true}
                    value={getResponse(
                      question.serviceQuestionId,
                      SERVICE_QUESTION_TYPES.SUPPLEMENTAL
                    )}
                    name="radio-buttons-group"
                    onChange={(event) => {
                      setUpdatedResponses(
                        question,
                        SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
                        event.target.value
                      );
                    }}>
                    <FormControlLabel
                      key="yes"
                      value="Yes"
                      control={
                        <Radio
                          disabled={
                            requestAlert(
                              question.serviceQuestionId
                            ) !== ''
                          }
                        />
                      }
                      label={
                        <Typography
                          component="h1"
                          variant="h5"
                          fontSize={20}>
                          Yes
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      key="no"
                      value="No"
                      control={
                        <Radio
                          disabled={
                            requestAlert(
                              question.serviceQuestionId
                            ) !== ''
                          }
                        />
                      }
                      label={
                        <Typography
                          component="h1"
                          variant="h5"
                          fontSize={20}>
                          No
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                <>
                  <TextareaAutosize
                    required
                    style={{
                      width: '100%',
                      fontFamily: 'inherit',
                      fontSize: 17
                    }}
                    // placeholder="Service Definition"
                    value={getResponse(
                      question.serviceQuestionId,
                      SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
                    )}
                    onChange={(event) => {
                      setUpdatedResponses(
                        question,
                        SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL,
                        event.target.value
                      );
                    }}
                  />
                  <WarningComponentForIncorrectField
                    message={
                      !checkIsResponsePresent(
                        question.serviceQuestionId
                      )
                        ? 'A custom supplemental question cannot be left unanswered'
                        : ''
                    }
                  />
                </>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SupplementalQuestions;
