import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PharmacyObject } from '../../helpers/interfaces';
import { getPharmacyByRecordId } from '../../helpers/apiCalls';
import {
  Divider,
  Grid,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import HistoryModal from '../../components/HistoryModal/HistoryModal';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import { useDispatch, useSelector } from 'react-redux';
import Error from '../../components/Error/Error';
import { RootStateType } from '../../redux/store';
import PharmacyServices from '../../components/PharmacyServices/PharmacyServices';
import {
  getAddress,
  fixCoordinates,
  showFacilityEmail
} from '../../helpers/utils';
import EditIcon from '@mui/icons-material/Edit';
import {
  TEXT_TEMPLATES,
  USER_ROLES
} from '../../helpers/constant';
import GeoMap from '../../components/GeoMap/GeoMap';

const PharmacyPage: React.FC = (): JSX.Element => {
  const { id } = useParams<string>();
  const [pharmacy, setPharmacy] =
    useState<PharmacyObject | null>(null);
  const [showMap, setShowMap] = useState<boolean>(false);
  const [displayModal, setDisplayModal] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const errorState = useSelector(
    (state: RootStateType) => state.error
  );
  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  const [editAccess, setEditAccess] =
    useState<boolean>(false);

  useEffect(() => {
    setEditAccess(
      (userState.userProfile !== null &&
        [USER_ROLES.ADMIN, USER_ROLES.SUPERADMIN].includes(
          userState.userProfile.role
        )) ||
        (userState.myPharmacy !== null &&
          userState.myPharmacy.length > 0 &&
          userState.myPharmacy[0].recordId === Number(id))
    );
  }, [userState, id]);

  useEffect(() => {
    const fetch = async () => {
      const pharmacyValue = await getPharmacyByRecordId(
        dispatch,
        Number(id)
      );
      if (!pharmacyValue) {
        return;
      }

      const showMap =
        fixCoordinates(pharmacyValue).locationLatitude *
          10000 !==
          0 &&
        fixCoordinates(pharmacyValue).locationLongitude *
          10000 !==
          0;

      setShowMap(showMap);
      setPharmacy(pharmacyValue);
    };
    fetch();
  }, [id]);

  return (
    <>
      {errorState.enableError && (
        <Error message={errorState.message} />
      )}
      {!errorState.enableError && pharmacy && (
        <>
          <Grid container mt={2} ml={2} mr={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container>
                <Grid item xs={1} sm={1} md={1}>
                  <Tooltip title="Back">
                    <IconButton
                      onClick={() => {
                        navigate('/pharmacies');
                      }}>
                      <ArrowBackIosNewOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={9} sm={9} md={9}>
                  <Typography
                    variant="h4"
                    style={{
                      color: '#368cc1',
                      marginBottom: '3%'
                    }}>
                    <b>{pharmacy.pharmacyName}</b>
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                  <>
                    {editAccess && (
                      <>
                        <Tooltip title="Edit pharmacy details">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              navigate(
                                `/managePharmacy/${pharmacy.recordId}`
                              );
                            }}>
                            <EditIcon fontSize="large" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="View update history">
                          <IconButton
                            onClick={() => {
                              setDisplayModal(true);
                            }}>
                            <HistoryOutlinedIcon
                              fontSize="large"
                              color="secondary"
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={6}>
              <Typography align="left" variant="h6">
                {getAddress(
                  pharmacy.locationAddress1,
                  pharmacy.locationAddress2,
                  pharmacy.locationCity,
                  pharmacy.locationState,
                  pharmacy.locationZip
                )}
              </Typography>
              <Typography align="left" variant="h6">
                <b>County: </b>
                {pharmacy.locationCounty}
              </Typography>
              <Typography align="left" variant="h6">
                <b>Phone: </b>
                {pharmacy.phone}
              </Typography>
              {pharmacy.fax && (
                <Typography align="left" variant="h6">
                  <b>Fax: </b>
                  {pharmacy.fax}
                </Typography>
              )}
              {showFacilityEmail(pharmacy) && (
                <Typography align="left" variant="h6">
                  <b>Facility Email: </b>
                  {pharmacy.facilityEmail}
                </Typography>
              )}
              <Typography align="left" variant="h6">
                <b>Mailing Address: </b>
                {getAddress(
                  pharmacy.mailingAddress1,
                  pharmacy.mailingAddress2,
                  pharmacy.mailingCity,
                  pharmacy.mailingState,
                  pharmacy.mailingZip
                )}
                <br />
                County: {pharmacy.mailingCounty}
              </Typography>
              {pharmacy.operationHours && (
                <Typography align="left" variant="h6">
                  <b>Hours: </b>
                  {pharmacy.operationHours}
                </Typography>
              )}
              {pharmacy.website && (
                <Typography align="left" variant="h6">
                  <b>Website: </b>
                  <a
                    href={`${
                      pharmacy.website.includes('http')
                        ? ''
                        : 'https://'
                    }${pharmacy.website}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {pharmacy.website}
                  </a>
                </Typography>
              )}
            </Grid>
            {showMap && (
              <Grid item xs={12} sm={7} md={5}>
                <GeoMap recordId={pharmacy.recordId} />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} mt={2}>
              <Typography variant="h5" align="left">
                <b>Services Offered</b>
              </Typography>
              <Typography
                align="left"
                color="error"
                fontWeight="bold"
                fontSize="medium">
                {TEXT_TEMPLATES.DISCLAIMER_TEXT}
              </Typography>

              <Divider
                sx={{
                  borderBottomWidth: '1px',
                  borderColor: '#000'
                }}
              />
              <Grid item xs={12}>
                <PharmacyServices
                  pharmacyRecordId={pharmacy.recordId}
                />
              </Grid>
            </Grid>
          </Grid>
          <HistoryModal
            recordId={pharmacy.recordId}
            displayModal={displayModal}
            setDisplayModal={setDisplayModal}
          />
        </>
      )}
    </>
  );
};

export default PharmacyPage;
