import {
  Grid,
  TextField,
  TextareaAutosize,
  Typography
} from '@mui/material';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import { ServiceDetailsProps } from '../../helpers/interfaces';

const ServiceDetails: React.FC<ServiceDetailsProps> = (
  props: ServiceDetailsProps
): JSX.Element => {
  return (
    <>
      <Typography variant="h5" align="center">
        Add service details
      </Typography>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <TextField
            autoComplete="given-service-name"
            name="service-name"
            required
            fullWidth
            label="Service Name"
            value={props.serviceName}
            sx={{ marginBottom: '2%' }}
            onChange={(event) => {
              props.setServcieName(event.target.value);
            }}
            autoFocus
          />
          <WarningComponentForIncorrectField
            message={props.serviceNameMessage}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="gray" fontSize={12}>
            Service Definition *
          </Typography>

          <TextareaAutosize
            required
            style={{
              width: '100%',
              fontFamily: 'inherit',
              fontSize: 17
            }}
            placeholder="Service Definition"
            value={props.serviceDefinition}
            onChange={(event) => {
              props.setServcieDefinition(
                event.target.value
              );
            }}
          />
          <WarningComponentForIncorrectField
            message={props.serviceDefinitionMessage}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography color="gray" fontSize={12}>
            Public-facing Service Definition *
          </Typography>

          <TextareaAutosize
            required
            style={{
              width: '100%',
              fontFamily: 'inherit',
              fontSize: 17
            }}
            placeholder="Public-facing Service Definition"
            value={props.publicServiceDefinition}
            onChange={(event) => {
              props.setPublicServcieDefinition(
                event.target.value
              );
            }}
          />
          <WarningComponentForIncorrectField
            message={props.publicServiceDefinitionMessage}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceDetails;
