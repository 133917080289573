import { Container, Grid, Typography } from '@mui/material';
import PharmacyCard from '../PharmacyCard/PharmacyCard';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { useEffect, useState } from 'react';
import { PharmacyObject } from '../../helpers/interfaces';
import { filterPharamciesByFilter } from '../../helpers/utils';
import SortBy from '../../sharedComponents/SortBy/SortBy';

const PharmacyContainer: React.FC = (): JSX.Element => {
  const pharmaciesState = useSelector(
    (state: RootStateType) => state.pharmacies
  );

  const pharmacyServicesState = useSelector(
    (state: RootStateType) =>
      state.pharmaciesServicesMapping
  );

  const [updatedPharmacies, setUpdatedPharmacies] =
    useState<PharmacyObject[]>(
      pharmaciesState.pharmaciesList
    );

  useEffect(() => {
    setUpdatedPharmacies(
      filterPharamciesByFilter(
        pharmaciesState.pharmaciesList,
        pharmacyServicesState.pharmaciesAndServicesList,
        pharmaciesState.filters
      )
    );
  }, [
    pharmaciesState.filters,
    pharmaciesState.pharmaciesList
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSort = (data: any) => {
    setUpdatedPharmacies(data as PharmacyObject[]);
  };

  const getPaginationSliceValueStart = (): number => {
    return (
      (pharmaciesState.currentPagination - 1) *
      pharmaciesState.paginationPageCapacity
    );
  };

  const getPaginationSliceValueEnd = (): number => {
    return (
      pharmaciesState.currentPagination *
      pharmaciesState.paginationPageCapacity
    );
  };

  return (
    <>
      {updatedPharmacies.length === 0 ? (
        <Typography variant="h5" component="h2" mt={15}>
          {pharmaciesState.pharmaciesList.length === 0
            ? 'No pharmacies added yet. Please come back later.'
            : 'No pharmacies available based on the filters. Try some other filters.'}
        </Typography>
      ) : (
        <Container>
          <SortBy
            data={updatedPharmacies}
            setData={handleSort}
            sortingOptions={[
              {
                filterName: 'Name',
                attributeName: 'pharmacyName',
                type: 'string'
              },
              {
                filterName: 'Email',
                attributeName: 'facilityEmail',
                type: 'string'
              },
              {
                filterName: 'Address',
                attributeName: 'locationAddress1',
                type: 'string'
              },
              {
                filterName: 'County',
                attributeName: 'locationCounty',
                type: 'string'
              }
            ]}
            defaultSorting={{
              filterName: 'Name',
              attributeName: 'pharmacyName',
              type: 'string'
            }}
          />
          <Grid container spacing={4}>
            {Array.isArray(updatedPharmacies) &&
              updatedPharmacies
                .slice(
                  getPaginationSliceValueStart(),
                  getPaginationSliceValueEnd()
                )
                .map((pharmacy) => (
                  <PharmacyCard
                    key={pharmacy.recordId}
                    pharmacy={pharmacy}
                  />
                ))}
          </Grid>
        </Container>
      )}
    </>
  );
};

export default PharmacyContainer;
