export const ACTION_NAMES = {
  UPDATE_PHARMACY_LAYOUT: 'UPDATE_PHARMACY_LAYOUT',
  UPDATE_PHARMACY_LAYOUT_USER:
    'UPDATE_PHARMACY_LAYOUT_USER',
  LOAD_PHARMACIES: 'LOAD_PHARMACIES',
  LOAD_PHARMACIES_SUCCESS: 'LOAD_PHARMACIES_SUCCESS',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  ENABLE_ERROR: 'ENABLE_ERROR',
  DISABLE_ERROR: 'DISABLE_ERROR',
  UPDATE_PAGINATION: 'UPDATE_PAGINATION',
  LOAD_USER_PROFILE: 'LOAD_USER_PROFILE',
  LOAD_USER_PROFILE_SUCCESS: 'LOAD_USER_PROFILE_SUCCESS',
  LOG_OUT_USER_PROFILE: 'LOG_OUT_USER_PROFILE',
  LOAD_SERVICES: 'LOAD_SERVICES',
  LOAD_SERVICES_SUCCESS: 'LOAD_SERVICES_SUCCESS',
  LOAD_PHARMACIES_SERVICES_MAPPING:
    'LOAD_PHARMACIES_SERVICES_MAPPING',
  LOAD_PHARMACIES_SERVICES_MAPPING_SUCCESS:
    'LOAD_PHARMACIES_SERVICES_MAPPING_SUCCESS',
  LOAD_SERVICE_REQUESTS: 'LOAD_SERVICE_REQUESTS',
  LOAD_SERVICE_REQUESTS_SUCCESS:
    'LOAD_SERVICE_REQUESTS_SUCCESS',
  LOAD_FEEDBACKS: 'LOAD_FEEDBACKS',
  LOAD_FEEDBACKS_SUCCESS: 'LOAD_FEEDBACKS_SUCCESS',
  LOAD_ADMIN_USERS: 'LOAD_ADMIN_USERS',
  LOAD_ADMIN_USERS_SUCCESS: 'LOAD_ADMIN_USERS_SUCCESS',
  OPEN_SNACKBAR: 'OPEN_SNACKBAR',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR',
  LOAD_MAIL_LOGS: 'LOAD_MAIL_LOGS',
  LOAD_MAIL_LOGS_SUCCESS: 'LOAD_MAIL_LOGS_SUCCESS'
};
