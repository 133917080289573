import { ACTION_NAMES } from '../reduxActions';
import { getPharmacyServicesMappingApi } from '../../helpers/apiCalls';
import { DispatchType } from '../store';

export const loadPharmaciesServicesMapping = async (
  dispatch: DispatchType
) => {
  dispatch({
    type: ACTION_NAMES.LOAD_PHARMACIES_SERVICES_MAPPING
  });
  const result =
    await getPharmacyServicesMappingApi(dispatch);
  dispatch({
    type: ACTION_NAMES.LOAD_PHARMACIES_SERVICES_MAPPING_SUCCESS,
    payload: result
  });
};
