import { Grid, TextField, Typography } from '@mui/material';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import { useEffect } from 'react';
import { validateEmail } from '../../helpers/utils';
import { PharmacyCredentialDetailsProps } from '../../helpers/interfaces';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { USER_ROLES } from '../../helpers/constant';

const PharmacyCredentialDetails: React.FC<
  PharmacyCredentialDetailsProps
> = (
  props: PharmacyCredentialDetailsProps
): JSX.Element => {
  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  useEffect(() => {
    props.setWarning(
      props.pmpicName === '' ||
        validateEmail(props.facilityEmail) !== '' ||
        validateEmail(props.pmpicEmail) !== ''
    );
  }, [
    props.facilityEmail,
    props.pmpicName,
    props.pmpicEmail
  ]);

  return (
    <>
      {props.viewMode !== 'REVIEW' && (
        <Typography variant="h5" align="center">
          Add crediential details
        </Typography>
      )}
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Typography
            fontWeight="bold"
            align="left"
            variant="h5">
            Facility Details
          </Typography>
          {props.viewMode !== 'REVIEW' &&
            userState.userProfile &&
            [
              USER_ROLES.ADMIN,
              USER_ROLES.SUPERADMIN
            ].includes(userState.userProfile.role) && (
              <WarningComponentForIncorrectField message="Users can use existing credentials if the facility email is registered; otherwise, a temporary password will be sent to the provided email." />
            )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoComplete="given-facility-email"
            name="facility-email"
            required
            fullWidth
            label="Facility Email"
            value={props.facilityEmail}
            sx={{ marginBottom: '2%' }}
            onChange={(event) => {
              props.updatePharmacyObject({
                facilityEmail: event.target.value
              });
            }}
            disabled={
              props.viewMode === 'REVIEW' ||
              userState.userProfile === null ||
              userState.userProfile.role === USER_ROLES.USER
            }
            autoFocus
          />
          <WarningComponentForIncorrectField
            message={validateEmail(props.facilityEmail)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight="bold"
            align="left"
            variant="h5">
            PMPIC Details
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            label="PMPIC Name"
            name="pmpic-name"
            value={props.pmpicName}
            onChange={(event) => {
              props.updatePharmacyObject({
                pmpicName: event.target.value
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
          />
          <WarningComponentForIncorrectField
            message={
              props.pmpicName.trim() === ''
                ? 'PMPIC name cannot be empty'
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            label="PMPIC Email"
            name="pmpic-email"
            value={props.pmpicEmail}
            onChange={(event) => {
              props.updatePharmacyObject({
                pmpicEmail: event.target.value
              });
            }}
            disabled={props.viewMode === 'REVIEW'}
          />
          <WarningComponentForIncorrectField
            message={validateEmail(props.pmpicEmail)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PharmacyCredentialDetails;
