import { UserWithPharmacyObject } from '../../helpers/interfaces';
import { ACTION_NAMES } from '../reduxActions';
import { DispatchType } from '../store';

export const loadUserProfile = (
  dispatch: DispatchType,
  userProfileWithPharm: UserWithPharmacyObject
) => {
  dispatch({
    type: ACTION_NAMES.LOAD_USER_PROFILE_SUCCESS,
    payload: userProfileWithPharm
  });
  if (
    userProfileWithPharm &&
    userProfileWithPharm.userProfile
  ) {
    localStorage.setItem(
      'token',
      userProfileWithPharm.userProfile.token
    );
  }
};

export const logOutUserProfile = () => {
  localStorage.removeItem('token');
  return {
    type: ACTION_NAMES.LOG_OUT_USER_PROFILE
  };
};
