interface ServiceCatalogItem {
  description: string;
  levels: string[];
}

export const ServiceCatalogDescription: Record<
  number,
  ServiceCatalogItem
> = {
  1: {
    description:
      'As a Glucagon service provider, pharmacies dispense, deliver, or administer glucagon to eligible individuals in accordance with the guidelines set forth by the NC Medical Board and NC Board of Pharmacy Statewide Protocol for Glucagon',
    levels: [
      'Follow the statewide Protocol for Glucagon and provides glucagon to eligible candidates upon request',
      'Meet LEVEL I service provider standards. They also proactively assesses the diabetic population to determine their need for Glucagon at regularly scheduled intervals.'
    ]
  },
  2: {
    description:
      'As a birth control service provider, pharmacies affirm they provide hormonal contraceptives to eligible candidates in accordance with NC Medical Board and NC Board of Pharmacy Statewide Protocol for Oral and Transdermal Self-Administered Combined Hormonal and Progestin Only Contraceptives.',
    levels: [
      'Follow the statewide Protocol for Oral and Transdermal Self-Administered Combined Hormonal and Progestin-Only Contraceptives and provide approved products to eligible candidates upon request.',
      "Meet level I service provider standards. In addition, the pharmacy proactively screens women of childbearing age regarding their pregnancy intention at regular intervals. For those interested, pharmacists discuss contraceptive options and promote their pharmacy's birth control service."
    ]
  },
  3: {
    description:
      'As a long-acting Injectable service provider, trained pharmacy staff dispense and administer long-acting injectable medications according to SL 2021-110 and NCBOP guidelines. This service is for patients who are otherwise hesitant or unable to self-administer their own non-behavioral health injections.',
    levels: [
      'Follow SL 2021-110 and NC Board of Pharmacy guidelines for administering long-acting injectables. They offer this service upon patient request, monitor for injection site reactions and adverse effects, and schedule follow-up appointments. They also ensure that required documentation and provider communication meet SL 2021-110 standards.',
      'Meet LEVEL I service provider standards and make follow-up contacts with patients who fail to meet their appointments.'
    ]
  },
  7: {
    description:
      'As a long-acting antipsychotic injectable service provider, trained pharmacy staff dispense and administer long-acting antipsychotic injectable medications according to SL 2021-110 and NCBOP guidelines.',
    levels: [
      'Follow SL 2021-110 and NC Board of Pharmacy guidelines for administering long-acting injectables. They offer this service upon patient request, monitor for injection site reactions and adverse effects, and schedule follow-up appointments. They also ensure that required documentation and provider communication meet SL 2021-110 standards.',
      "Meet LEVEL I service provider standards, perform mental status checks at each visit, and communicate findings to the provider. They follow up with patients who miss appointments and monitor medication adherence in patients on oral therapy for schizophrenia. If non-adherent to oral therapy, they inform the prescriber of findings and provide recommendations to consider LAIA's as an alternative to improve relapse and further clinical deterioration"
    ]
  },
  8: {
    description:
      'As a long-acting injectable service provider of buprenorphine (Sublocade or Brixadi), the pharmacy confirms that it is REMS-certified to order, stock, dispense, and administer the product in the pharmacy or other allowed sites.',
    levels: [
      'Follow SL 2021-110 and NC Board of Pharmacy guidelines for administering long-acting injectables and are REMS certified to stock, order, dispense, and administer these products per REMS requirements. In addition, pharmacists check the CSRS before administration, monitor for injection site reactions and adverse effects, and schedule next appointments with the patient. Required documentation and provider communication are consistent with both REMS requirements and those standards outlined in SL2021-110.',
      'Meet LEVEL I service provider standards and make follow-up contact with patients who fail to make their injection appointments. In addition, they promote LAI services to local prescribers with recommendations to consider LAIs as a strategy to improve adherence, relapse/deterioration in those living with OUD.'
    ]
  },
  9: {
    description:
      "As a Prenatal Vitamin service provider, pharmacies affirm they provide prenatal vitamins to eligible candidates in accordance with the NC Medical Board and NC Board of Pharmacy's Statewide Protocol for multivitamins with folic acid.",
    levels: [
      'Follow the statewide Protocol for multivitamins with folic acid and provide approved products to eligible candidates upon request.',
      'Meet Level I service provider standards and proactively screen women of childbearing age regarding their pregnancy intentions by regularly asking, "Would you like to become pregnant in the next year?" For those who answer yes or believe they may already be pregnant, counseling is provided on the importance of folic acid supplementation, with recommendations to start supplementation at least one month prior to conception.'
    ]
  },
  10: {
    description:
      'As a Postexposure Prophylaxis (PEP) service provider, the pharmacy dispenses, delivers or administers PEP therapy to eligible candidates in accordance with the NC Medical Board and NC Board of Pharmacy Statewide Protocol for Postexposure Prophylaxis (PEP) for Human Immunodeficiency Virus (HIV).',
    levels: [
      'Follow the statewide Protocol for Post Exposure Prophylaxis (PEP) for Human Immunodeficiency Virus (HIV) and provides approved products to eligible candidates upon request.'
    ]
  },
  11: {
    description:
      'As a nicotine replacement service provider, the pharmacy dispenses, delivers, or administers nicotine replacement therapy to eligible candidates in accordance with NC Medical Board and NC Board of Pharmacy statewide Protocol for Nicotine Replacement Therapy',
    levels: [
      'Adhere to the statewide Protocol for Nicotine Replacement therapy (NRT) and provide approved products to eligible candidates upon request',
      "Meet level I service provider standards and proactively screen and access patients' smoking status using the 5-A's method. For patients actively quitting, evaluate medications affected by CYP 1A2 induction reversal and contact the provider for dosage adjustments if needed. For patients unsuitable for NRT, reach out to providers for alternative treatments."
    ]
  },
  12: {
    description:
      'As a Clozapine service provider, pharmacies affirm they are Clozapine REMS certified to receive and dispense clozapine.',
    levels: [
      'Ensure they are Clozapine REMS certified and stock various Clozapine strengths to meet patient needs and prevent therapy delays. Pharmacists regularly educate patients and caregivers about side effects and provide ongoing monitoring.',
      "Meet LEVEL I service provider standards. Additionally, pharmacists conduct a mental status check and smoking assessment at each visit, sharing results with patients' providers and recommending dose adjustments as needed. Staff follow up with patients who miss medication pickups and contact the patient's provider within 48 hours of any missed appointment."
    ]
  },
  13: {
    description:
      "Pharmacies dispense opioid antagonists per the NC State Health Director's Opioid Antagonists Standing Order for Pharmacists and, at a minimum, stocks one or more opioid antagonists from the list of approved products supplement to the same order.",
    levels: [
      "Stock and dispense opioid antagonists under the NC State Health Director's Opioid Antagonists Standing Order to anyone upon request. The pharmacy offers a stigma-free environment and provides education and counseling regarding risk factors for overdose, the signs of an overdose, and how to use their opioid antagonist appropriately.",
      "Meet level I provider service standards. Additionally, these pharmacies assess their patient population on opioids for the risk of overdose or serious respiratory depression. For at-risk patients, a stigma-free environment is maintained to proactively provide education and counseling, ensuring patients understand their identified risks and the benefits of carrying an opioid antagonist. Assessment, recommendations/actions are documented and shared with the patient's provider to ensure care coordination and improve patient safety."
    ]
  },
  14: {
    description:
      "Providing Fentanyl Test Strips at the pharmacy reduces overdoses and deaths. People who know their drug contents are more likely to practice safer drug use. As a provider of Fentanyl Test Strips, pharmacy's maintain a supply as part of a strategy to reduce fentanyl-related overdose deaths.",
    levels: [
      'Confirm they order and stock Fentanyl Test Strips. Strips are easily accessible in the pharmacy and have clear instructions for testing different drug supplies. Staff are also well-trained and offer stigma-free counseling on strip use if needed.',
      'Meet Level | service provider standards. Additionally, their pharmacy partners with local harm reduction groups, public health officials, and law enforcement to educate the community on accessing and using test strips for safety and harm reduction among at-risk populations.'
    ]
  },
  15: {
    description:
      'Providing Xylazine test strips in the pharmacy helps reduce overdoses and deaths caused by Xylazine. When individuals are aware of the substances in their drug supply, they are more likely to engage in safer drug use behaviors to prevent overdose. As a provider of Xylazine test strips, the pharmacy maintains a steady supply and is dedicated to reducing overdose deaths associated with Xylazine.',
    levels: [
      'Orders and maintains a stock of Xylazine test strips, which are prominently displayed in an easily accessible location. The test strips include take-home instructions for testing various drug supplies. Additionally, the staff is well-trained to offer a stigma-free environment, providing counseling to customers on the appropriate use of these strips when needed.',
      'Meet Level I service provider standards and collaborate with local harm reduction groups, public health officials, and law enforcement to educate the community on accessing and using test strips for safety and harm reduction among at-risk populations.'
    ]
  },
  16: {
    description:
      'As a buprenorphine service provider, pharmacies order, stock, and dispense buprenorphine for Opioid Use Disorder. They communicate with wholesalers to prevent inventory issues and have well-trained staff to ensure a stigma-free environment. Additionally, they confirm that patients have Naloxone available during each visit in case of an overdose emergency.',
    levels: [
      'May have restrictions or policies affecting dispensing, but still carry buprenorphine and strive to meet patient needs to avoid therapy gaps.',
      'Confirm no restrictions or policies preventing individuals from filling buprenorphine prescriptions at their location.',
      'Meet Level II service provider standards and collaborate with prescribers to co-manage patients with OUD, monitoring adherence, withdrawal, and potential relapse. They document findings and share them with the prescriber for coordinated care.'
    ]
  },
  17: {
    description:
      'The pharmacy acknowledges the link between injection drug use and infections like (HCV, HIV, endocarditis, etc.) along with the associated high treatment costs. Recognizing that providing access to sterile, unused syringes and needles is one of the most effective ways to ensure patient safety, reduce disease burden, and improve healthcare costs, the pharmacy fully supports this practice. The pharmacy is aware of North Carolina laws permitting the sale of syringes without a prescription, a stance supported by the NC Board of Pharmacy and the American Pharmacists Association. Employees are also well-trained to provide a judgment-free environment where individuals can purchase syringes without a prescription, free from questions or restrictions.',
    levels: [
      'Have employees who are well-trained to create a stigma-free environment where anyone can easily request or purchase syringes without a prescription, regardless of their intended use, and without facing questions or restrictions.',
      "Meet level I service provider standards and provide information (brochures, business cards, flyers, etc.) with each syringe purchased that directs clients to local Syringe Service Programs to access other harm reduction services and explore treatment options for opioid use disorder when they're ready.",
      'Meet level I service provider standards and serves as an extension site of a local Syringe Service Program (SSP) offering free safe use supplies and naloxone. The partnership facilitates a co-location process, referring clients from the SSP to the pharmacy and vice versa, especially when assistance with testing for HIV, HCV, or treatment options is needed for the client.'
    ]
  },
  18: {
    description:
      'As a Immunization Service Provider, pharmacies affirm they provide immunization administration to eligible candidates following ACIP guidelines.',
    levels: [
      'Ensure certified immunizers are on staff to provide immunization administration in accordance with state law and ACIP guidelines. In addition, the pharmacy regularly checks their patient population for vaccination requirements, providing counseling and encouraging immunizations when needed.'
    ]
  },
  19: {
    description:
      'As a compounding pharmacy service provider, pharmacies affirm they hold a permit from the NC Board of Pharmacy to engage in compounding activities and that they incorporate applicable USP chapter standards governing the preparation of non-sterile and sterile compounding products.',
    levels: [
      'Confirm that they hold an NC Board of Pharmacy permit to provide compounding services and follow USP chapter standards governing compounded product preparation.'
    ]
  },
  20: {
    description:
      'Pharmacies offering unit dose packaging help patients organize medications by date and time. Pharmacists work with patients, caregivers, and prescribers to find the best packaging solutions, such as bubble packs, medication strips, or automated planners. They ensure medication information and dosing regimens stay up-to-date through close collaboration with all parties.',
    levels: [
      "Offer unit dose adherence packaging to help patients and caregivers organize medication regimens. The pharmacists actively collaborate with patients, caregivers, and prescribers to identify the most suitable adherence packaging system tailored to meet the patient's specific needs. Additionally, the pharmacy ensures that dosing regimens undergo regular reviews to guarantee the accuracy and currency of medication information."
    ]
  },
  21: {
    description:
      'The pharmacy helps patients dealing with multiple chronic conditions or taking several medication coordinate their medication refills, ensuring that their chronic medications are dispensed at the same time each month, simplifying the management process',
    levels: [
      'The pharmacy provides medication synchronization services to patients who are managing multiple chronic conditions or taking several medications. The pharmacy collaborates with patients to align the timing of their prescription refills. Additionally, the pharmacy proactively reaches out to patients before each refill cycle to monitor their adherences to medication and assess whether there have been any changes to their therapy between refill cycles.'
    ]
  },
  22: {
    description:
      "Pharmacies deliver patients' prescription medications to their homes.",
    levels: [
      "The pharmacy offers the delivery of patients' prescription medications directly to their homes or residences."
    ]
  }
};
