import { ErrorProps } from '../../helpers/interfaces';

const Error: React.FC<ErrorProps> = (
  props: ErrorProps
): JSX.Element => {
  return (
    <div
      style={{
        color: 'red',
        border: '1px solid red',
        padding: '10px',
        borderRadius: '4px',
        margin: '10px 0'
      }}>
      <strong>Error:</strong> {props.message}
    </div>
  );
};

export default Error;
