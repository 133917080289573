import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { closeSnackbar } from '../../redux/snackbar/snackbarActions';

const SnackbarComponent: React.FC = (): JSX.Element => {
  const snackbarState = useSelector(
    (state: RootStateType) => state.snackbar
  );

  const dispatch = useDispatch();

  return (
    <Snackbar
      open={snackbarState.open}
      autoHideDuration={6000}
      onClose={() => {
        dispatch(closeSnackbar());
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
      <Alert
        onClose={() => {
          dispatch(closeSnackbar());
        }}
        severity="success"
        sx={{ width: '100%' }}>
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
