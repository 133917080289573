import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SendIcon from '@mui/icons-material/Send';
import {
  updateFeedback,
  replyFeedback
} from '../../redux/feedbacks/feedbacksActions';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';
import { FEEDBACK_STATUS } from '../../helpers/constant';
import {
  getDateString,
  validateEmail
} from '../../helpers/utils';
import {
  AdminUserObject,
  FeedbackObject
} from '../../helpers/interfaces';
import { getAdminUsersApi } from '../../helpers/apiCalls';
import SortBy from '../../sharedComponents/SortBy/SortBy';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import CircleIcon from '@mui/icons-material/Circle';

const ViewFeedback: React.FC = (): JSX.Element => {
  const feedbacksState = useSelector(
    (state: RootStateType) => state.feedbacks
  );

  const [filteredFeedbacks, setFilteredFeedbacks] =
    useState<FeedbackObject[]>([]);

  const [adminUsers, setAdminUsers] = useState<
    AdminUserObject[]
  >([]);

  const [expandedFeedback, setExpandedFeedback] =
    useState<number>(-1);

  const [selectedStatus, setSelectedStatus] =
    useState<string>('');

  const [selectedComment, setSelectedComment] =
    useState<string>('');

  const [selectedAssignedTo, setSelectedAssignedTo] =
    useState<string>('');

  const [includeDisclaimer, setIncludeDisclaimer] =
    useState<boolean>(false);

  const [replyToAddress, setReplyToAddress] =
    useState<string>('cheryl@ncpharmacists.org');

  const [emailErrorMessage, setEmailErrorMessage] =
    useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    const result = feedbacksState.feedbacks.filter((fd) =>
      [
        FEEDBACK_STATUS.OPEN,
        FEEDBACK_STATUS.IN_PROGRESS
      ].includes(fd.status)
    );

    setFilteredFeedbacks(result);
  }, [feedbacksState.feedbacks]);

  useEffect(() => {
    const fetchAdminUsers = async () => {
      const result = await getAdminUsersApi(dispatch);
      setAdminUsers(result);
    };
    fetchAdminUsers();
  }, []);

  useEffect(() => {
    if (includeDisclaimer) {
      setEmailErrorMessage(validateEmail(replyToAddress));
    } else {
      setEmailErrorMessage('');
    }
  }, [replyToAddress, includeDisclaimer]);

  const getStatusColor = (status: string): string => {
    switch (status) {
      case FEEDBACK_STATUS.OPEN:
        return '#FF5733';
      case FEEDBACK_STATUS.IN_PROGRESS:
        return '#FFD700';
      case FEEDBACK_STATUS.RESOLVED:
        return '#32CD32';
      case FEEDBACK_STATUS.REVIEWED:
        return '#808080';
      default:
        return '#87CEEB';
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDataSort = (data: any) => {
    setFilteredFeedbacks(data as FeedbackObject[]);
  };

  const handleUpdate = async (
    event: React.MouseEvent<HTMLButtonElement>,
    feedbackId: number
  ) => {
    try {
      await updateFeedback(
        dispatch,
        feedbackId,
        selectedStatus,
        selectedComment,
        selectedAssignedTo
      );
      dispatch(
        openSnackbar(
          `Feedback updated successfully`,
          'success'
        )
      );
      setExpandedFeedback(-1);
    } catch (e) {
      console.log('Error: ', e);
      dispatch(
        openSnackbar(
          'Feedback could not be updated at this moment. Please try again later.',
          'error'
        )
      );
    }
  };

  const handleReply = async (
    event: React.MouseEvent<HTMLButtonElement>,
    feedbackId: number
  ) => {
    try {
      await replyFeedback(
        dispatch,
        feedbackId,
        selectedComment,
        includeDisclaimer,
        replyToAddress
      );
      dispatch(
        openSnackbar('Reply sent successfully', 'success')
      );
      setExpandedFeedback(-1);
    } catch (e) {
      console.log('Error: ', e);
      dispatch(
        openSnackbar(
          'Reply could not be sent at this moment. Please try again later.',
          'error'
        )
      );
    }
  };

  return (
    <>
      <Paper>
        <h2>Pending Feedbacks</h2>
        {filteredFeedbacks &&
        filteredFeedbacks.length > 0 ? (
          <Grid container component={Paper} spacing={1}>
            <SortBy
              data={filteredFeedbacks}
              setData={handleDataSort}
              sortingOptions={[
                {
                  filterName: 'Received Date',
                  attributeName: 'dateCreated',
                  type: 'date'
                },
                {
                  filterName: 'Topic',
                  attributeName: 'topic',
                  type: 'string'
                },
                {
                  filterName: 'Feedback from',
                  attributeName: 'email',
                  type: 'string'
                }
              ]}
              defaultSorting={{
                filterName: 'Received Date',
                attributeName: 'dateCreated',
                type: 'date'
              }}
            />
            {filteredFeedbacks.map((feedback) => {
              return (
                <Grid
                  item
                  key={feedback.feedbackId}
                  xs={12}
                  mb={2}
                  style={{
                    border: '1px solid #cc',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
                    padding: '2px'
                  }}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={8} display="flex">
                          <CircleIcon
                            fontSize="small"
                            style={{
                              marginRight: '4px',
                              marginTop: '4px',
                              color: getStatusColor(
                                feedback.status
                              )
                            }}
                          />
                          <Typography
                            align="left"
                            variant="h6"
                            gutterBottom>
                            {feedback.topic}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} display="flex">
                          <Typography
                            align="left"
                            variant="body2"
                            color="textSecondary"
                            gutterBottom>
                            Feedback from:
                          </Typography>
                          <Typography
                            align="left"
                            variant="body2"
                            ml={1}
                            gutterBottom>
                            {feedback.email}
                          </Typography>
                        </Grid>
                        <Grid item xs={8} display="flex">
                          <Typography
                            align="left"
                            variant="body2"
                            color="textSecondary"
                            gutterBottom>
                            Description:
                          </Typography>
                          <Typography
                            align="left"
                            variant="body2"
                            ml={1}
                            gutterBottom>
                            {feedback.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} display="flex">
                          <Typography
                            align="left"
                            variant="body2"
                            color="textSecondary"
                            gutterBottom>
                            Received On:
                          </Typography>
                          <Typography
                            align="left"
                            variant="body2"
                            ml={1}
                            gutterBottom>
                            {getDateString(
                              feedback.dateCreated
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() => {
                              if (
                                expandedFeedback ===
                                feedback.feedbackId
                              ) {
                                setExpandedFeedback(-1);
                              } else {
                                setExpandedFeedback(
                                  feedback.feedbackId
                                );
                                setSelectedStatus(
                                  feedback.status
                                );
                                setSelectedComment(
                                  feedback.comment
                                );
                                setSelectedAssignedTo(
                                  feedback.assignedTo
                                );
                                setIncludeDisclaimer(false);
                                setReplyToAddress(
                                  'cheryl@ncpharmacists.org'
                                );
                              }
                            }}>
                            {expandedFeedback &&
                            expandedFeedback ===
                              feedback.feedbackId ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </Grid>
                        {expandedFeedback &&
                          expandedFeedback ===
                            feedback.feedbackId && (
                            <>
                              <Grid item xs={3}>
                                <Typography
                                  align="left"
                                  color="textSecondary"
                                  mb={1}>
                                  Status:
                                </Typography>
                                <Select
                                  value={selectedStatus}
                                  onChange={(event) => {
                                    setSelectedStatus(
                                      event.target.value
                                    );
                                  }}
                                  fullWidth
                                  style={{
                                    textAlign: 'left'
                                  }}>
                                  {Object.keys(
                                    FEEDBACK_STATUS
                                  ).map((key) => {
                                    const value =
                                      FEEDBACK_STATUS[key];
                                    return (
                                      <MenuItem
                                        key={value}
                                        value={value}>
                                        <CircleIcon
                                          fontSize="small"
                                          style={{
                                            marginRight:
                                              '4px',
                                            marginBottom:
                                              '-3px',
                                            color:
                                              getStatusColor(
                                                value
                                              )
                                          }}
                                        />
                                        {value}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </Grid>
                              <Grid item xs={1}></Grid>
                              <Grid item xs={3}>
                                <Typography
                                  align="left"
                                  color="textSecondary"
                                  mb={1}>
                                  Assigned to:
                                </Typography>
                                <Select
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left'
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left'
                                    }
                                  }}
                                  value={selectedAssignedTo}
                                  onChange={(event) => {
                                    setSelectedAssignedTo(
                                      event.target.value
                                    );
                                  }}
                                  fullWidth
                                  style={{
                                    textAlign: 'left'
                                  }}>
                                  <MenuItem
                                    key="Unassigned"
                                    value="Unassigned">
                                    Unassigned
                                  </MenuItem>
                                  {adminUsers.map(
                                    (admin) => {
                                      return (
                                        <MenuItem
                                          key={admin.userId}
                                          value={`${admin.name} (${admin.email})`}>
                                          {`${admin.name} (${admin.email})`}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </Grid>
                              <Grid item xs={1}></Grid>
                              <Grid
                                item
                                xs={3}
                                display="flex">
                                <Typography
                                  align="left"
                                  variant="body2"
                                  color="textSecondary"
                                  gutterBottom>
                                  Updated On:
                                </Typography>
                                <Typography
                                  align="left"
                                  variant="body2"
                                  ml={1}
                                  gutterBottom>
                                  {getDateString(
                                    feedback.dateUpdated
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={11.8} mt={2}>
                                <Typography
                                  align="left"
                                  color="textSecondary"
                                  mb={1}>
                                  Comments:
                                </Typography>
                                <TextareaAutosize
                                  required
                                  minRows={3}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'inherit',
                                    fontSize: 17,
                                    padding: '8px',
                                    border:
                                      '1px solid #ccc',
                                    borderRadius: '4px',
                                    resize: 'vertical'
                                  }}
                                  value={selectedComment}
                                  onChange={(event) => {
                                    setSelectedComment(
                                      event.target.value
                                    );
                                  }}
                                />
                              </Grid>
                              {feedback.email !==
                                'anonymous' && (
                                <>
                                  <Grid item xs={12} mt={1}>
                                    <Box
                                      display="flex"
                                      alignItems="left">
                                      <FormControl>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={
                                                includeDisclaimer
                                              }
                                              onChange={() => {
                                                setIncludeDisclaimer(
                                                  !includeDisclaimer
                                                );
                                              }}
                                              name="includeDisclaimer"
                                            />
                                          }
                                          label="Include disclaimer"
                                        />
                                      </FormControl>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      align="left"
                                      color="textSecondary"
                                      mb={1}>
                                      Reply-to address:
                                    </Typography>
                                    <TextField
                                      required
                                      variant="outlined"
                                      disabled={
                                        !includeDisclaimer
                                      }
                                      style={{
                                        width: '40%',
                                        float: 'left'
                                      }}
                                      value={replyToAddress}
                                      onChange={(event) => {
                                        setReplyToAddress(
                                          event.target.value
                                        );
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} mt={1}>
                                    <WarningComponentForIncorrectField
                                      message={
                                        emailErrorMessage
                                      }
                                    />
                                  </Grid>
                                </>
                              )}
                              <Grid
                                container
                                justifyContent="flex-end"
                                mt={1}
                                spacing={2}>
                                {feedback.email ===
                                'anonymous' ? (
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={(event) => {
                                        handleUpdate(
                                          event,
                                          feedback.feedbackId
                                        );
                                      }}>
                                      Save Changes
                                    </Button>
                                  </Grid>
                                ) : (
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      disabled={
                                        selectedComment ===
                                          '' ||
                                        (includeDisclaimer &&
                                          emailErrorMessage !==
                                            '')
                                      }
                                      onClick={(event) => {
                                        handleReply(
                                          event,
                                          feedback.feedbackId
                                        );
                                      }}>
                                      Reply
                                      <SendIcon
                                        style={{
                                          marginLeft: 5
                                        }}
                                      />
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </>
                          )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography fontSize={20} fontStyle="italic">
            No pending feedbacks at this time.
          </Typography>
        )}
      </Paper>
    </>
  );
};

export default ViewFeedback;
