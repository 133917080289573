import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ServiceItemListProps } from '../../helpers/interfaces';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { removeService } from '../../redux/services/servicesActions';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';

const ServiceItemList: React.FC<ServiceItemListProps> = (
  props: ServiceItemListProps
): JSX.Element => {
  const dispatch = useDispatch();
  return (
    <List
      dense={false}
      sx={{ paddingLeft: '5%', paddingRight: '5%' }}>
      {props.serviceList.map((service) => {
        return (
          <ListItem
            key={service.serviceId}
            sx={{
              border: '1px solid #808080',
              boxShadow: '2px 2px #808080',
              marginBottom: '2%'
            }}
            secondaryAction={[
              <Tooltip
                title="Edit service information"
                key={1}>
                <IconButton
                  edge="end"
                  aria-label="update"
                  onClick={() =>
                    props.openServiceSettingsModal(service)
                  }>
                  <EditIcon fontSize="large" />
                </IconButton>
              </Tooltip>,
              <Tooltip title="Delete service" key={2}>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={async () => {
                    await removeService(
                      dispatch,
                      service.serviceId
                    );
                    dispatch(
                      openSnackbar(
                        'Service deleted!',
                        'error'
                      )
                    );
                  }}>
                  <DeleteForeverIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            ]}>
            <ListItemText
              primary={
                <Typography fontWeight="bold">
                  {service.serviceName}
                </Typography>
              }
              secondary={
                <Typography
                  fontStyle="italic"
                  color="gray"
                  mr={4}>
                  {service.serviceDefinition}
                </Typography>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default ServiceItemList;
