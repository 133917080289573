import {
  Container,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { useEffect, useState } from 'react';
import ServiceItemList from '../../components/ServiceItemList/ServiceItemList';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import AddServiceModal from '../AddServiceModal/AddServiceModal';
import { loadServices } from '../../redux/services/servicesActions';
import Error from '../../components/Error/Error';
import { DATA_LOAD_STATUS } from '../../helpers/constant';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { ServiceObject } from '../../helpers/interfaces';

const ServicesContainer: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const servicesState = useSelector(
    (state: RootStateType) => state.services
  );

  const errorState = useSelector(
    (state: RootStateType) => state.error
  );

  useEffect(() => {
    loadServices(dispatch);
  }, []);

  const [displayModal, setDisplayModal] =
    useState<boolean>(false);

  const [selectedService, setSelectedService] =
    useState<ServiceObject | null>(null);

  const openServiceSettings = (
    service: ServiceObject | null
  ) => {
    setSelectedService(service);
    setDisplayModal(true);
  };

  const closeServiceSettings = () => {
    setSelectedService(null);
    setDisplayModal(false);
  };

  return (
    <>
      {errorState.enableError && (
        <Error message={errorState.message} />
      )}
      {!errorState.enableError &&
        servicesState.status ===
          DATA_LOAD_STATUS.STATUS_LOADING && (
          <LoadingSpinner />
        )}
      {!errorState.enableError &&
        servicesState.status ===
          DATA_LOAD_STATUS.STATUS_DONE && (
          <>
            <Typography variant="h5" mt={2}>
              Services Offered by Pharmacies
            </Typography>
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                minWidth: '100%'
              }}>
              <Tooltip title="Add new service">
                <IconButton
                  edge={'end'}
                  sx={{ paddingRight: '2%' }}
                  onClick={() => {
                    openServiceSettings(null);
                  }}>
                  <AddBoxTwoToneIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Container>
            {servicesState.servicesList.length === 0 ? (
              <Typography
                variant="h5"
                component="h2"
                mt={2}>
                No services added yet. Use the ADD button to
                add new services.
              </Typography>
            ) : (
              <>
                <ServiceItemList
                  serviceList={servicesState.servicesList}
                  openServiceSettingsModal={
                    openServiceSettings
                  }
                  closeServiceSettingsModal={
                    closeServiceSettings
                  }
                />
              </>
            )}
            {displayModal && (
              <AddServiceModal
                isModalOpen={displayModal}
                setIsModalOpen={setDisplayModal}
                selectedService={selectedService}
              />
            )}
          </>
        )}
    </>
  );
};

export default ServicesContainer;
