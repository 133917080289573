import {
  VIEW_MODE,
  PHARMACY_LAYOUT_USER
} from '../../helpers/constant';
import { ACTION_NAMES } from '../reduxActions';

const initialState = {
  mode: VIEW_MODE.LIST_VIEW,
  user: PHARMACY_LAYOUT_USER.PATIENT
};

export const pharmaciesLayoutReducer = (
  state = initialState,
  action: {
    type: string;
    payload?: string;
  }
) => {
  switch (action.type) {
    case ACTION_NAMES.UPDATE_PHARMACY_LAYOUT:
      return {
        ...state,
        mode:
          state.mode === VIEW_MODE.LIST_VIEW
            ? VIEW_MODE.GRID_VIEW
            : VIEW_MODE.LIST_VIEW
      };
    case ACTION_NAMES.UPDATE_PHARMACY_LAYOUT_USER:
      return {
        ...state,
        user: action.payload as string
      };
    default:
      return state;
  }
};
