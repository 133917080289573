import { DATA_LOAD_STATUS } from '../../helpers/constant';
import { AdminUserObject } from '../../helpers/interfaces';
import { ACTION_NAMES } from '../reduxActions';

const initialState = {
  status: DATA_LOAD_STATUS.STATUS_DONE,
  adminUsers: [] as AdminUserObject[]
};

export const adminUserReducer = (
  state = initialState,
  action: {
    type: string;
    payload: AdminUserObject[] | string;
  }
) => {
  switch (action.type) {
    case ACTION_NAMES.LOAD_ADMIN_USERS:
      return {
        ...state,
        status: DATA_LOAD_STATUS.STATUS_LOADING,
        adminUsers: [] as AdminUserObject[]
      };
    case ACTION_NAMES.LOAD_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        status: DATA_LOAD_STATUS.STATUS_DONE,
        adminUsers: action.payload as AdminUserObject[]
      };
    default:
      return state;
  }
};
