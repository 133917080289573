import {
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { useEffect, useState } from 'react';
import { loadServices } from '../../redux/services/servicesActions';
import Error from '../../components/Error/Error';
import {
  DATA_LOAD_STATUS,
  SERVICE_QUESTION_TYPES,
  USER_ROLES
} from '../../helpers/constant';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import EditIcon from '@mui/icons-material/Edit';
import {
  PharmacyServicesProps,
  ServiceObject
} from '../../helpers/interfaces';
import ConfigurePharmacyServiceModal from '../../views/ConfigurePharmacyServiceModal/ConfigurePharmacyServiceModal';
import { getSupplementalQuestions } from '../../helpers/utils';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const PharmacyServices: React.FC<PharmacyServicesProps> = (
  props: PharmacyServicesProps
): JSX.Element => {
  const dispatch = useDispatch();
  const servicesState = useSelector(
    (state: RootStateType) => state.services
  );

  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  const pharmaciesServicesMappingState = useSelector(
    (state: RootStateType) =>
      state.pharmaciesServicesMapping
  );

  const errorState = useSelector(
    (state: RootStateType) => state.error
  );

  const [editAccess, setEditAccess] =
    useState<boolean>(false);
  useEffect(() => {
    setEditAccess(
      (userState.userProfile !== null &&
        [USER_ROLES.ADMIN, USER_ROLES.SUPERADMIN].includes(
          userState.userProfile.role
        )) ||
        (userState.myPharmacy !== null &&
          userState.myPharmacy.length > 0 &&
          userState.myPharmacy[0].recordId ===
            props.pharmacyRecordId)
    );
  }, [userState, props.pharmacyRecordId]);

  useEffect(() => {
    loadServices(dispatch);
  }, []);

  const [selectedService, setSelectedService] =
    useState<ServiceObject | null>(null);

  const [
    openConfigurationModal,
    setOpenConfigurationModal
  ] = useState<boolean>(false);

  const handleCloseModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setSelectedService(null);
  };

  useEffect(() => {
    setOpenConfigurationModal(selectedService !== null);
  }, [selectedService]);

  return (
    <>
      {errorState.enableError && (
        <Error message={errorState.message} />
      )}
      {!errorState.enableError &&
        servicesState.status ===
          DATA_LOAD_STATUS.STATUS_LOADING && (
          <LoadingSpinner />
        )}
      {!errorState.enableError &&
        servicesState.status ===
          DATA_LOAD_STATUS.STATUS_DONE && (
          <>
            {servicesState.servicesList.length === 0 ? (
              <Typography
                variant="h6"
                component="h2"
                mt={2}>
                No services added yet. Wait for North
                Carolina Association of Pharmacists to add
                services.
              </Typography>
            ) : (
              <>
                <Grid container>
                  {servicesState.servicesList.map(
                    (service) => {
                      return (
                        <Grid
                          container
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          spacing={0}
                          key={service.serviceId}>
                          <Grid
                            item
                            xs={2}
                            sm={1}
                            md={0.5}
                            mt={1}>
                            {editAccess && (
                              <Tooltip title="Configure this service">
                                <IconButton
                                  edge="end"
                                  color="primary"
                                  sx={{ marginTop: 0.4 }}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setSelectedService(
                                      service
                                    );
                                  }}>
                                  <EditIcon fontSize="large" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sm={1}
                            md={0.5}
                            mt={1}>
                            {pharmaciesServicesMappingState.pharmaciesAndServicesList &&
                            pharmaciesServicesMappingState.pharmaciesAndServicesList.filter(
                              (mapping) =>
                                mapping?.questionType ===
                                  SERVICE_QUESTION_TYPES.PROVIDER &&
                                mapping.pharmacyRecordId ===
                                  props.pharmacyRecordId &&
                                mapping.serviceId ===
                                  service.serviceId &&
                                mapping.response !== 'None'
                            ).length > 0 ? (
                              <CheckCircleOutlinedIcon
                                color="success"
                                fontSize="large"
                                sx={{
                                  marginLeft: 1,
                                  marginTop: 1.3
                                }}
                              />
                            ) : (
                              <RadioButtonUncheckedOutlinedIcon
                                fontSize="large"
                                sx={{
                                  marginLeft: 1,
                                  marginTop: 1.3
                                }}
                              />
                            )}
                          </Grid>
                          <Grid item xs={8}>
                            <ListItem
                              sx={{
                                marginTop: '1%'
                              }}>
                              <ListItemText
                                primary={
                                  <Typography
                                    align="left"
                                    variant="h6">
                                    {service.serviceName}{' '}
                                    Service
                                  </Typography>
                                }
                                secondary={getSupplementalQuestions(
                                  service
                                ).map((suppService) => {
                                  const response =
                                    pharmaciesServicesMappingState.pharmaciesAndServicesList.filter(
                                      (ps) =>
                                        ps?.pharmacyRecordId ===
                                          props.pharmacyRecordId &&
                                        ps.serviceQuestionId ===
                                          suppService.serviceQuestionId &&
                                        (ps.questionType ===
                                          SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL ||
                                          (ps.questionType ===
                                            SERVICE_QUESTION_TYPES.SUPPLEMENTAL &&
                                            ps.response ===
                                              'Yes'))
                                    )[0]?.response;
                                  if (!response) {
                                    return null;
                                  }
                                  return (
                                    <Typography
                                      key={
                                        suppService.serviceQuestionId
                                      }
                                      fontSize="medium">
                                      {`${
                                        suppService.serviceQuestion
                                      }: ${
                                        response || 'N/A'
                                      }`}
                                    </Typography>
                                  );
                                })}
                              />
                            </ListItem>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
                {selectedService &&
                  pharmaciesServicesMappingState.pharmaciesAndServicesList && (
                    <ConfigurePharmacyServiceModal
                      pharmacyRecordId={
                        props.pharmacyRecordId
                      }
                      service={selectedService}
                      isModalOpen={openConfigurationModal}
                      handleClose={handleCloseModal}
                    />
                  )}
              </>
            )}
          </>
        )}
    </>
  );
};

export default PharmacyServices;
