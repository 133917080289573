import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // TextField,
  CircularProgress,
  Typography,
  TextField
} from '@mui/material';
import { UpdateAdminModalProps } from '../../helpers/interfaces';
import {
  createAdminUser,
  updateAdmin
} from '../../redux/adminUsers/adminUserActions';
import { useDispatch } from 'react-redux';
import {
  validateEmail,
  validateUsername
} from '../../helpers/utils';
import WarningComponentForIncorrectField from '../../sharedComponents/WarningComponentForIncorrectField/WarningComponentForIncorrectField';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';

const UpdateAdminModal: React.FC<UpdateAdminModalProps> = (
  props: UpdateAdminModalProps
) => {
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const [newName, setNewName] = useState<string>('');
  const [newNameMessage, setNewNameMessage] =
    useState<string>('');

  const [newEmail, setNewEmail] = useState<string>('');
  const [newEmailMessage, setNewEmailMessage] =
    useState<string>('');

  useEffect(() => {
    setNewNameMessage(validateUsername(newName));
  }, [newName]);

  useEffect(() => {
    setNewEmailMessage(validateEmail(newEmail));
  }, [newEmail]);

  const getSnackbarSuccessMessage = () => {
    switch (props.action) {
      case 'UPDATE NAME':
        return 'Admin name updated successfully';
      case 'ASSIGN NEW PASSWORD':
        return 'New password sent to the admin successfully';
      case 'DELETE ADMIN':
        return 'Admin deleted successfully';
      case 'CREATE NEW ADMIN':
        return 'New admin user created successfully';
      default:
        return '';
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      if (
        props.admin &&
        [
          'UPDATE NAME',
          'ASSIGN NEW PASSWORD',
          'DELETE ADMIN'
        ].includes(props.action)
      ) {
        await updateAdmin(
          dispatch,
          props.superAdminEmail,
          props.admin.userId,
          props.admin.email,
          newName,
          props.action
        );
      } else if (props.action === 'CREATE NEW ADMIN') {
        await createAdminUser(dispatch, newName, newEmail);
        dispatch(
          openSnackbar(
            getSnackbarSuccessMessage(),
            'success'
          )
        );
      }
    } catch (er) {
      dispatch(
        openSnackbar(
          `Could not perform the operation at this time. Please try again later.`,
          'error'
        )
      );
    }
    setLoading(false);
    props.setOpenModal(false);
    setNewName('');
    setNewEmail('');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={props.openModal}
      onClose={() => {
        props.setOpenModal(false);
        setNewName('');
        setNewEmail('');
      }}>
      <DialogTitle>
        <Typography variant="h5" fontWeight="bold">
          {props.action
            .split(' ')
            .map(
              (w) => w.charAt(0) + w.slice(1).toLowerCase()
            )
            .join(' ')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {props.admin && props.action === 'UPDATE NAME' && (
          <>
            <Typography variant="body1">
              Current admin name: {props.admin.name}
            </Typography>
            <TextField
              label="New name"
              name="newname"
              value={newName}
              onChange={(event) => {
                setNewName(event.target.value);
              }}
              fullWidth
              margin="normal"
              key="name"
            />
            <WarningComponentForIncorrectField
              message={newNameMessage}
            />
            <Typography variant="body1">
              {`Are you sure you want to update this admin's
              name?`}
            </Typography>
          </>
        )}
        {props.admin &&
          props.action === 'ASSIGN NEW PASSWORD' && (
            <>
              <Typography variant="body1">
                Are you sure you want to assign a new
                password to Admin - {props.admin.name} (
                {props.admin.email})
              </Typography>
            </>
          )}
        {props.admin && props.action === 'DELETE ADMIN' && (
          <>
            <Typography variant="body1">
              Are you sure you want to delete the account
              for Admin - {props.admin.name} (
              {props.admin.email})
            </Typography>
            <Typography variant="body1">
              Once deleted, the user would not have admin
              access.
            </Typography>
          </>
        )}
        {props.action === 'CREATE NEW ADMIN' && (
          <>
            <TextField
              label="New name"
              name="newname"
              value={newName}
              onChange={(event) => {
                setNewName(event.target.value);
              }}
              fullWidth
              margin="normal"
              key="name"
            />
            <WarningComponentForIncorrectField
              message={newNameMessage}
            />
            <TextField
              label="Email"
              name="email"
              value={newEmail}
              onChange={(event) => {
                setNewEmail(event.target.value);
              }}
              fullWidth
              margin="normal"
              key="email"
            />
            <WarningComponentForIncorrectField
              message={newEmailMessage}
            />
            <Typography variant="body1" mt={1}>
              {`Once created, this user will receive an email with the temporary password to access the website as an admin.`}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            props.setOpenModal(false);
            setNewName('');
            setNewEmail('');
          }}
          color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={
            loading ||
            (props.action === 'UPDATE NAME' &&
              newNameMessage !== '')
          }
          color={
            [
              'UPDATE NAME',
              'ASSIGN NEW PASSWORD',
              'CREATE NEW ADMIN'
            ].includes(props.action)
              ? 'success'
              : 'error'
          }>
          {loading ? <CircularProgress /> : props.action}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateAdminModal;
