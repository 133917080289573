export const VIEW_MODE = {
  LIST_VIEW: 'List layout',
  GRID_VIEW: 'Grid layout'
};

export const DATA_LOAD_STATUS = {
  STATUS_LOADING: 'STATUS_LOADING',
  STATUS_DONE: 'STATUS_DONE',
  STATUS_ERROR: 'STATUS_ERROR'
};

export const SIGN_IN_UP_MODES = {
  USER_SIGN_IN: 'USER_SIGN_IN',
  USER_SIGN_UP: 'USER_SIGN_UP'
};

export const USER_LOGGED_IN = {
  YES: 'YES',
  NO: 'NO'
};

export const SIGNUP_LOGIN_ERROR_MESSAGES = {
  EMAIL_EMPTY: '* Enter email address',
  PASSWORD_EMPTY: '* Enter password',
  PASSWORD_INVALID_LENGTH:
    '* Password should be of at least 8 characters',
  PASSWORD_INVALID_CHARACTERS:
    '* Password should contain at least 1 number, 1 uppercase, 1 lowercase and 1 special character',
  EMAIL_INVALID:
    '* Email should be in the format firstname.lastname@example.com',
  EMAIL_EXISTS: '* Email already exists. Try signing in.',
  USER_NOT_FOUND:
    'User not found. Make sure you are entering the correct email.',
  PASSWORD_INCORRECT:
    'Incorrect email or password. Make sure you are entering the correct email and password.',
  PASSWORD_CONFIRMATION_MISMATCH:
    '* Password and confirmation password do not match. Try again.',
  USERNAME_EMPTY: '* Enter name',
  USERNAME_INVALID:
    '* Name should be in the format of John Michael Doe'
};

export const SERVICE_QUESTION_TYPES = {
  LEVEL1_PROVIDER: 'Level1 Provider',
  SUPPLEMENTAL: 'Supplemental',
  PROVIDER: 'Provider',
  CUSTOM_SUPPLEMENTAL: 'Custom Supplemental'
};

export const SERVICE_QUESTION_REFERENCE_STATUS = {
  EXISTING: 'EXISTING',
  NEW: 'NEW',
  DELETED: 'DELETED',
  UPDATED: 'UPDATED'
};

export const USER_ROLES = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPERADMIN'
};

export const PHARMACY_TYPES: {
  [key: string]: string;
} = {
  INDEPENDENT: 'INDEPENDENT',
  CHAIN: 'CHAIN',
  SPECIAL_REQUEST: 'SPECIAL REQUEST'
};

export const PHARMACY_LAYOUT_USER = {
  PATIENT: 'PATIENT',
  PROVIDER: 'PROVIDER'
};

export const DUMMY_PHARMACY = {
  recordId: -1,
  permitNo: '',
  status: 'NEW',
  pharmacyName: '',
  locationAddress1: '',
  locationAddress2: null,
  locationCity: '',
  locationState: '',
  locationZip: '',
  locationCounty: '',
  mailingAddress1: '',
  mailingAddress2: null,
  mailingCity: '',
  mailingState: '',
  mailingZip: '',
  mailingCounty: '',
  phone: '',
  fax: '',
  operationHours: '',
  website: '',
  facilityEmail: '',
  pmpicName: '',
  pmpicEmail: '',
  pharmacyType: PHARMACY_TYPES.INDEPENDENT,
  locationLatitude: 0,
  locationLongitude: 0
};

export const PHARMACY_OPERATION_MODE = {
  NEW: 'NEW',
  UPDATE: 'UPDATE'
};

export const PHARMACY_SERVICE_VIEW_PROPS = {
  CONFIGURE: 'CONFIGURE',
  REVIEW: 'REVIEW'
};

export const TABLE_NAMES = {
  PHARMACIES: 'Pharmacies',
  PHARMACHY_HISTORY: 'PharmacyHistory',
  USERS: 'Users',
  SERVICES: 'Services',
  SERVICE_QUESTIONS: 'ServiceQuestions',
  SERVICE_REFERENCES: 'ServiceReferences',
  SERVICE_REQUESTS: 'ServiceRequests',
  PHARMACY_SERVICES: 'PharmacyServices',
  FEEDBACKS: 'Feedbacks',
  MAIL_LOGS: 'MailLogs'
};

export const IMPORT_PHARMACIES_COLUMNS = [
  'permitNo',
  'pharmacyName',
  'locationAddress1',
  'locationAddress2',
  'locationCity',
  'locationState',
  'locationZip',
  'locationCounty',
  'mailingAddress1',
  'mailingAddress2',
  'mailingCity',
  'mailingState',
  'mailingZip',
  'mailingCounty',
  'phone',
  'fax',
  'operationHours',
  'website',
  'facilityEmail',
  'pmpicName',
  'pmpicEmail',
  'pharmacyType',
  'locationLatitude',
  'locationLongitude'
];

export const FEEDBACK_STATUS: { [key: string]: string } = {
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  RESOLVED: 'Resolved',
  REVIEWED: 'Reviewed (no action required)',
  ENHANCEMENT: 'Save as an Enhancement'
};

export const TEXT_TEMPLATES = {
  DISCLAIMER_TEXT:
    'Service fees MAY apply. Contact pharmacy for details'
};

export const PHARMACY_OVERRIDES = {
  HIDE_FACILITY_EMAIL: ['07804']
};
