import { useEffect, useState } from 'react';
import {
  HistoryModalProps,
  HistoryObject
} from '../../helpers/interfaces';
import {
  Divider,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { getPharmacyHistory } from '../../helpers/apiCalls';
import { useDispatch } from 'react-redux';
import { getDateString } from '../../helpers/utils';

const HistoryModal: React.FC<HistoryModalProps> = (
  props: HistoryModalProps
): JSX.Element => {
  const [history, setHistory] = useState<HistoryObject[]>(
    []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      const result = await getPharmacyHistory(
        dispatch,
        props.recordId
      );
      setHistory(result);
    };
    fetch();
  }, [props.recordId, props.displayModal]);

  return (
    <Modal
      open={props.displayModal}
      onClose={() => props.setDisplayModal(false)}
      style={{
        minWidth: '50%',
        maxWidth: '90%',
        minHeight: '300px',
        margin: '10% auto 10% auto'
      }}>
      {history.length === 0 ? (
        <div>No updates done for this pharmacy</div>
      ) : (
        <TableContainer
          component={Paper}
          sx={{ overflowX: 'auto' }}
          style={{ height: '100%' }}>
          <Grid container>
            <Grid item xs={10} sm={10} md={10}>
              <Typography
                variant="h5"
                align="center"
                mt={2}
                ml={10}>
                Update History
              </Typography>
            </Grid>
          </Grid>

          <Divider />
          <Table
            sx={{ minWidth: 650, maxWidth: 'auto' }}
            aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Operation</b>
                </TableCell>
                <TableCell align="right">
                  <b>Updated By</b>
                </TableCell>
                <TableCell align="right">
                  <b>Comment</b>
                </TableCell>
                <TableCell align="right">
                  <b>Updated On</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((item) => (
                <TableRow
                  key={item.historyRecordId}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0
                    }
                  }}>
                  <TableCell component="th" scope="row">
                    {item.operation}
                  </TableCell>
                  <TableCell align="right">
                    {item.updatedByUserName}
                  </TableCell>
                  <TableCell align="right">
                    {item.updateComment}
                  </TableCell>
                  <TableCell align="right">
                    {getDateString(item.updatedOn)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Modal>
  );
};

export default HistoryModal;
